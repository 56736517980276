.log-info {
    label {
        font-size: 14px;
        font-weight: 600;
    }

    &.form-flex.two-column-mobile,
    &.form-flex.three-column  {
        align-content: flex-start;
        align-items: flex-start;
        
        section {
            flex-direction: row;
            flex-wrap: wrap;
            flex-flow: row wrap;
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            justify-content: flex-start;
            width: 100%;
        }

        label {
            margin: 0 5% 0 0;
            text-align: right;
            width: 40%;
        }

        p {
            width: 55%;
        }
    }

    section {
        p {
            padding: 0;
            margin: 0 0 5px 0;
            width: 100%;
        }
    }
}

ul.log-info.flex-list ,
ul.audit-info.flex-list {
    align-content: flex-start;
    align-items: flex-start;

    border: 2px solid $killarney;
    border-radius: 6px;
    list-style: none;
    margin: 30px 0 0 0;
    padding: 20px;

    li {
        list-style: none;
        margin: 0 2% 0 0;
        width: 49%;

        &:nth-of-type(2n+2) {
            margin: 0 0 0 0;
        }
    }

    label {
        font-size: 14px;
        font-weight: 800;
    }

    p {
        margin: 0;
    }
}

.log-info.purpose-statement {
    border-bottom: 1px solid $pistachio;
    margin: 0 0 bsu(2) 0;
    padding: 0 0 bsu(0.5) 0;
}

@include bp(sm) {
    .log-info {
        section {
            flex-direction: row;
            flex-wrap: wrap;
            flex-flow: row wrap;
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            justify-content: flex-start;

            label {
                margin: 0 10px 0 0;
                text-align: right;
                width: 19%;
            }

            section {
                padding: 0;
                width: 79%;
            }
        }

        &.form-flex.two-column  {
            section {
                margin: 0 2% 0 0;
                width: 49%;
    
                &:nth-of-type(2n+2) {
                    margin: 0 0 0 0;
                }
            }
        }

        &.form-flex.three-column  {
            section {
                margin: 0 2% 0 0;
                width: 32%;
    
                &:nth-of-type(2n+2) {
                    margin: 0 2% 0 0;
                }

                &:nth-of-type(3n+3) {
                    margin: 0;
                }
            }
        }
    }

    ul.log-info.flex-list,
    ul.audit-info.flex-list {
        li {
            flex-direction: row;
            flex-wrap: wrap;
            flex-flow: row wrap;
            align-content: flex-start;
            align-items: flex-start;
            display: flex;
            justify-content: flex-start;

            margin: 0 2% bsu(0.5) 0;
            width: 49%;

            &:nth-of-type(3n+3) {
                margin: 0 2% bsu(0.5) 0;
            }

            &:nth-of-type(2n+2) {
                margin-right: 0;
            }

            label {
                margin: 0 5% 0 0;
                text-align: right;
                width: 40%;
            }

            p {
                padding: 0;
                width: 55%;
            }
        }
    }

    ul.audit-info.flex-list {
        li {
            //margin: 0 2% bsu(0.5) 0;
            // width: 32%;

            // &:nth-of-type(3n+3) {
            //     margin: 0 0 bsu(0.5) 0;
            // }

            // &:nth-of-type(2n+2) {
            //     margin-right: 0;
            // }

            label {
                text-align: left;
                width: 1000%;
            }

            p {
                padding: 0;
                width: 100%;
            }
        }
    }
}


@include bp(md) {
    .log-info {
        section {
            label {
                margin: 0 15px 0 0;
            }

            p {
                //min-height: 30px;
            }
        }

        &.form-flex.two-column-mobile,
        &.form-flex.three-column {
            section {
                label {
                    margin: 0 3% 0 0;
                    width: 40%;
                }

                p {
                    width: 52%;
                }
            }
        }
    }

    ul.audit-info.flex-list {
        li {
            width: 23.5%;

            &:nth-of-type(3n+3) {
                margin: 0 2% bsu(0.5) 0;
            }
            &:nth-of-type(4n+4) {
                margin: 0 0% bsu(0.5) 0;
            }
        }
    }
}

@media print {
    .log-info {
        label,
        p {
            font-size: 10pt !important;
            line-height: 12pt !important;
        }

        section {
            label {
                margin: 0.125in 0 0 0;
                text-align: left;
                width: 1.5in;
            }

            section {
                margin: 0.125in 0 0 0;
                width: 6in;
            }
        }
    }

    .log-info.purpose-statement {
        border-bottom: 1px solid $gray-chateau;
        margin: 0 0 0.125in 0 !important;
    }

    ul.log-info.flex-list {
        li {
            margin: 0 4% 0.0625in 0 !important;
            width: 30% !important;

            &:nth-of-type(2n+2) {
                margin: 0 4% 0.0625in 0 !important;
            }

            &:nth-of-type(3n+3) {
                margin: 0 0 0.0625in 0!important;
            }

            label {
                margin: 0;
                text-align: left;
                width: 100%;
            }

            p {
                padding: 0;
                width: 100%;
            }
        }
    }

    .e-sign {
        section {
            h6 {
                font-size: 10pt !important;
            }
        }
    }

    #fb-render {
        h1,
        h2,
        h3,
        h4 {
            margin-bottom: 0.125in !important;
            padding: 0 !important;
        }

        .u-margin-bottom-1,
        .u-margin-bottom-2,
        .u-margin-bottom-3,
        .u-margin-bottom-4 {
            margin-bottom: 0.25in !important;
        }
    }
}

///////////////////////////////////////////////
//new design
///////////////////////////////////////////////
.new-design {
    ul.audit-info.flex-list, 
    ul.log-info.flex-list {
        border: 2px solid $killarney;
        border-radius: 6px;
        margin-top: 30px;
        padding: 20px;

        label {
            font-weight: 800;
        }

        li {
            margin-bottom: 0;

            &:nth-of-type(2n+2),
            &:nth-of-type(3n+3) {
                margin-bottom: 0;
            }
        }
    }
}