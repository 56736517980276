$spacing-steps: 10;

@for $index from 0 to $spacing-steps {
    
    .u-margin-#{$index} {
        margin: bsu($index / 2) !important;
    }

    .u-margin-top-#{$index} {
        margin-top: bsu($index / 2) !important;
    }

    .u-margin-right-#{$index} {
        margin-right: bsu($index / 2) !important;
    }

    .u-margin-bottom-#{$index} {
        margin-bottom: bsu($index / 2) !important;
    }

    .u-margin-left-#{$index} {
        margin-left: bsu($index / 2) !important;
    }

    .u-padding-#{$index} {
        padding: bsu($index / 2) !important;
    }

    .u-padding-top-#{$index} {
        padding-top: bsu($index / 2) !important;
    }

    .u-padding-right-#{$index} {
        padding-right: bsu($index / 2) !important;
    }

    .u-padding-bottom-#{$index} {
        padding-bottom: bsu($index / 2) !important;
    }

    .u-padding-left-#{$index} {
        padding-left: bsu($index / 2) !important;
    }
}

@for $small from 1 to 9 {
    
    .u-small-margin-#{$small} {
        margin: #{$small}px !important;
    }

    .u-small-margin-top-#{$small}  {
        margin-top: #{$small}px !important;
    }

    .u-small-margin-right-#{$small}  {
        margin-right: #{$small}px !important;
    }

    .u-small-margin-bottom-#{$small}  {
        margin-bottom: #{$small}px !important;
    }

    .u-small-margin-left-#{$small}  {
        margin-left: #{$small}px !important;
    }

    .u-small-padding-#{$small}  {
        padding: #{$small}px !important;
    }

    .u-small-padding-top-#{$small}  {
        padding-top: #{$small}px !important;
    }

    .u-small-padding-right-#{$small}  {
        padding-right: #{$small}px !important;
    }

    .u-small-padding-bottom-#{$small}  {
        padding-bottom: #{$small}px !important;
    }

    .u-small-padding-left-#{$small}  {
        padding-left: #{$small}px !important;
    }
}

@mixin spacing-steps-md-up-x {
    @for $i from 0 to 10 {
    
        .md-u-margin-#{$i} {
            margin: bsu($i / 2) !important;
        }
    
        .md-u-margin-top-#{$i} {
            margin-top: bsu($i / 2) !important;
        }
    
        .md-u-margin-right-#{$i} {
            margin-right: bsu($i / 2) !important;
        }
    
        .md-u-margin-bottom-#{$i} {
            margin-bottom: bsu($i / 2) !important;
        }
    
        .md-u-margin-left-#{$i} {
            margin-left: bsu($i / 2) !important;
        }
    
        .md-u-padding-#{$i} {
            padding: bsu($i / 2) !important;
        }
    
        .md-u-padding-top-#{$i} {
            padding-top: bsu($i / 2) !important;
        }
    
        .md-u-padding-right-#{$i} {
            padding-right: bsu($i / 2) !important;
        }
    
        .md-u-padding-bottom-#{$i} {
            padding-bottom: bsu($i / 2) !important;
        }
    
        .md-u-padding-left-#{$i} {
            padding-left: bsu($i / 2) !important;
        }
    }
}

@include bp(md) {
    @include spacing-steps-md-up-x;
}


.u-margin-none,
.u-margin-0 {
    margin: 0 !important;
}

.u-padding-none {
    padding: 0 !important;
}

.u-margin-auto {
    margin: auto;
}

@include bp(md) {
    .u-margin-bottom-desk-0 {
        margin: 0 !important;
    }
}

.u-margin-none,
.u-margin-0 {
    margin: 0 !important;
}

.u-padding-none {
    padding: 0 !important;
}

.u-margin-auto {
    margin: auto;
}

@include bp(md) {
    .u-margin-bottom-desk-0 {
        margin: 0 !important;
    }
}
