.attachment-embed {
    min-height: 400px;
    min-width: 100%;
}

@include bp(md) {
    .attachment-embed {
        min-height: 500px;
        min-width: 100%;
    }
}

// @include bp(lg) {
//     .attachment-embed {
//         min-height: 800px;
//         min-width: 100%;
//     }
// }