// Float Helpers
// ------------------------------------------------------------------------------------

.u-float {
    float: left !important;
}

.u-float--left {
    float: left !important;
}

.u-float--right {
    float: right !important;
}

.u-float--none {
    float: none !important;
}

.u-float--initial {
    float: initial !important;
}

.u-float--inherit {
    float: inherit !important;
}

.u-clear {
    clear: both !important;
}

@each $key, $breakpoint in $breakpoints {
    .#{$key}-u-float {
        @include bp($breakpoint) {
            float: left !important;
        }
    }

    .#{$key}-u-float--right {
        @include bp($breakpoint) {
            float: right !important;
        }
    }

    .#{$key}-u-float--none {
        @include bp($breakpoint) {
            float: none !important;
        }
    }

    .#{$key}-u-float--initial {
        @include bp($breakpoint) {
            float: initial !important;
        }
    }

    .#{$key}-u-float--inherit {
        @include bp($breakpoint) {
            float: inherit !important;
        }
    }

}