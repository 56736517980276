//log builder
.log-builder-wrapper {
    display: grid;
    grid-template-columns: min-content 1fr;
    margin-right: 5%;
    position: relative;
    width: 95%;
}

.log-builder-inner-wrapper {
    margin: 0 0 0 35px;
    position: relative;
    z-index: 1;

    h2 {
        height: 35px;
        margin: 0;
    }
}

.log-builder-tools {
    position: relative;
    z-index: 2;

    ul {
        list-style: none;
        margin: 35px 0 0 0;
        left: 0;
        position: sticky;
        top: 120px;
        width: 50px;
    }

    li {
        background: $ada-grey-2;
        margin: 0;
        position: relative;
        z-index: 3;

        &::before {
            display: none;
        }

        &:after {
            background: $white;
            bottom: 0;
            content: '';
            display: block;
            height: 1px;
            left: 4px;
            position: absolute;
            width: 42px;
            z-index: 3;
        }

        &:first-of-type {
            border-radius: 0 4px 0 0;
        }

        &:last-of-type {
            border-radius: 0 0 4px 0;

            &:after {
                display: none;
            }
        }

        a {
            background: $ada-grey-2;
            display: block;
            height: 47px;
            padding: 10px;
            position: relative;
            text-align: center;
            width: 50px;
            z-index: 3;
        }

        svg {
            fill: $white;
            height: auto;
            max-height: 27px;
            max-width: 27px;
            width: auto;
        }
    }
}

.log-builder-tooltip {
    background: $mercury;
    //display: none;
    display: flex;
    align-items: center;
    height: 47px;
    left: -120px;
    padding: 0 10px;
    position: absolute;
    top: 0;
    transition: all ease-in-out 0.5s;
    width: 120px;
    z-index: 2;

    &.openToolTip {
        //display: flex;
        left: 100%;
    }
}

//percentage table
.percentage-table {
    // border: 1px solid $sea-nymph-hover;
    // border-radius: 6px;

    header {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;

        font-size: 16px;
        padding-bottom: 5px;
        position: relative;

        .header-left {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            span {
                margin: 0 0 0 5px;
            }
        }

        h1 {
            font-size: 24px !important;
            font-weight: bold !important;
            margin: 0;
            padding: 0;
            width: auto;
        }

        .header-right {
            .number-field,
            span {
                position: absolute;
                right: 0;
            }

            .number-field {
                top: 4px;
                width: 95px;
            }

            span {
                bottom: 0;
                font-size: 20px;
                font-weight: bold;
            }

            p {
                margin: 0;
            }
        }
    }

    .percentage-table-row {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        //align-items: center;
        align-items: stretch;
        justify-content: space-between;

        div.column {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            border: 1px solid $sea-nymph-hover;
            border-left: none;
            border-top: none;

            font-size: 16px;
            min-height: 44px;
            padding: 0 20px;
            position: relative;
            text-align: center;
            width: 30%;

            &:first-of-type {
                align-items: flex-start;
                border-left: 1px solid $sea-nymph-hover;
                text-align: left;
                width: 40%;
            }

            &:last-of-type {
            }
        }

        .min-max {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;

            font-weight: bold;

            div {
                margin: 0 20px 0 0;

                &:last-of-type {
                    margin: 0;
                }
            }
        }

        .min-max-right {
            position: absolute;
            right: 5px;
            top: 5px;
            width: 70px;

            p {
                font-size: 16px;
                line-height: 1;
                margin: 0;
                text-align: left;
            }
        }

        &:first-of-type {
            div.column {
                background: $sea-nymph-hover;
                border-bottom: none;
                border-right: 1px solid $white;
                border-top: 1px solid $sea-nymph-hover;
                font-weight: 600;

                &:first-of-type {
                    border-radius: 6px 0 0 0;
                }

                &:last-of-type {
                    border-radius: 0 6px 0 0;
                    border-right: $sea-nymph-hover;
                }
            }
        }

        &:last-of-type {
            div.column {
                background: $ada-grey-5;
                font-weight: bold;

                &:first-of-type {
                    border-radius: 0 0 0 6px;
                }

                &:last-of-type {
                    border-radius: 0 0 6px 0;
                }
            }
        }
    }
}

@include bp(sm) {
    .percentage-table {
        header {
            .header-left {
                width: auto;
            }
           
            .header-right {
                display: flex;
                flex-direction: row;
                flex-flow: row wrap;
                align-items: center;
                justify-content: space-between;

                .number-field,
                span {
                    bottom: inherit;
                    position: relative;
                    right: inherit;
                    top: inherit;
                }

                p {
                    margin: 0 5px 0 0;
                }
            }
        }
    }
}

@include bp(md) {
    .percentage-table {
        .percentage-table-row {
            div.column {
                height: 44px;
            }
        }
    }
}