// Position Helpers
//-------------------------------------------------------------------------
.u-relative {
    position: relative !important;
}
.u-relative-before {
    &:before {
        position: relative !important;
    }
}
.u-absolute {
    position: absolute !important;
}
.u-fixed {
    position: fixed !important;
}

// Pin an item to the corners of the viewport
//-------------------------------------------------------------------------
.u-pin-top {
    top: 0 !important;
}
.u-pin-right {
    right: 0 !important;
}
.u-pin-bottom {
    bottom: 0 !important;
}
.u-pin-left {
    left: 0 !important;
}

.u-pin-edges {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
}

.u-top-inherit {
    top: inherit !important;

    &:before {
        top: inherit !important;
    }
}

@each $key, $breakpoint in $breakpoints {

    .#{key}-u-relative {
        @include bp($breakpoint) {
            position: relative !important;
        }
    }
    .#{key}-u-absolute {
        @include bp($breakpoint) {
            position: absolute !important;
        }
    }
    .#{key}-u-fixed {
        @include bp($breakpoint) {
            position: fixed !important;
        }
    }
    .#{key}-u-pin-top {
        @include bp($breakpoint) {
            top: 0 !important;
        }
    }
    .#{key}-u-pin-right {
        @include bp($breakpoint) {
            right: 0 !important;
        }
    }
    .#{key}-u-pin-bottom {
        @include bp($breakpoint) {
            bottom: 0 !important;
        }
    }
    .#{key}-u-pin-left {
        @include bp($breakpoint) {
            left: 0 !important;
        }
    }
    
    .#{key}-u-pin-edges {
        @include bp($breakpoint) {
            top: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            left: 0 !important;
        }
    }

}    