// Container shorthand
@mixin container-padding() {
    @include padding(bsu(0.5) 1rem bsu(0.5) 1rem);
}

.no-margin {
    margin: 0 !important;
}

.no-margin-top {
    margin-top: 0 !important;
}