html {
    scroll-behavior: smooth;
}

#app {
    padding: 0;
}

//main container
main {
    background-color: $black-haze;
    box-shadow: 10px 10px 30px 10px rgba($almost-black, 0.4);
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 410px;
    transform: translate(-50%, -50%);
    width: 90%;

    figure {
        margin-bottom: $small-spacing;
        padding: $large-spacing $base-spacing 0 $base-spacing;
        text-align: center;

        img {
            margin: 0 auto;
        }
    }

    .not-logged-in-top {
        padding: $small-spacing $base-spacing 0 $base-spacing;
    }

    figcaption h1 {
        border: 0 !important;
        clip: rect(1px, 1px, 1px, 1px) !important;
        -webkit-clip-path: inset(50%) !important;
                clip-path: inset(50%) !important;
        height: 1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
        white-space: nowrap !important;
    }
}

.logged-in {
    main {
        background: $white;
        box-shadow: none;
        left: 0;
        margin: bsu(2) auto bsu(3) auto;
        max-width: 70em;
        position: relative;
        right: 0;
        top: 0;
        transform: none;
        width: 90%;

        &.narrow {
            max-width: 40em;
        }

        &.print {
            max-width: 100em;
        }

        &.wider {
            max-width: 100em;
        }
        
        &.full-width {
            max-width: 140em;
        }

        &.dashboard {
            margin: 20px auto;
            max-width: 1620px;
        }


    }
}

.narrow-form-wrapper,
.narrow-content-wrapper {
    margin: 0 auto;
}

.narrow-form-wrapper {
    max-width: 758px;
}

@include bp(md) {
    .crm-content-container {
        .narrow-form-wrapper,
        .narrow-content-wrapper {
            max-width: 66.67%;
            width: 66.67%;
        }
    }
}

.unauthenticated {
    background: $white;

    main {
        background: $white;
        box-shadow: none;
        left: 0;
        margin: bsu(1) auto bsu(2) auto;
        max-width: 70em;
        position: relative;
        right: 0;
        top: 0;
        transform: none;
        width: 90%;
    }
}

.site-width {
    margin: 0 auto;
    max-width: 70em;
}

.not-logged-in-footer {
    padding: $large-spacing $small-spacing $small-spacing $small-spacing;

    p {
        font-size: 12px;
        margin: 0 0 bsu(0.25) 0;

        &.contact {
            font-size: 14px;
        }
    }
}

section.self-service-narrow-width,
section.self-service-wider-width {
    border-bottom: 2px solid $pistachio;
    margin: bsu(2) auto 0 auto;
    max-width: 40em;
    padding: 0 0 bsu(1.75) 0;
    text-align: center;

    h2 {
        margin: 0;
    }
}

section.self-service-wider-width {
    max-width: 60em;
}

@include bp(sm) {
   main {
        figure {
            padding: $large-spacing $xlarge-spacing 0 $xlarge-spacing;
        }
        
        .not-logged-in-top {
            padding: $small-spacing $xlarge-spacing 0 $xlarge-spacing;
        }
    }

    [data-view="Dashboard"] {
        main {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: top;
            justify-content: center;
        }
    }

    .flex-wrapper {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: top;
        justify-content: center;

        .tool-container {
            margin: 0 2% bsu(1) 0;
            text-align: center;
            width: 32%;
    
            &:nth-of-type(2n+2) {
                margin: 0 2% bsu(1) 0;                
            }
    
            &:nth-of-type(3n+3) {
                margin: 0 0 bsu(1) 0;                
            }
    
            a {
                svg {
                    margin: bsu(1) 15% bsu(0.5) 15%;
                }
            }
        }
    }
}

@include bp(md) {
    .logged-in {
        main {
            &.dashboard {
                margin: 25px auto;
            }
        }
    }
}

.tools-inner-wrapper {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;

    h1 {
        width: 100%;
    }
}

.tool-container {
    margin: 0 8% bsu(1) 0;
    text-align: center;
    width: 46%;

    &:nth-of-type(2n+2) {
        margin: 0 0 bsu(1) 0;                
    }

    a {
        display: block;
        padding: 0 5%;

        svg {
            height: auto;
            margin: bsu(1) 20% bsu(0.5) 20%;
            max-height: 66px;
        }

        h2 {
            font-size: 16px;
        }
    }
}

.widgets {
    section {
        margin-bottom: bsu(2);

        &.inner-wrapper {
            margin-bottom: 0;
        }

        section {
            margin: 0;
        }
    }
}


@include bp(sm) {
    .tools {
        margin: 0 8% 0 0;
        width: 46%;
    }

    .tools-inner-wrapper {
        .tool-container {
            a {
                padding: 0 10%;
                
                svg {
                    margin: bsu(1) 30% bsu(0.5) 30%;
                }

                h2 {
                    font-size: 18px;
                }
            }
        }
    }

    .widgets {
        width: 46%;
    }
}

//e-sign
.e-sign {
    section {
        margin: bsu(2) 0 0 0;
        width: 100%;

        div {
            border-bottom: 1px solid $almost-black;
            font-style: italic;
            margin: 0 0 bsu(0.25) 0;
            padding: 0 0 bsu(0.25) 0;
        }
    }
}

@include bp(sm) {
    .e-sign {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row wrap;
        align-content: flex-end;

        section {
            margin: bsu(2) 4% 0 0;
            width: 48%;

            &:nth-of-type(2n+2) {
                margin: bsu(2) 0 0 0;
            }
        }
    }
}


//content-containers
.modal .content-container-white-bg,
.content-container-white-bg {
    background: $white;
    border: 1px solid $hub;
    border-radius: 6px;
    padding: 20px 15px;

    .heading-container {
        background: $hub;
        border-radius: 4px 4px 0 0;
        min-height: 50px;
        padding: 5px 13px;

        &.heading-container-embedded {
            border-radius: 6px;
            margin: 0 10px;
        }
            
        h1 {
            color: $white;
            display: inline;
            font-size: 22px;
            font-weight: 600;
            margin: 0;
            max-width: 80%;
            padding: 0;

            &:after {
                display: none;
            }
        }

        label {
            color: $white;
        }
    }

    .content-container-white-bg-inner-padding {
        border-top: none;
        border-radius: 0 0 6px 6px;
        padding: 20px 15px;

        &.narrow-padding {
            padding: 10px;
        }
    }

    .ellipsis-container.ellipsis-pin-top-right {
        right: 0;
        position: absolute;
        top: 20px;

        .ellipsis-option-list {
            left: inherit;
            right: 30px;
        }
    }

    &.grey-header {
        border: none;

        .heading-container {
            background: $ada-grey-4;

            h1 {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    &.pistachio-header {
        border: 1px solid $pistachio;

        .heading-container {
            background: $pistachio;
        }
    }

    &.log-builder {
        font-size: 16px;
        max-width: 500px;

        .heading-container {
            h1 {
                font-size: 18px;
                font-weight: 600;
            }
        }
    }
}

.title-bar-btn-container {
    display: flex;
    justify-content: space-between;
    text-align: right;
    cursor: pointer; 
    width: 45px;
}

.modal .content-container-white-bg {
    .content-container-white-bg-inner-padding {
        border: 1px solid $hub;
    }
}

.info-container {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: top;
    justify-content: space-between;

    max-width: 450px;
    width: 100%;

    h4,
    h5 {
        margin: 0 0 5px 0;
    }

    h4 {
        text-align: right;
        width: 30%;
    }

    h5 {
        width: 65%;
    }
}

@include bp(sm) {
    .content-container-white-bg {
        padding: 25px;

        .heading-container {
            padding: 5px 10px 5px 30px;
    
            section {
                margin-top: 0;
            }

            &.saved-item-header {
                padding: 5px 10px;
            }
        }
    
        .content-container-white-bg-inner-padding {
            padding: 25px;
        }

        .ellipsis-container.ellipsis-pin-top-right {
            right: 0;
            position: absolute;
            top: 25px;
        }

        &.log-builder {
            .heading-container {
                height: 40px;
            }

            .content-container-white-bg-inner-padding {
                padding: 30px 50px;
            }
        }
    }

    .info-container {
        h5 {
            width: 67%;
        }
    }
}

//qc content-containers
body.quality-control {
    .modal .content-container-white-bg,
    .content-container-white-bg {
        border: 1px solid $sea-nymph-hover;

        .heading-container {
            background: $sea-nymph-hover;

            h1, 
            label {
                color: $mine-shaft;
            }
                
            h1 {
                color: $mine-shaft;
                font-size: 20px;
                font-weight: 600;
                margin: 0;
                padding: 0;
            }

            &.saved-item-header {
                h1 {
                    font-size: 16px;
                }
            }
        }

        .content-container-white-bg-inner-padding {
            th {
                padding-top: 0;
            }
        }
    }

    .modal .content-container-white-bg {
        .content-container-white-bg-inner-padding {
            border: 1px solid $sea-nymph-hover;
        }
    }
}

//wave backgrounds
.bg-wave {
    background: url('../../Content/img/wave-quality-control.jpg') no-repeat center center;
    background-size: cover;
    border-radius: 6px;
    color: $white;
    padding: 27px 30px;

    &.title-only {
        h1 {
            margin: 0;
            padding: 0;
        }
    }
}

.bg-wave-qc {
    background: $cutty-sark url('../../Content/img/wave-qc.svg') no-repeat bottom right;
}

@include bp(lg) {
    .bg-wave {
        padding: 50px;

        &.title-only {
            padding: 27px 30px;
        }
    }
}

///////////////////////////////////////////////
//new design
///////////////////////////////////////////////
body.new-design {
    background: $gallery;
    color: $mine-shaft;
    margin: 0 auto;

    &.logged-in {
        main {
            &.full-width {
                background: transparent;
                margin: 100px auto 0;
                max-width: 90%;
                width: 90%;

                #embedContainer {
                    height: 90.5vh;
                    margin: 0 auto;
                }
            }

            &.utility-page {
                border-radius: 6px;
                box-shadow: none;
                margin: bsu(2) auto bsu(3) auto;
                max-width: 550px;
                padding: 40px 20px;
                width: 90%;
        
                svg {
                    display: block;
                    margin: 0 auto 30px auto;
                    text-align: center;
                }
        
                h1,
                h2,
                p,
                .form-item {
                    padding-left: 45px;
                    padding-right: 45px;
                }

                h1 {
                    background: transparent;
                    font-size: 25px;
                    font-weight: normal;
                    padding-bottom: 0;
                    padding-top: 0;
                    text-align: left;
                }
        
                .bottom-button-container {
                    padding-left: 75px;
                    padding-right: 75px;
                }

                .btn, 
                .ui-widget-content .btn {
                    padding: 9px 10px 11px 10px;
                }
        
                .scrolling-text {
                    border: 1px solid $silver2;
                    height: 250px;
                    margin-bottom: 25px;
                    overflow-y: scroll;
                    padding: 20px;
        
                    h1,
                    h2,
                    p,
                    .form-item {
                        padding-left: 25px;
                        padding-right: 25px;
                    }
            
        
                    h1 {
                        border-bottom: 1px dashed $mine-shaft;
                        font-size: 16px;
                        padding-bottom: 10px;
                        text-align: center;
                    }
        
                    h2 {
                        font-size: 14px;
                        font-weight: bold;
                    }
                }

                &.wide {
                    max-width: 980px;

                    h1,
                    h2,
                    .form-item {
                        padding: 0;
                    }
                }
            }
    
            &.full-width-container {
                border-radius: 6px;
                box-shadow: none;
                margin-top: 110px;
                max-width: 90%;
                padding: 0;
                position: relative;
                top: inherit;
                left: inherit;
                transform: none;
                width: 90%;

                .content-container {
                    padding: 25px;
                }

                &.transparent {
                    background: transparent;
                    border-radius: 0;
                }
            }
        }
    }

    @include bp(sm) {
        &.logged-in {
            main {
                &.utility-page {
                    margin: bsu(5) auto bsu(3) auto;
                    padding: 60px;
                    width: 80%;
                }

                &.full-width-container {
                    .content-container {
                        padding: 35px 70px;
                    }
                }
            }
        }
    }

    @include bp(md) {
        &.logged-in {
            main {
                &.utility-page {
                    margin: bsu(6) auto bsu(3) auto;
                    
                }
            }
        }
    }
}





