.breadcrumbs {
    align-content: flex-start;
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
	flex-flow: row wrap;
    list-style: none;
    margin: 0 0 bsu(2) 0;
    width: 100%;

    li {
        color: $jumbo;
        font-size: 13px;
        font-weight: 600;
        margin: 0 1% 0 0;
        padding: 0 bsu(0.9) 0 0;
        position: relative;

        &:last-of-type {
            margin: 0;

            &:after {
                display: none;
            }
        }

        &:after {
            color: $hub;
            content: '\edf6';
			font-family: 'icomoon';
			font-size: 10px;
            font-weight: 600;
			position: absolute;
			right: bsu(0.1);
			top: 4px;
        }

        a {
            color: $jumbo;

            &:hover {
                color: $pistachio;
            }
        }
    }
}

.crop-counts-active {
    li {
        &.crop-counts {
            a {
                color: $marigold;
            }
        }
    }
}

.weekly-estimates-active {
    li {
        &.weekly-estimates {
            a {
                color: $marigold;
            }
        }
    }
}

.seasons-active {
    li {
        &.seasons {
            a {
                color: $marigold;
            }
        }
    }
}

.records-active {
    li {
        &.records {
            a {
                color: $marigold;
            }
        }
    }
}

.season-active {
    li {
        &.season {
            a {
                color: $marigold;
            }
        }
    }
}

.seasons-active {
    li {
        &.seasons {
            a {
                color: $marigold;
            }
        }
    }
}

.region-active {
    li {
        &.region {
            a {
                color: $marigold;
            }
        }
    }
}

.cultivar-active {
    li {
        &.cultivar {
            a {
                color: $marigold;
            }
        }
    }
}

.farm-active {
    li {
        &.farm {
            a {
                color: $marigold;
            }
        }
    }
}

.locations-active {
    li {
        &.locations {
            color: $marigold;
        }
    }
}

.information-active {
    li {
        &.information {
            color: $marigold;
        }
    }
}

.products-active {
    li {
        &.products {
            color: $marigold;
        }
    }
}

.dates-times-active {
    li {
        &.dates-times {
            color: $marigold;
        }
    }
}

@include bp(sm) {
    .breadcrumbs {
        li {
            font-size: 14px;
            padding: 0 bsu(2) 0 0;

            &:after {
                font-size: 12px;
                right: bsu(0.5);
                top: 2px;
            }
        }
    }
}

//breadcrumb-container
.breadcrumb-container {
    padding: bsu(2.5) 0 0 0;

    ul {
        margin: 0 0 20px 0;
    }

    li {
        font-size: 12px;
        margin: 0;
        padding: 0 13px 0 0;

        &:after {
            color: $marigold;
			right: 2px;
			top: 7px;
        }

        a {
            display: block;
            padding: 5px 5px;
        }
    }

    .steps {
        li {
            padding: 0 20px 0 0;

            &:last-of-type {
                padding: 0;
            }

            &:after {
                right: 4px;
                top: 18px;
            }

            a {
                background: $concrete;
                border-radius: 100%;
                display: block;
                font-size: 30px;
                height: 50px;
                line-height: 1;
                padding: 10px;
                text-align: center;
                width: 50px;
            }

            &.active {
                a {
                    background: $marigold;
                    color: $white;
                }
            }

            &.complete {
                a {
                    background: rgba($pistachio, 0.5);
                    //border: 5px solid rgba($pistachio, 0.7);
                    color: $white;
                    //padding: 0px 5px;
                    position: relative;

                    &:before {
                        content: "\edab";
                        font-family: 'icomoon' !important;
                        left: 10px;
                        //padding-right: 5px;
                        position: absolute;
                        top: 10px;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }
    }
}

.user-profile-active {
    li {
        &.user-profile {
            a {
                background: rgba($pistachio, 0.7);
                color: $white;
            }
        }
    }
}

.company-active {
    li {
        &.company {
            a {
                background: rgba($pistachio, 0.7);
                color: $white;
            }
        }
    }
}

.operation-active {
    li {
        &.operation {
            a {
                background: rgba($pistachio, 0.7);
                color: $white;
            }
        }
    }
}

.logo-use-active {
    li {
        &.logo-use {
            a {
                background: rgba($pistachio, 0.7);
                color: $white;
            }
        }
    }
}

.notifications-active {
    li {
        &.notifications {
            a {
                background: rgba($pistachio, 0.7);
                color: $white;
            }
        }
    }
}

.user-sites-active {
    li {
        &.user-sites {
            a {
                background: rgba($pistachio, 0.7);
                color: $white;
            }
        }
    }
}

.user-permissions-active {
    li {
        &.user-permissions {
            a {
                background: rgba($pistachio, 0.7);
                color: $white;
            }
        }
    }
}

.user-notifications-active {
    li {
        &.user-notifications {
            a {
                background: rgba($pistachio, 0.7);
                color: $white;
            }
        }
    }
}

.operation-active {
    li {
        &.operation {
            a {
                background: rgba($pistachio, 0.7);
                color: $white;
            }
        }
    }
}

.site-details-active {
    li {
        &.site-details {
            a {
                background: rgba($pistachio, 0.7);
                color: $white;
            }
        }
    }
}

.crop-setup-active {
    li {
        &.crop-setup {
            a {
                background: rgba($pistachio, 0.7);
                color: $white;
            }
        }
    }
}

.variety-setup-active {
    li {
        &.variety-setup {
            a {
                background: rgba($pistachio, 0.7);
                color: $white;
            }
        }
    }
}

.planting-template-active {
    li {
        &.planting-template {
            a {
                background: rgba($pistachio, 0.7);
                color: $white;
            }
        }
    }
}

.site-permissions-active {
    li {
        &.site-permissions {
            a {
                background: rgba($pistachio, 0.7);
                color: $white;
            }
        }
    }
}

.associated-seasons-active {
    li {
        &.associated-seasons {
            a {
                background: rgba($pistachio, 0.7);
                color: $white;
            }
        }
    }
}

@include bp(sm) {
    // .breadcrumb-container {
    //     padding: 0 150px 0 0;

    //     li {
    //         font-size: 13px;
    //         padding: 0 20px 0 0;

    //         &:after {
    //             right: 5px;
    //         }
    //     }

    //     .steps {
    //         li {
    //             a {
    //                 font-size: 13px;

    //                 &:after {
    //                     right: 5px;
    //                 }
    //             }

    //             &.complete {
    //                 a {
    //                 }
    //             }
    //         }
    //     }
    // }
}

///////////////////////////////////////////////
//new design
///////////////////////////////////////////////
.new-design {
    .breadcrumbs {
        li { 
            font-style: 12px;

            &:after {
                color: $mine-shaft;
                font-size: 8px;
                font-weight: 400;
                right: 3px;
                top: 8px;
            }
    
            a {
                color: $cutty-sark;
                font-weight: 400;
                height: 17px;
    
                &:hover {
                    color: $mine-shaft;

                    .u-icon-home {
                        &:before {
                            color: $mine-shaft;
                        }
                    }
                }

                &.active {
                    color: $mine-shaft;
                }

                &.home {
                    svg {
                        width: 18px;
                    }
                }
            }
        }
    }
}

.breadcrumbs {
    li { 
        font-style: 12px;

        &:after {
            color: $mine-shaft;
            font-size: 8px;
            font-weight: 400;
            right: 3px;
            top: 8px;
        }

        a {
            color: $cutty-sark;
            font-weight: 400;
            //height: 17px;

            &:hover {
                color: $mine-shaft;

                .u-icon-home {
                    &:before {
                        color: $mine-shaft;
                    }
                }
            }

            &.active {
                color: $mine-shaft;
            }

            &.home {
                svg {
                    width: 18px;
                }
            }
        }
    }
}

.circle-breadcrumbs {
    display: flex;
    justify-content: flex-start;
	flex-flow: column;
    list-style: none;
    margin: 0 0 bsu(2) 0;
    width: 100%;
    li {
        
        display: flex;
        align-items: center;
        justify-content: left;
        position: relative;
        flex-direction: row-reverse;
        width: 100%;

        label{
            color: $almost-black;
            margin-left: 10px;
        }

        .u-circle {
            border-radius: 50%;
            background-color: $rio-grande;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            z-index: 1;
            margin: 5px 0;
            
            h2 {
                color: $almost-black;
                margin: 0px !important;
                top: -1px; 
                left: 1px;
                position: relative;
            }
        }
        .u-progress-line{
            height: 1px;
            width: 36px;
            background-color: $rio-grande;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 0;
            transform: rotate(90deg);
        }
    }
}
.locations-active{
    .circle-breadcrumbs {
        li:nth-child(1), li:nth-child(2){
            .u-progress-line{
                background-color: $ada-grey-2;
            }
        }
        li:nth-child(2), li:nth-child(3) {
            label{
                color: $ada-grey-2;
            }
            .u-circle {
                background-color: $white;
                border: 1px solid $ada-grey-2;
                h2 {
                    color: $ada-grey-2;
                }
            }
        }
    }
}
.dates-times-active{
    .circle-breadcrumbs {
        li:nth-child(2){
            .u-progress-line{
                background-color: $ada-grey-2;
            }
        }
        li:nth-child(3) {
            label{
                color: $ada-grey-2;
            }
            .u-circle {
                background-color: $white;
                border: 1px solid $ada-grey-2;
                h2 {
                    color: $ada-grey-2;
                }
            }
        }
    }
}

@include bp(sm) {
    .circle-breadcrumbs {
        align-content: flex-start;
        align-items: flex-end;
        justify-content: flex-start;
        flex-flow: row wrap;
        
        li {
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 33%;
    
            label{
                max-width: 120px;
                text-align: center;
                margin: 0px;
            }
            .u-circle {
                width: 50px;
                height: 50px;
                margin: 10px;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
            .u-progress-line{
                left: 50%;
                bottom: 33px;
                width: 100%;
                transform: rotate(0deg);
            }
        }
    }
}