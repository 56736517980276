// Typography

$system-font: 'Source Sans Pro', sans-serif;

$base-font-family: $system-font;
//$heading-font-family: $base-font-family;

$serif: georgia, times, serif;
$sans-serif: sans-serif;
$code: Consolas, monaco, monospace;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.5;
