$site-width: 70em;

$breakpoint-sm: 38em;
$breakpoint-md: 56em;
$breakpoint-lg: 75em;
$breakpoint-lgg: 90em;
$breakpoint-xlg: 115em;

$breakpoint-only-sm: 0em $breakpoint-sm;
$breakpoint-only-md: $breakpoint-md $breakpoint-lg;
$breakpoint-only-lgg: $breakpoint-lg $breakpoint-lgg;
$breakpoint-only-lg: $breakpoint-lgg $breakpoint-xlg;
$breakpoint-only-xlg: $breakpoint-xlg;

$breakpoints: (
    sm: $breakpoint-sm,
    only-sm: $breakpoint-only-sm,
    md: $breakpoint-md,
    only-md: $breakpoint-only-md,
    lg: $breakpoint-lg,
    only-lg: $breakpoint-only-lg,
    lgg: $breakpoint-lgg,
    only-lgg: $breakpoint-only-lgg,
    xlg: $breakpoint-xlg,
    only-xlg: $breakpoint-only-xlg
);