$_button-background-color: $action-color;
$_button-background-color-hover: $pistachio;

$_btn-grey-background-color: $ship-gray;
$_btn-grey-background-color-hover: lighten($ship-gray, 20%);

input[type='button'] {
	white-space: normal;
}

//main button styles
#{$all-buttons},
.button,
.btn,
a.btn,
.ui-widget input.btn,
.k-button,
.k-filter-menu .k-button,
.k-edit-form-container .k-button, 
.k-edit-form-container .k-primary, 
.k-popup .k-button, 
.k-popup .k-primary,
.ui-widget-content a.btn,
input[type='button'] {
//.active {
	appearance: none;
	background: $marigold;
	border: 0;
	border-radius: 5px;
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-family: $base-font-family;
	font-size: 18px;
	-webkit-font-smoothing: antialiased;
	font-weight: 600;
	line-height: 1;
	outline: none;
	padding: 13px 30px 13px 30px;
	text-align: center;
	text-decoration: none;
	transition: all $base-duration $base-timing;
	user-select: none;
	vertical-align: middle;

	&:hover,
	&:focus {
		background: $marigold-hover;
		color: $white;
		outline: none;
		outline-offset: 0;
	}

	&:visited {
		background: $marigold;

		&:hover,
		&:focus {
			background: $marigold-hover;
		}
	}

	&.btn-square {
		display: block;
		height: 25px;
		padding: 0;
		position: relative;
		width: 25px;

		span[class*=" u-icon-"]:before, 
		span[class^=u-icon-]:before {
			color: $white;
			left: 4px;
			position: absolute;
			top: 3px;
		}

		&:hover {
			span[class*=" u-icon-"]:before, 
			span[class^=u-icon-]:before {
				color: $white;
			}
		}

		&.left {
			float: left;
			margin-right: 10px;
		}

		&.right {
			float: right;
			margin-left: 10px;
		}
	}

	&.small {
		font-size: 12px;
		padding: 5px;
	}

	&.wide-button {
		width: 250px;
	}

	&.btn-grey {
		background: $_btn-grey-background-color;
		color: contrast-switch($_btn-grey-background-color);
		
		&:hover {
			background: $_btn-grey-background-color-hover;
			color: contrast-switch($_btn-grey-background-color-hover);
		}
	}

	&.btn-orange,
	&.btn-marigold {
		background: $marigold;
		
		&:hover {
			background: $marigold-hover;
		}
	}

	&.btn-bluegreen {
		background: $blue-green;

		&:hover {
			background: $pistachio;
		}
	}

	&.complete-button,
	&.btn-pistachio {
		background: $pistachio;

		&:hover {
			background: $pistachio-hover;
		}
	}

	&.btn-danger {
	    background: $danger-color;
	}

	&.btn-alt-1 {
		background: transparent;
		border: 2px solid $ship-gray;
		//height: 40px;
		color: $ship-gray;
		padding: 11px 30px;

		&:hover {
			background-color: $concrete;
			color: $ship-gray;
		}

		&:disabled,
		&.disabled {
			background: $concrete;
			color: $ship-gray;

			&:hover {
				background: $concrete;
				border: 2px solid $ada-grey-3;
				color: $ship-gray;
			}
		}

	}

	&.btn-alt-2 {
		background: $hub;

		&:hover {
			background-color: $hub-hover;
		}

		&:disabled,
		&.disabled {
			background: $ada-grey-2;
			color: $almost-black;

			&:hover {
				background: $ada-grey-2;
				color: $almost-black;
			}
		}
	}

	&.btn-alt-3 {
		background: $pistachio;

		&:hover {
			background-color: $pistachio-hover;
		}

		&:disabled,
		&.disabled {
			background: $ada-grey-2;
			color: $almost-black;

			&:hover {
				background: $ada-grey-2;
				color: $almost-black;
			}
		}
	}

	&.btn-alt-4 {
		background: transparent;
		border: 1px solid $ada-grey-2;
		color: $almost-black;
		font-size: 14px;
		font-weight: normal;
		text-align: left;
		padding: 11px 30px;

		&:hover {
			background-color: $concrete;
			color: $almost-black;
		}

		&:disabled,
		&.disabled {
			background: $concrete;
			color: $almost-black;

			&:hover {
				background: $concrete;
				border: 1px solid $ada-grey-2;
				color: $almost-black;
			}
		}

	}

	&:after {
		/* use !important to prevent issues with browser extensions that change fonts */
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
	
		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	&.back-button,
	&.next-button,
	&.prev-button,
	&.arrow-button,
	&.add-button-round {
		position: relative;

		&:after {
			color: $white;
			font-family: 'icomoon';
			font-size: 17px;
			font-weight: 600;
		}
	}

	&.next-button,
	&.prev-button {
		width: 30px;

		&:after {
			position: relative;
		}
	}
	
	&.next-button {
		&:after {
			content: '\edf6';
		}
	}

	&.prev-button {
		&:after {
			content: '\edfe';
		}
	}

	&.icon-btn {
		display: flex;
		flex-direction: row;
		flex-flow: row wrap;
		align-items: center;
		justify-content: flex-start;
			
		box-shadow: inset 0 0 20px rgba($pistachio,0.3);
		overflow: hidden;
		padding: 0;
		position: relative;

		.svg-container {
			background: $pistachio;
			height: 53px;
			padding: 12px 10px;
			text-align: center;
			width: 30%;
		}

		svg {
			fill: $white;
			height: auto;
			max-height: 26px;
			max-width: 26px;
			width: auto;
		}

		.button-text-container {
			font-size: 14px;
			font-weight: 600;
			line-height: 1.3;
			padding: 0 5px 0 5px;
			text-align: left;
			width: 70%;
		}
	}

	&.plus-button,
	&.minus-button {
		display: block;
		height: 40px;
		padding: 0;
		width: 40px;

		&:after {
			left: 12px;
			position: absolute;
			top: 10px;
		}
	}
		
	&.plus-button {		
		&:after {
			content: '\ed96';
		}
	}

	&.minus-button {		
		&:after {
			content: '\ed97';
		}
	}

	&.add-button-round,
	&.question-button-round {
		border-radius: 100%;
		cursor: pointer;
		height: 24px;
		padding: 0;
		position: relative;
		width: 24px;

		&:after {
			content: '\ed96';
			font-size: 15px;
			left: 50%;
			margin: 0 0 0 -7px;
			position: absolute;
			top: 4px;
		}

		&.small {
			height: 17px;
			width: 17px;

			&:after {
				font-size: 12px;
				margin: 0 0 0 -5px;
				top: 3px;
			}
		}

		&.assign {
			height: 17px;
			margin-top: 2px;
			vertical-align: top;
			width: 17px;

			&:after {
				font-size: 12px;
				margin: 0 0 0 -6px;
				top: 2px;
			}
		}

		&.add-event-button {
			background: $marigold;
			bottom: 20px;
			height: 34px;;
			position: fixed;
			right: 20px;
			z-index: 1;
			width: 34px;

			&:after {
				font-size: 25px;
				margin: 0 0 0 -12px;
			}
		}
	}

	&.question-button-round {
		font-style: normal;

		&:after {
			color: $white;
			content: '?';
			font-family: $base-font-family !important;
			font-size: 20px;
			font-weight: 600;
			margin: 0 0 0 -4px;
			top: 2px;
		}

		&:hover {
			&:after {
				color: $white;
			}
		}
	}

	&.arrow-button {
		margin: 0 0 20px 0;
		padding-right: 30px;

		&:after {
			content: '\edf6';
			position: absolute;
			right: 5px;
			//top: 8px;
		}
	}

	&.close-button {
		background: $marigold;
		margin: 0 0 $base-spacing 0;
		padding-right: 30px;

		&:after {
			content: '\e5cd';
			font-size: 22px;
			position: absolute;
			top: 5px;
		}

		&.round {
			border-radius: 100%;
			display: block;
			height: 20px;
			margin: 0;
			min-width: 20px;
			padding: 0;
			width: 20px;

			&:after {
				font-size: 16px;
				left: 3px;
				right: inherit;
				top: 1px;
			}
		}
	}

	&.back-button {
		&:after {
			content: '\edfe';
			margin-right: 15px;
			position: absolute;
			left: 10px;
		}
	}

	&.button-padding-right,
	&.arrow-button {
		margin: 0;
		padding-right: 30px;

		&:after {
			right: 5px;
		}
	}

	&.button-padding-left,
	&.back-button {
		padding-left: 30px;

		&:after {
			left: 5px;
		}
	}

	&.smaller-buttons {
		font-size: 15px;

		&:after,
		&::before {
			font-size: 15px;
		}
	}

	&:disabled,
	&.disabled {
		background: $ada-grey-3;
		color: $almost-black;
		cursor: not-allowed;
		pointer-events: none;

		&:hover {
			background: $ada-grey-3;
			color: $almost-black;
		}

		&:after,
		&:before {
			color: $almost-black;
		}
	}
}

.k-edit-form-container .k-primary, .k-popup .k-primary {
	background: $marigold !important;
	color: $white !important;

	&:visited {
		background: $marigold !important;
		color: $white !important;
	}
}

.k-event-delete {
	display: block;
}

.k-button,
.k-filter-menu .k-button,
.k-edit-form-container .k-button, 
.k-edit-form-container .k-primary, 
.k-popup .k-button, 
.k-popup .k-primary {
	&:nth-of-type(2) {
		background: $hub;
	}
}

.k-multiselect-wrap {
	.k-button,
	.k-filter-menu .k-button,
	.k-edit-form-container .k-button, 
	.k-edit-form-container .k-primary, 
	.k-popup .k-button, 
	.k-popup .k-primary {
		background: $marigold !important;

		&:hover,
		&.k-state-hover {
			background: $marigold !important;
		}

		// &:nth-of-type(2) {
		// 	background: $hub !important;
		// }
	}
}

td {
	.btn.close-button.round {
		right: 15px;
		top: 12px;
	}
}

.btn-plus-sign {
	background: transparent;
	border-radius: 0;
	color: $ship-gray;
	font-size: 16px;
	font-weight: 600;
	padding: 0 32px 0 0;
	position: relative;

	&:hover,
	&:visited,
	&:focus {
		background: transparent;
		color: $ship-gray;
	}

	&:after {
		background: $white;
		border: 1px solid $grey;
		border-radius: 100%;
		color: $grey;
		content: '+';
		font-weight: normal;
		font-size: 13px;
		height: 22px;
		margin-top: -11px;
		padding: 0 0 0 1px;
		position: absolute;
		right: 0;
		text-align: center;
		top: 50%;
		width: 22px;
	}

	&.icon-before {
		padding: 0 0 0 32px;

		&:after {
			left: 0;
			//padding: 4px;
			right: inherit;
		}
	}
}

button {
	&.btn-plus-sign {

		&:after {
			background: transparent;
			border: none;
			margin-top: -9px;
			//padding: 0;
			z-index: 2;
		}

		&:before {
			background: $white;
			border: 1px solid $grey;
			border-radius: 100%;
			content: '';
			height: 22px;
			margin-top: -11px;
			padding: 0 0 0 1px;
			position: absolute;
			right: 0;
			top: 50%;
			width: 22px;
			z-index: 1;
		}

		&.icon-before {
			padding: 0 0 0 32px;

			&:before {
				left: 0;
			}
	
			&:after {
				left: 0;
				right: inherit;
			}
		}
	}
}

a.btn-plain-text,
button.btn-plain-text,
input.btn-plain-text {
	color: $ship-gray;
	font-size: 16px;
	//font-weight: 500;

	&.back-button {
		background: url('../../Content/img/icons/icon_back-arrow.svg') no-repeat 0 5px;
		background-size: 18px;
		display: block;
		line-height: 1.5;
		min-width: 0;
		padding: 0 0 20px 25px;
		width: 100%;

		&:after {
			display: none;
		}

		&:hover {
			color: $pistachio;
		}
	}
}

@include bp(md) {
	#{$all-buttons},
	.button,
	.btn,
	a.btn,
	.ui-widget input.btn,
	.k-button,
	.k-filter-menu .k-button,
	.k-edit-form-container .k-button, 
	.k-edit-form-container .k-primary, 
	.k-popup .k-button, 
	.k-popup .k-primary,
	.ui-widget-content a.btn {
		&.planting-template-button-left,
		&.planting-template-button-right {
			font-size: 16px;
		}
			
		&.planting-template-button-left {
			padding: 7px 5px 10px 5px;
		}

		&.planting-template-button-right {
			padding: 7px 38px 10px 18px;
		}
	}

	a.btn-plain-text,
	button.btn-plain-text,
	input.btn-plain-text {
		&.back-button {
			width: auto;
		}
	}
}

@include bp(lg) {
	#{$all-buttons},
	.button,
	.btn,
	a.btn,
	.ui-widget input.btn,
	.k-button,
	.k-filter-menu .k-button,
	.k-edit-form-container .k-button, 
	.k-edit-form-container .k-primary, 
	.k-popup .k-button, 
	.k-popup .k-primary,
	.ui-widget-content a.btn {
		&.planting-template-button-right {
			padding: 7px 47px 10px 25px;
	
			&:after {
				font-size: 16px;
			}
		}
	}
}

@include bp(lgg) {
	#{$all-buttons},
	.button,
	.btn,
	a.btn,
	.ui-widget input.btn,
	.k-button,
	.k-filter-menu .k-button,
	.k-edit-form-container .k-button, 
	.k-edit-form-container .k-primary, 
	.k-popup .k-button, 
	.k-popup .k-primary,
	.ui-widget-content a.btn {
		&.icon-btn {
			.button-text-container {
				font-size: 13px;
			}

			&.marketwatch-lite {
				.button-text-container {
					font-size: 11px;
				}
			}
		}
	}
}

@include bp(xlg) {
	#{$all-buttons},
	.button,
	.btn,
	a.btn,
	.ui-widget input.btn,
	.k-button,
	.k-filter-menu .k-button,
	.k-edit-form-container .k-button, 
	.k-edit-form-container .k-primary, 
	.k-popup .k-button, 
	.k-popup .k-primary,
	.ui-widget-content a.btn {
		&.icon-btn {
			.button-text-container {
				font-size: 14px;
			}
		}
	}
}

//clear all button
.clear-all {
	color: $ship-gray;
	font-size: 16px;
	float: right;
	margin: 30px 0 0 0;
	text-align: right;
	text-decoration: underline;
}
//search button grey
.search-icon-button {
	position: relative;
	
	&:before {
		color: $gray-chateau;
		content: "\e8b6";
		font-family: 'icomoon';
		font-size: 22px;
		font-weight: 600;
	}

	&:hover {
		&:before {
			color: $pistachio;
		}
	}
}

//settings button
a.settings-icon-button {
	color: $ship-gray;
	font-size: 17px;
	font-weight: 600;
	padding: 0 0 0 30px;
	position: relative;

	&:before {
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
	
		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		
		color: $pistachio;
		content: "\e8b8";
		font-size: 25px;
		left: 0;
		position: absolute;
		top: -5px;
		transition: all $base-duration $base-timing;
	}

	&:hover {
		&:before {
			color: $marigold;
		}
	}
}

//button container styles
.title-button-wrapper {
	display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: space-between;
}

.button-container {
	display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: flex-start;
	
	#{$all-buttons},
	.button,
	.btn,
	a.btn,
	.ui-widget input.btn,
	.k-button,
	.k-filter-menu .k-button,
	.k-edit-form-container .k-button, 
	.k-edit-form-container .k-primary, 
	.k-popup .k-button, 
	.k-popup .k-primary,
	.ui-widget-content a.btn {
		margin: 0 4% 0 0;
		width: 48%;

		&:nth-child(2n+2),
		&:last-child {
			margin: 0;
		}

		&.wide-button {
			margin: 0 0 20px 0;
		}
	}

	&.single-button-centered {
		justify-content: center;
		padding-top: 30px;

		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			margin: 0;
			width: 150px;
		}
	}

	&.border-bottom {
		display: block;
		margin-bottom: 20px;
		position: relative;
		text-align: right;

		&:before {
			background: $hub;
			bottom: -10px;
			content: "";
			height: 2px;
			left: 0;
			position: absolute;
			width: 100%;
		}
	}

	&.section-top,
	&.section-top-negative-margin {
		button,
		.button,
		.btn,
		a.button {
			// font-size: 14px;
			// font-weight: 600;
			// line-height: 1em;
			margin: 0 20px 10px 0;
			padding: 8px 25px 8px 25px;
			width: auto;

			&:last-child {
				margin: 0 0 10px 0;
			}

			// &.add-button,
			// &.copy-button,
			// &.play-button {
			// 	&:after {
			// 		font-size: 14px;
			// 		position: relative;
			// 		top: 1px;
			// 	}
			// }

			// &.share-button,
			// &.archive-button,
			// &.unarchive-button {
			// 	padding-right: 30px;
				
			// 	&:after {
			// 		font-size: 18px;
			// 	}
			// }
		}

		&.checkbox-container,
		&.search-container,
		&.settings-container {
			display: flex;
		}

		p {
			margin: 0;
			text-align: left;
		}

		&.search-container {
			margin-left: 50%;

			input {
				margin: 0;
				width: 85%;
			}

			a {
				width: 15%;
			}
		}

		&.settings-container {
			justify-content: flex-end;
			margin: 20px 0;

			a {
				width: 100px;

				&.settings-icon-button {
					margin: 3px 20px 0 0;
				}

				&.btn-pistachio {
					margin: 0;
				}
			}
		}
	}

	&.section-top {
		display: flex;
		flex-direction: row;
		flex-flow: row wrap;
		//align-items: flex-end;
		align-items: center;
		justify-content: flex-end;

		margin-bottom: 20px;
		position: relative;
		text-align: right;
		z-index: 2;

		&.checkbox-container {
			#{$all-buttons},
			.button,
			.btn,
			a.btn,
			.ui-widget input.btn,
			.k-button,
			.k-filter-menu .k-button,
			.k-edit-form-container .k-button, 
			.k-edit-form-container .k-primary, 
			.k-popup .k-button, 
			.k-popup .k-primary,
			.ui-widget-content a.btn {
				&:last-child {
					margin: 10px 0 0 0;
				}
			}
		}
	}

	&.section-top-negative-margin {
		margin: 0 0 10px 0;
		position: relative;
		z-index: 2;
	}

	&.section-bottom {
		margin-top: 30px;
		padding-top: 20px;
		position: relative;

		&:before {
			background: $hub;
			content: "";
			height: 2px;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		p {
			margin-top: 20px;
		}

		&.add-new {
			margin: 0;
			padding: 15px 0 0 0;
		}

		&.dark-border {
			&:before {
				background: $pistachio;
				height: 1px;
			}
		}

		&.button-centered {
			margin-top: 15px;
			padding: 30px 0 0 0;

			a {
				margin: 0 auto;
			}
		}
	}

	&.no-border {
		&:before {
			display: none;
		}
	}

	&.no-margin-above {
		margin: 0;
	}

	&.single-button {
		display: block;
		text-align: right;

		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			margin: 0;
			width: auto;

			&.round {
				width: 20px;
			}
		}
	}

	&.three-button,
	&.three-button-wide,
	&.four-button,
	&.four-button-next-prev {
		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			margin-bottom: 10px;

			&:last-child  {
				margin: 0;
			}
		}
	}

	&.four-button,
	&.four-button-next-prev {
		align-items: flex-end;
		justify-content: flex-end;
	}

	&.three-button,
	&.three-button-wide {
		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {

			&:nth-child(3)  {
				margin: 0;
			}
		}
	}

	&.four-button,
	&.four-button-next-prev {
		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			&:last-child {
				margin: 0 0 10px 0;
			}
		}
	}

	&.left-button {
		display: block;
		padding-left: 0;
		text-align: left;

		button,
		.button,
		.btn,
		a.button {
			margin: 0;
			min-width: 125px;
			width: auto;

			&.wide-button {
				width: 250px;
			}
		}
	}

	&.button-min-width {
		button,
		.button,
		.btn,
		a.button {
			min-width: 150px;
		}
	}

	&.section-bottom.products,
	&.section-bottom.three-button-next-prev {
		margin-top: 0;

		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			&:last-child {
				margin: 20px 0 0 0;
			}
		}
	}

	&.single-button.left-button {
		button,
		.button,
		.btn,
		a.button {
			min-width: 125px;
			width: auto;
		}
	}
}

.section-bottom.no-buttons {
    border-top: 2px solid $hub;
    padding-top: 20px;
}

@include bp(sm) {
	.title-button-wrapper {
		border-bottom: 2px solid $hub;

		.button-container,
		h1 {
			width: 48%;
		}
		
		.button-container {
			margin: 0;
		}

		h1 {
			margin: 0 4% 10px 0;

			&:after {
				display: none;
			}
		}
	}

	.button-container {
		button,
		.button,
		.btn,
		a.button {
			width: auto;

			&.wide-button {
				width: 250px;
			}
		}
			
		&.section-top {
			margin: 0;
			//margin: 0 0 -24px 0;

			&.float-right {
				float: right;
				margin: 0 0 -28px 0;
				padding: 0;
			}

			&.checkbox-container {
				align-items: center;
				justify-content: flex-start;
				line-height: 1;

				label {
					font-size: 14px;;
					margin: 0;
				}

				&.checkbox-container {
					#{$all-buttons},
					.button,
					.btn,
					a.btn,
					.ui-widget input.btn,
					.k-button,
					.k-filter-menu .k-button,
					.k-edit-form-container .k-button, 
					.k-edit-form-container .k-primary, 
					.k-popup .k-button, 
					.k-popup .k-primary,
					.ui-widget-content a.btn {
						&:last-child {
							margin: 0;
						}
					}
				}
			}

			p {
				margin: 0 10px 0 0;
				text-align: right;
				width: auto;
			}

			&.search-container {
				margin-bottom: -20px;
				margin-left: 70%;
			}

			&.settings-container {
				margin: 0 0 -24px 0;
			}
		}

		&.section-top-negative-margin {
			margin: 0 0 -24px 0;
		}
		
		&.section-bottom {
			padding-left: 50%;
			width: 100%;
		}

		&.three-button,
		&.three-button-wide {
			padding-left: 25%;

			button,
			.button,
			.btn,
			a.button {
				margin: 0 2% 0 0;
				min-width: 0;
				width: 32%;

				&:last-child {
					margin: 0;
				}
			}

			&.split-buttons {
				padding-left: 0;

				button,
				.button,
				.btn,
				a.button {
					width: 23%;

					&:first-of-type {
						margin-right: 29%;
						
					}
				}
			}
		}

		&.three-button-wide {
			padding-left: 0;
		}

		&.four-button {
			padding-left: 0;

			button,
			.button,
			.btn,
			a.button {
				margin: 0 2% 0 0;
				min-width: 0;
				width: 23.5%;

				&:last-child {
					margin: 0;
				}
			}
		}

		&.four-button-next-prev {
			padding-left: 0;

			button,
			.button,
			.btn,
			a.button {
				margin: 0 2% 0 0;
				min-width: 0;
				width: 22.75%;

				&:nth-of-type(2) {
					margin: 0 5% 0 0;
				}

				&:last-child {
					margin: 0;
				}
			}
		}

		&.section-bottom.left-button {
			padding-left: 0;
		}

		&.section-bottom.next-prev {
			display: block;
			overflow: hidden;
			padding-left: 0;

			button,
			.button,
			.btn,
			a.button {
				width: 160px;

				&.next,
				&.complete {
					float: right;
				}

				&.wide-button {
					width: 250px;
				}
			}
		}

		&.section-bottom.single-button {
			padding-left: 0;

			button,
			.button,
			.btn,
			a.button {
				width: auto;
			}
		}

		&.scouting-report-view {
			margin-top: 0;
		}

		&.section-bottom.next-prev.products,
		&.section-bottom.three-button-next-prev {
			margin-top: 0;
	
			#{$all-buttons},
			.button,
			.btn,
			a.btn,
			.ui-widget input.btn,
			.k-button,
			.k-filter-menu .k-button,
			.k-edit-form-container .k-button, 
			.k-edit-form-container .k-primary, 
			.k-popup .k-button, 
			.k-popup .k-primary,
			.ui-widget-content a.btn {
				width: 28%;

				&:nth-of-type(2) {
					margin:  0 2% 0 9%;
				}

				&:last-child {
					margin: 0;
				}
			}
		}
	}
}

@include bp(md) {
	.title-button-wrapper {
		.button-container {
			width: 36%;
		}

		h1 {
			width: 60%;
		}
	}

	.button-container {
		&.section-top {
			// button,
			// .button,
			// .btn,
			// a.button {
			// 	font-size: 14px;

			// 	&:after {
			// 		font-size: 14px;
			// 	}
			// }

			&.search-container {
				margin-left: 80%;
			}
		}
		
		&.section-bottom {
			padding-left: 60%;
		}

		&.section-bottom.next-prev {
			button,
			.button,
			.btn,
			a.button {
				&.wide-button {
					//margin: 0 4% 0 0;
					min-width: 250px;
				}
			}
		}

		&.three-button {
			padding-left: 40%;

			&.split-buttons {
				padding-left: 0;

				button,
				.button,
				.btn,
				a.button {
					width: 18%;

					&:first-of-type {
						margin-right: 44%;
						
					}
				}
			}
		}

		&.three-button-wide,
		&.four-button {
			padding-left: 20%;
		}

		&.four-button-next-prev {
			padding-left: 0;

			button,
			.button,
			.btn,
			a.button {
				margin: 0 2% 0 0;
				min-width: 0;
				width: 19%;

				&:nth-of-type(2) {
					margin: 0 20% 0 0;
				}

				&:last-child {
					margin: 0;
				}
			}
		}

		&.section-bottom.next-prev.products,
		&.section-bottom.three-button-next-prev {
			#{$all-buttons},
			.button,
			.btn,
			a.btn,
			.ui-widget input.btn,
			.k-button,
			.k-filter-menu .k-button,
			.k-edit-form-container .k-button, 
			.k-edit-form-container .k-primary, 
			.k-popup .k-button, 
			.k-popup .k-primary,
			.ui-widget-content a.btn {
				width: 20%;

				&:nth-of-type(2) {
					margin:  0 2% 0 33%;
				}
			}
		}
	}
}

@include bp(lg) {
	.title-button-wrapper {
		.button-container {
			width: 34%;
		}

		h1 {
			width: 62%;
		}
	}

	.button-container {
		&.three-button {
			padding-left: 50%;
		}

		&.four-button {
			padding-left: 25%;
		}
	}
}

//grid-list-view-toggles
.grid-list-view-toggles,
.button-container.section-top.grid-list-view-toggles {
	display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	align-items: flex-end;
	justify-content: flex-end;

	.grid-view {
		margin: 0 5px 0 0;
	}

	.grid-view,
	.list-view {
		&.active {
			.u-icon-grid,
			.u-icon-list {
				&:before {
					color: $ship-gray;
				}
			}
		}
	}

	.u-icon-grid,
	.u-icon-list {
		cursor: pointer;
		display: block;
		height: 25px;
		position: relative;
		width: 25px;

		&:before {
			color: $ship-gray;
			left: 0;
			position: absolute;
		}
	}

	.u-icon-grid {
		&:before {
			font-size: 20px;
			top: 0;
		}
	}

	.u-icon-list {
		&:before {
			font-size: 30px;
			top: -6px;
		}
	}
}

.button-container.section-top.grid-list-view-toggles {
	margin: 52px 0 -52px 0;

	h4 {
		margin: 0 10px 2px 0;
	}
}

@include bp(md) {
	.button-container.section-top.grid-list-view-toggles {
		margin: 0 0 -28px 0;
	}
}

.download-manual {
	color: $pistachio;
	padding: 5px 0 5px 35px;
	position: relative;
	text-decoration: underline;

	&:before {
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
	
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		content: "\ecbe";
		font-size: 26px;
		left: 0;
		position: absolute;
		text-decoration: none;
		top: 0;
	}
}

//top button flex-end container
.button-container.top-section-flex-end-container {
	display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	align-items: center;
	justify-content: flex-end;

	margin: 0 0 10px 0;
	position: relative;
	z-index: 2;

	a {
		margin: 0 20px 0 0;
		width: auto;

		&.instructions-button {
			width: 45px;
		}

		&:nth-child(2n+2) {
			margin: 0 20px 0 0;
		}

		&:last-of-type {
			margin: 0;
		}
	}
}

.title-button-wrapper {
	.button-container.top-section-flex-end-container {
		justify-content: space-between;
		margin: -15px 0 10px 0;
	}
}

@include bp(sm) {
	.button-container.top-section-flex-end-container {
		& + h1 {
			margin-top: -25px;
		}

		&.section-top-negative-margin {
			margin: 0 0 -24px 0;
		}
	}
}

//drag and drop file upload
.box {
    background-color: $concrete;
    margin: 0 0 20px 0;
    padding: 60px 20px;
    position: relative;
    text-align: center;

    &.has-advanced-upload {
        outline: 2px dashed $iron;
        outline-offset: -10px;

        -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
        transition: outline-offset .15s ease-in-out, background-color .15s linear;
    }

    &.is-dragover {
        outline-offset: -10px;
        outline-color: $iron;
        background-color: $white;
    }

    label {
        color: $gray-chateau;
        font-size: 20px;

        span {
            font-size: 18px;
        }

        &:hover {
            color: $pistachio;

            span {
                color: $gray-chateau;
            }
        }
    }

    .upload-icon {
        color: $gray-chateau;
        font-size: 30px;
        font-weight: 400;
    }

    &.is-uploading,
    &.is-success,
    &.is-error {
        .box__input {
            visibility: hidden;
        }
    }
}

.box__dragndrop,
.box__icon {
    display: none;
}

.box__uploading,
.box__success,
.box__error {
    display: none;
}

.box.is-uploading .box__uploading,
.box.is-success .box__success,
.box.is-error .box__error {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;

    -webkit-transform: translateY( -50% );
    transform: translateY( -50% );
}

.box__uploading {
    font-style: italic;
}

.box__success {
    -webkit-animation: appear-from-inside .25s ease-in-out;
    animation: appear-from-inside .25s ease-in-out;
}

@-webkit-keyframes appear-from-inside {
    from	{ -webkit-transform: translateY( -50% ) scale( 0 ); }
    75%		{ -webkit-transform: translateY( -50% ) scale( 1.1 ); }
    to		{ -webkit-transform: translateY( -50% ) scale( 1 ); }
}

@keyframes appear-from-inside {
    from	{ transform: translateY( -50% ) scale( 0 ); }
    75%		{ transform: translateY( -50% ) scale( 1.1 ); }
    to		{ transform: translateY( -50% ) scale( 1 ); }
}

.box__restart {
    font-weight: 700;
}

.box__restart {
    &:focus,
    &:hover {
        color: $gray-chateau;
    }
}

.js {
    .box__file {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;

        + label {
            max-width: 80%;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            display: inline-block;
            overflow: hidden;

            &:hover strong {
                color: $gray-chateau;
            }
        }
    }
}

.box__file {
    &:focus + label strong,
    &.has-focus + label strong {
        color: $gray-chateau;
    }
}

.no-js .box__file + label {
    display: none;
}

.no-js .box__button {
    display: block;
}

//top-button-heading-container
.top-button-heading-container {
	display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-between;

	border-bottom: 2px solid $hub;
	margin: 0 0 20px 0;
	padding: 0 0 10px 0;

	h1.no-border {
		margin: 20px 0 0 0;
		order: 2;
		padding: 0;
		width: 100%;
	}
}

@include bp(sm) {
	.top-button-heading-container {
		justify-content: space-between;

		// a.btn,
		// a.settings-icon-button {
		// 	margin: 0;
		// 	order: 2;
		// }

		h1.no-border {
			margin: 0;
			order: 1;
			width: auto;
		}
	}
}

//new button container
.bottom-button-container {
	display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-between;

	border-top: 2px solid $pistachio;
	margin: 20px 0 0 0;
	padding: 20px 0 0 0;

	&.no-margin {
		margin: 0;
	}

	&.no-border {
		border: none;
	}

	&.no-padding {
		padding: 0;
	}

	&.even-buttons {
		//justify-content: flex-end;

		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			margin: 0 4% 20px 0;
			width: 48%;

			&:nth-of-type(2n+2) {
				margin: 0 0 20px 0;
			}

			&:last-child {
				margin: 0;
			}
		}
	}

	&.button-min-width {
		button,
		.button,
		.btn,
		a.button {
			margin: 0 4% 0 0 !important;
			min-width: 48%;
			
			&:last-of-type {
				margin: 0 !important;
			}
		}
	}

	&.flex-center {
		justify-content: center;

		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			margin: 0 20px 0 0;
			min-width: 100px;

			&:last-child {
				margin: 0;
			}
		}
	}

	&.flex-start {
		justify-content: flex-start;

		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			margin: 0 20px 0 0;

			&:last-child {
				margin: 0;
			}
		}
	}

	&.flex-end {
		justify-content: flex-end;

		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			margin: 0 20px 0 0;

			&:last-child {
				margin: 0;
			}
		}
	}

	&.flex-wide-buttons-mobile {
		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			margin: 0 auto 20px auto;
			width: 80%;

			&:last-child {
				margin: 0 auto;
			}
		}
	}

	.flex-two-buttons,
	.flex-three-buttons {
		display: flex;
		flex-direction: row;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;

		width: 100%;

		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			margin-bottom: 20px;
			width: 48%;

			&:last-child {
				margin: 0 0 20px 0;
			}
		}
	}

	.flex-space-between {
		display: flex;
		flex-direction: row;
		flex-flow: row wrap;
		align-items: flex-start;
		justify-content: space-between;

		width: 100%;
	}
	
	#{$all-buttons},
	.button,
	.btn,
	a.btn,
	.ui-widget input.btn,
	.k-button,
	.k-filter-menu .k-button,
	.k-edit-form-container .k-button, 
	.k-edit-form-container .k-primary, 
	.k-popup .k-button, 
	.k-popup .k-primary,
	.ui-widget-content a.btn {
		&:after {
			position: absolute;
			//top: 7px;
		}

		&.no-margin {
			margin: 0;
		}

		&.close-button {
			&:after {
				top: 5px;
			}
		}

		&:last-child {
			margin: 0;
		}

		&.archive-button,
		&.unarchive-button {
			padding-right: 35px;
		}
	}
}

@include bp(sm) {
	.bottom-button-container {
		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			margin: 0;
		}

		&.button-min-width {
			button,
			.button,
			.btn,
			a.button {
				min-width: 150px;
			}
		}
	

		&.flex-wide-buttons-mobile {
			#{$all-buttons},
			.button,
			.btn,
			a.btn,
			.ui-widget input.btn,
			.k-button,
			.k-filter-menu .k-button,
			.k-edit-form-container .k-button, 
			.k-edit-form-container .k-primary, 
			.k-popup .k-button, 
			.k-popup .k-primary,
			.ui-widget-content a.btn {
				margin: 0 20px 0 0;
				width: auto;

				&:last-child {
					margin: 0;
				}
			}
		}

		.flex-two-buttons,
		.flex-three-buttons {
			width: 50%;

			&.flex-start {
				justify-content: flex-start;
			}

			&.flex-end {
				justify-content: flex-end;
			}

			#{$all-buttons},
			.button,
			.btn,
			a.btn,
			.ui-widget input.btn,
			.k-button,
			.k-filter-menu .k-button,
			.k-edit-form-container .k-button, 
			.k-edit-form-container .k-primary, 
			.k-popup .k-button, 
			.k-popup .k-primary,
			.ui-widget-content a.btn {
				margin: 0 20px 0 0;
				width: auto;

				&:last-child {
					margin: 0;
				}
			}
		}

		&.even-buttons {
			justify-content: flex-end;
	
			#{$all-buttons},
			.button,
			.btn,
			a.btn,
			.ui-widget input.btn,
			.k-button,
			.k-filter-menu .k-button,
			.k-edit-form-container .k-button, 
			.k-edit-form-container .k-primary, 
			.k-popup .k-button, 
			.k-popup .k-primary,
			.ui-widget-content a.btn {
				margin: 0 20px 0 0;
				width: 200px;
	
				&:nth-of-type(2n+2) {
					margin: 0 20px 0 0;
				}
	
				&:last-child {
					margin: 0;
				}
			}
		}
	}
}

@include bp(md) {
	.bottom-button-container {
		.flex-two-buttons,
		.flex-three-buttons {
			#{$all-buttons},
			.button,
			.btn,
			a.btn,
			.ui-widget input.btn,
			.k-button,
			.k-filter-menu .k-button,
			.k-edit-form-container .k-button, 
			.k-edit-form-container .k-primary, 
			.k-popup .k-button, 
			.k-popup .k-primary,
			.ui-widget-content a.btn {
				margin: 0 20px 0 0;
			}
		}
	}
}

//next-prev-container-top
.next-prev-container-top {
	position: absolute;
	right: 0;
	top: 0;

	a {
		background: $marigold;
		color: $white;
		display: inline-block;
		font-weight: 600;
		position: relative;
		text-transform: uppercase;

		&:hover {
			background: $marigold-hover;
			color: $white;
		}

		&:after {
			color: $white;
			font-family: 'icomoon';
			font-size: 12px;
			font-weight: 600;
			position: absolute;
			top: 6px;
		}

		&.next-btn {
			background: $marigold;
			padding: 5px 20px 5px 10px;

			&:hover {
				background: $marigold-hover;
			}

			&:after {
				content: '\edf6';
				right: 5px;
			}
		}
	
		&.prev-btn {
			margin: 0 5px 0 0;
			padding: 5px 10px 5px 20px;
			
			&:after {
				content: '\edfe';
				left: 5px;
			}
		}
	
	}
}

// round plus icon before
a.btn-round-plus-icon-before {
	color: $ship-gray;
	padding: 6px 0 0 32px;	
	position: relative;

	&:before {
		background: $marigold;
		border-radius: 100%;
		color: $white;
		content: '\ed96';
		display: block;
		font-family: 'icomoon';
		font-size: 15px;
		height: 22px;
		left: 0;
		padding: 0 0 0 3px;
		position: absolute;
		top: 4px;
		width: 22px;
	}

	&:hover {
		color: $pistachio;

		&:before {
			background: $pistachio;
		}
	}
}

// round edit icon before
a.btn-round-edit-icon-before {
	color: $ship-gray;
	padding: 6px 0 0 32px;	
	position: relative;

	&:before {
		background: $marigold;
		border-radius: 100%;
		color: $white;
		content: '\e254';
		display: block;
		font-family: 'icomoon';
		font-size: 15px;
		height: 22px;
		left: 0;
		padding: 0 0 0 3px;
		position: absolute;
		top: 4px;
		width: 22px;
	}

	&:hover {
		color: $pistachio;

		&:before {
			background: $pistachio;
		}
	}
}

//add button before
.add-button-before {
	display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	align-items: center;
	justify-content: flex-start;
	
	color: $ship-gray;
	font-size: 16px;
	font-weight: bold;

	span {
		background: $marigold;
		border-radius: 100%;
		cursor: pointer;
		display: block;
		height: 24px;
		margin: 0 5px 0 0;
		padding: 0;
		position: relative;
		transition: all $base-duration $base-timing;
		width: 24px;

		&:after {
			font-family: 'icomoon';
			font-weight: 600;

			color: $white;
			content: '\ed96';
			font-size: 14px;
			left: 5px;
			position: absolute;
			transition: all $base-duration $base-timing;
			top: 2px;
		}
	}

	div {
		width: auto;
	}

	&.checklist {
		span {
			&:after {
				content: '\edab';
			}
		}
	}

	&.attachments {
		span {
			&:after {
				content: '\e226';
			}
		}
	}

	&:hover {
		color: $ship-gray;

		span {
			background: $marigold-hover;
		}
	}
}

//instructions button
a.instructions-button {
    background: $marigold;
    border-radius: 50%;
    color: $white;
    display: block;
    height: 45px;
    position: relative;
    top: 0;
    width: 45px;

    &:before {
        color: $white;
        content: '?';
        display: block;
        font-size: 32px;
        font-weight: 500;
        height: 45px;
        position: absolute;
        right: 0;
        text-align: center;
        top: -2px;
        transition: all $base-duration $base-timing;
        width: 45px;
    }

    &:hover {
        background: $marigold-hover;
        color: $white;
    }
}

label {
	a.instructions-button {
		background: transparent;
		height: 16px;
		margin-left: 10px;
		width: 16px;

		&:hover {
			background: transparent;
		}

		&:before {
			background: url("../../Content/img/icons/icon-info.svg") no-repeat center center;
			content: "";
			display: block;
			height: 16px;
			text-align: center;
			top: 0;
			width: 16px;
		}
	}
}

//btn container
.btn-container {
	display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	align-items: center;
	justify-content: flex-start;

	#{$all-buttons},
	.button,
	.btn,
	a.btn,
	.ui-widget input.btn,
	.k-button,
	.k-filter-menu .k-button,
	.k-edit-form-container .k-button, 
	.k-edit-form-container .k-primary, 
	.k-popup .k-button, 
	.k-popup .k-primary,
	.ui-widget-content a.btn,
	section {
		margin: 0 0 30px 0;
		width: 100%;
	}

	&.section-top {
		align-items: flex-end;
		justify-content: flex-end;
		margin: 0 0 10px 0;
		position: relative;
		z-index: 2;

		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			border-radius: 4px;
			font-size: 14px;
			margin: 0 5px 0 0;
			padding: 5px 10px 5px 10px;
			width: auto;
		}

		a.instructions-button {
			background: transparent;
			border: 2px solid $silver2;
			color: $silver2;
			height: 30px;
			position: relative;
			width: 30px;

			font-family: 'icomoon' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;

			/* Better Font Rendering =========== */
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			&::before {
				color: $silver2;
				content: '\ed91';
				font-size: 18px;
				font-weight: 400;
				height: 30px;
				right: inherit;
				left: -1px;
				top: 3px;
				width: 30px;
			}
		}
	}
}


@include bp(sm) {
	.btn-container {

		&.flex-center {
			justify-content: center;
		}
		
		&.flex-start {
			justify-content: flex-start;
		}

		&.flex-end {
			justify-content: flex-end;
		}

		&.flex-space-between {
			justify-content: space-between;
		}

		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn,
		section {
			margin: 0 20px 0 0;
			//padding: 13px 45px 13px 45px;
			min-width: 150px;
			width: auto;

			&.btn-ghost {
				//padding: 11px 41px 11px 41px;
			}

			&:nth-last-of-type(2),
			&:nth-of-type(2n+2) {
				margin: 0 20px 0 0;
			}

			&:last-of-type {
				margin: 0;
			}
		}

		&.section-top {
			#{$all-buttons},
			.button,
			.btn,
			a.btn,
			.ui-widget input.btn,
			.k-button,
			.k-filter-menu .k-button,
			.k-edit-form-container .k-button, 
			.k-edit-form-container .k-primary, 
			.k-popup .k-button, 
			.k-popup .k-primary,
			.ui-widget-content a.btn {
				padding: 5px 10px 5px 10px;
				min-width: 125px;

				&:nth-of-type(2n+2),
				&:nth-last-of-type(2) {
					margin: 0 5px 0 0;
				}
			}
		}
	}
}

@include bp(lg) {
	.btn-container {
		&.section-top {
			margin: 0 30px -35px 0;
		}
	}
}



///////////////////////////////////////////////
//new design
///////////////////////////////////////////////
body.new-design {
	.btn-plus-sign {
		font-size: 16px;
		font-weight: 600;
		padding: 0 32px 0 0;
		position: relative;

		&:after {
			background: $white;
			border: 1px solid $grey;
			border-radius: 100%;
			color: $grey;
			content: '+';
			font-weight: normal;
			font-size: 13px;
			height: 22px;
			margin-top: -11px;
			padding: 0 0 0 1px;
			position: absolute;
			right: 0;
			text-align: center;
			top: 50%;
			width: 22px;
		}

		&.icon-before {
			font-weight: 400;
			padding: 0 0 0 32px;

			&:after {
				right: inherit;
				left: 0;
			}
		}
	}

	.btn-container {
		display: flex;
		flex-direction: row;
		flex-flow: row wrap;
		align-items: center;
		justify-content: flex-start;

		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			margin: 0 2% 10px 0;
			padding: 13px 10px 13px 10px;
			width: 48%;

			&:nth-last-of-type(2) {
				margin: 0 2% 10px 0;
			}

			// &:nth-of-type(2n+2),
			// &:last-of-type {
			// 	margin-right: 0;
			// }

			&.btn-ghost {
				padding: 11px 8px 11px 8px;
			}
		}

		&.section-top {
			align-items: flex-end;
			justify-content: flex-end;
			margin: 0 0 10px 0;
			position: relative;
			z-index: 2;

			#{$all-buttons},
			.button,
			.btn,
			a.btn,
			.ui-widget input.btn,
			.k-button,
			.k-filter-menu .k-button,
			.k-edit-form-container .k-button, 
			.k-edit-form-container .k-primary, 
			.k-popup .k-button, 
			.k-popup .k-primary,
			.ui-widget-content a.btn {
				border-radius: 4px;
				font-size: 14px;
				margin: 0 5px 0 0;
				//min-width: 100px;
				padding: 5px 10px 5px 10px;
				width: auto;

				&.btn-ghost {
					border-width: 1px;
				}
			}

			a.instructions-button {
				background: transparent;
				border: 2px solid $silver2;
				color: $silver2;
				height: 30px;
				position: relative;
				width: 30px;

				font-family: 'icomoon' !important;
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				line-height: 1;

				/* Better Font Rendering =========== */
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;

				&::before {
					color: $silver2;
					content: '\ed91';
					font-size: 18px;
					font-weight: 400;
					height: 30px;
					right: inherit;
					left: -1px;
					top: 3px;
					width: 30px;
				}
			}
		}
	}

	.narrow-button-container {
		margin: 0 auto;
		text-align: center;
		width: 420px;

		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			padding: 13px 0;

			&.btn-ghost {
				padding: 11px 0;
			}
		}
	}
	
	#{$all-buttons},
	.button,
	.btn,
	a.btn,
	.ui-widget input.btn,
	.k-button,
	.k-filter-menu .k-button,
	.k-edit-form-container .k-button, 
	.k-edit-form-container .k-primary, 
	.k-popup .k-button, 
	.k-popup .k-primary,
	.ui-widget-content a.btn {
        background: $rio-grande;
        border-radius: 100px;
        color: $emperor;
        display: inline-block;
		font-family: 'Source Sans Pro', sans-serif;
		font-size: 18px;
        font-weight: 600;
        //margin-bottom: 20px;
        padding: 13px 25px 13px 25px;
		text-decoration: none;

        &:visited {
            background: $rio-grande;
            color: $emperor;

            &:hover,
            &:focus {
                background: $bitter-lemon;
                color: $emperor;
            }
        }

        &:hover,
        &:focus {
            background: $bitter-lemon;
            color: $emperor;
        }

        // &:last-child {
        //     margin-bottom: 20px;
        // }

		&.btn-chambray {
			background: $chambray;
			color: $white;

			&:hover,
			&:focus {
				background: $biscay;
				color: $white;
			}
		}

		&.btn-plantation {
			background: $plantation;
			color: $white;

			&:hover,
			&:focus {
				background: $killarney;
				color: $white;
			}
		}

		&.btn-cutty-sark {
			background: $cutty-sark;
			color: $white;

			&:hover,
			&:focus {
				background: $sea-nymph;
				color: $white;
			}
		}

		&.btn-killarney {
			background: $killarney;
			color: $white;

			&:hover,
			&:focus {
				background: $sea-nymph;
				color: $white;
			}

			&:disabled,
			&.disabled {
				background: $ada-grey-2;
				color: $mine-shaft;
				font-weight: 400;
				opacity: 1;

				&:hover {
					background: $ada-grey-2;
					color: $mine-shaft;
				}
			}
		}

		&.btn-spring-leaves {
			background: $spring-leaves;
			color: $white;

			&:hover,
			&:focus {
				background: $plantation;
				color: $white;
			}
		}

		&.btn-white {
			background: $white;
			color: $killarney;

			&:hover {
				background: $ada-grey-4;
				color: $killarney;
			}
		}

		&:disabled,
		&.disabled {
			background: $ada-grey-3;
			color: $mine-shaft;
			font-weight: 400;
			opacity: 1;

			&:hover {
				background: $ada-grey-3;
				color: $mine-shaft;
			}
		}

        &.btn-ghost {
            background: transparent;
            border: 2px solid $rio-grande;
			font-weight: bold;
            padding: 11px 21px 11px 21px;

            &:visited {
                background: transparent;
                border: 2px solid $rio-grande;
            }

            &:hover,
            &:focus {
                background: $ada-grey-5;
            }

			&:disabled,
			&.disabled {
				border: 2px solid $ada-grey-3;
				color: $mine-shaft;
				font-weight: 400;
				opacity: 1;

				&:hover {
					border: 2px solid $ada-grey-3;
					color: $mine-shaft;
				}
			}

			&.btn-ghost-white-bg {
				background: $white;

				&:visited {
					background: $white;					
				}
			}

			&.btn-ghost-chambray {
				border: 2px solid $chambray;
				color: $chambray;

				&:visited {
					border: 2px solid $chambray;
				}

				&:hover,
				&:focus {
					background: $chambray;
					color: $white;
				}
			}

			&.btn-ghost-plantation {
				border: 2px solid $plantation;
				color: $plantation;

				&:visited {
					border: 2px solid $plantation;
				}

				&:hover,
				&:focus {
					background: $plantation;
					color: $white;
				}
			}

			&.btn-ghost-spring-leaves {
				border: 2px solid $spring-leaves;
				color: $spring-leaves;

				&:visited {
					border: 2px solid $spring-leaves;
				}

				&:hover,
				&:focus {
					background: $ada-grey-5;
					color: $spring-leaves;
				}
			}

			&.btn-ghost-cutty-sark {
				border: 2px solid $cutty-sark;
				color: $spring-leaves;

				&:visited {
					border: 2px solid $cutty-sark;
				}

				&:hover,
				&:focus {
					background: $ada-grey-5;
					color: $spring-leaves;
				}
			}

			&.btn-ghost-bitter-lemon {
				border: 2px solid $bitter-lemon;
				color: $mine-shaft;

				&:visited {
					border: 2px solid $bitter-lemon;
				}

				&:hover,
				&:focus {
					background: $bitter-lemon-hover;
					color: $mine-shaft;
				}
			}

			&.btn-ghost-mine-shaft {
				border: 2px solid $mine-shaft;
				color: $mine-shaft;

				&:visited {
					border: 2px solid $mine-shaft;
				}

				&:hover,
				&:focus {
					background: $ada-grey-5;
					color: $mine-shaft;
				}
			}

			&.btn-ghost-white {
				border: 2px solid $white;
				color: $white;

				&:visited {
					border: 2px solid $white;
				}

				&:hover,
				&:focus {
					background: $white;
					color: $killarney;
				}

				&:disabled,
				&.disabled {
					background: $ada-grey-2;
					border: 2px solid $ada-grey-2;
					color: $mine-shaft;
					font-weight: 400;
					opacity: 1;

					&:hover {
						background: $ada-grey-2;
						border: 2px solid $ada-grey-2;
						color: $mine-shaft;
					}
				}
			}
        }
    }

	a.btn-plain-text {
		color: $mine-shaft;
		font-size: 16px;
		font-weight: 500;
	}

	.heading-container {
		#{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn {
			//border-width: 1px;
			font-size: 16px;
			font-weight: 500;
			padding: 6px 24px 6px 24px;

			&.btn-ghost {
				//border-width: 1px;
				font-size: 16px;
				font-weight: 600;
				padding: 4px 20px 4px 20px;
			}
		}
	}
}

@include bp(sm) {
	body.new-design {
		.btn-container {
			#{$all-buttons},
			.button,
			.btn,
			a.btn,
			.ui-widget input.btn,
			.k-button,
			.k-filter-menu .k-button,
			.k-edit-form-container .k-button, 
			.k-edit-form-container .k-primary, 
			.k-popup .k-button, 
			.k-popup .k-primary,
			.ui-widget-content a.btn {
				margin: 0 20px 0 0;
				padding: 13px 45px 13px 45px;
				width: auto;
	
				&.btn-ghost {
					padding: 11px 41px 11px 41px;
				}

				&:nth-last-of-type(2),
				&:nth-of-type(2n+2) {
					margin: 0 20px 0 0;
				}
	
				&:last-of-type {
					margin: 0;
				}
			}

			&.section-top {
				#{$all-buttons},
				.button,
				.btn,
				a.btn,
				.ui-widget input.btn,
				.k-button,
				.k-filter-menu .k-button,
				.k-edit-form-container .k-button, 
				.k-edit-form-container .k-primary, 
				.k-popup .k-button, 
				.k-popup .k-primary,
				.ui-widget-content a.btn {
					padding: 5px 10px 5px 10px;
					min-width: 125px;

					&:nth-of-type(2n+2),
					&:nth-last-of-type(2) {
						margin: 0 5px 0 0;
					}
				}
			}
		}
	}
}

@include bp(lg) {
	body.new-design {
		.btn-container {
			&.section-top {
				margin: 0 30px -35px 0;
			}
		}
	}
}

