footer.site-footer {
    background: $pistachio;
    border-top: 2px solid $hub;
    bottom: 0;
    height: auto;
    margin: 0;
    position: relative;
    width: 100%;

    section {
        margin: 0 auto;
        max-width: 70em;
        padding: bsu(0.5) 0;
        text-align: center;
        width: 90%;
    }

    ul {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: top;
        justify-content: center;
        list-style: none;
        margin: 0;

        list-style: none;
        margin: 0;

        li {
            margin: 0;
            padding: 0 bsu(1);
            position: relative;

            &:after {
                background: $white;
                border-radius: 100%;
                content: '';
                display: block;
                height: 6px;
                margin: -3px 0 0 0;
                position: absolute;
                right: -3px;
                top: 50%;
                width: 6px;
            }

            &:last-of-type {
                &:after {
                    display: none;
                }
            }
        }
    }

    a {
        color: $white;
        position: relative;

        &:after {
            background: $white;
            bottom: -5px;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            transition: all $base-duration $base-timing;
            width: 0%;
        }

        &:hover {
            color: $white;

            &:after {
                width: 100%;
            }
        }
    }

    &.new-design {
        background: $mine-shaft;
        border-top: none;

        section {
            margin: 0 auto;
            max-width: 90%;
            padding: 30px 0;
            text-align: center;
            width: 90%;
        }
    
        ul {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: top;
            justify-content: center;
            list-style: none;
            margin: 0;
    
            list-style: none;
            margin: 0;
    
            li {
                margin: 0;
                padding: 0 bsu(1);
                position: relative;
    
                &:after {
                    display: none;
                }
            }
        }
    
        a {
            color: $white;
            position: relative;
            text-decoration: underline;
    
            &:after {
                display: none;
            }
        }
    }
}

//mobile app footer adjust for iPhone X
footer.site-footer {
    margin-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
    margin-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}

@include bp(sm) {
   
}

//sticky button footer
.sticky-footer {
    background: $gallery;
    bottom: 0;
    position: sticky;
    width: 100%;
    z-index: 3;

    section {
        margin: 0 auto;
        max-width: 90%;
        padding: 30px 0;
        width: 90%;

        &.btn-container {
            .btn {
                margin: 0;
                width: 100%;

                &:first-of-type {
                    margin: 0 0 10px 0;
                }
            }
        }
    }
}

@include bp(sm) {
    .sticky-footer {
        position: relative;

        section {
            padding-top: 0;
            
            &.btn-container {
                justify-content: flex-end;

                .btn {
                    width: auto;

                    &:first-of-type {
                        margin: 0 20px 0;
                    }
                }
            }
        }
    }
}
