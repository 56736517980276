.toggle-button,
.toggle-button-season {
    cursor: pointer;
    display: block;
    height: 30px;
    position: relative;
    width: 30px;

    &:before {
        display: none;
    }

    &:hover {
        color: $pistachio;
    }

    .u-icon-accordion,
    .u-icon-accordion2 {
        &:before {
            position: absolute;
            transition: all $base-duration $base-timing;
        }
    }

    .u-icon-accordion2 {
        &:before {
            transition: none;
        }
    }

    &.open {
        color: $pistachio;

        .u-icon-accordion {
            &:before {
                transform: rotate(90deg);
            }
        }

        .u-icon-accordion2 {
            &:before {
                transform: rotate(180deg);
            }
        }
    }
}

.toggle-button-filter {
    &:hover {
        color: $pistachio;
    }

    .u-icon-accordion {
        &:before {
            position: absolute;
            transition: all $base-duration $base-timing;
        }
    }

    &.open {
        color: $pistachio;

        .u-icon-accordion {
            &:before {
                transform: rotate(90deg);
            }
        }
    }
}

.toggle-content,
table.non-responsive td.toggle-content, 
table.non-responsive th.toggle-content {
    display: none;
}

//green bar toggle buttons
.toggle-button.color-bar,
.toggle-button-season.color-bar {
    background: $hub;
    cursor: pointer;
    height: auto;
    padding: bsu(0.15) bsu(0.5) bsu(0.25) bsu(1.75);
    position: relative;
    width: 100%;

    h1.no-border {
        color: $white;
        margin: 0;
        padding: 0;
    }

    &.green-bar {
        background: $green;
    }

    &.hub-lightest-bar {
        background: $hub-lightest;
        color: $ship-gray;

        label {
            color: $ship-gray;
        }

        .u-icon-accordion {
            &:before {
                color: $ship-gray;
            }
        }
    }

    &.pistachio-bar {
        background: $pistachio;
    }

    &.marigold-bar {
        background: $marigold;
    }

    &.board-bar {
        background: rgba($concrete,0.5);
        
        h1.no-border,
        label {
            color: $ship-gray;
        }

        label {
            font-weight: normal;
        }
    }

    .u-icon-accordion {
        &:before {
            color: $white;
            left: 7px;
            position: absolute;
            top: 8px;
        }
    }

    label {
        color: $white;
        font-size: 18px;
        margin: 0;
        padding: 0;
        width: 50%;
    }

    &.full-width {
        label {
            width: 100%;
        }
    }
}

//toggle buttons in tables
.view-report-details.toggle-button,
.view-toggle-content.toggle-button {
    background: $hub;
    color: $white;
    display: block;
    min-height: 40px;
    padding: bsu(0.5) bsu(0.5) bsu(0.5) bsu(1.5);
    width: 100%;

    &:hover {
        background: $pistachio;
        color: $white;
        cursor: pointer;
    }

    .u-icon-accordion {
        display: block;
        position: absolute;
        width: 60px;        

        &:before {
            color: $white;
            font-size: 14px;
            left: -20px;
            top: 3px;
        }
    }

    label {
        display: inline;
        font-weight: 600;
        padding-left: bsu(0.25);
    }

    &.open {
        color: $white;
    }
}

@include bp(md) {
    .view-report-details.toggle-button,
    .view-toggle-content.toggle-button {
        background: transparent;
        color: $ship-gray;
        left: 0;
        padding: 0;
        position: absolute;
        top: -1px;
        width: 30px;

        &:hover {
            background: transparent;
            color: $ada-grey-2; 
        }

        .u-icon-accordion {
            &:before {
                color: $ada-grey-2;
                left: 10px;
                top: 16px;
                z-index: 3;
            }
        }

        &.open {
            .u-icon-accordion {
                &:before {
                    color: $ada-grey-2;
                }
            }
        }

        label {
            visibility: hidden;
            transform: scale(0);
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
    }

    .view-toggle-content.toggle-button {
        left: inherit;
        right: 4%;

        &.left {
            left: 0;
            right: inherit;
        }
    }
}

//details hidden content
.report-details,
.grey-bg {
    background: $black-haze;
    box-shadow: inset 0 0 20px rgba($gray-chateau, 0.5);
    padding: bsu(1);

    p {
        span {
            font-weight: 600;
        }
    }

    table,
    table.green-header {
        border: none;
        margin-top: bsu(1);

        tr {
            background: $concrete;
            border-bottom: 2px solid $pistachio;
            margin: 0 0 bsu(1) 0;
            padding: 0 0 bsu(1) 0;

            &:nth-of-type(2n+2) {
                background: $concrete;
            }

            &:last-of-type {
                margin: 0;
            }
        }

        th,
        td {
            background: transparent;
            font-size: 13px;
        }

        td {
            text-align: left;

            &:nth-of-type(2n+2) {
                background: $white;
            }
        }
    }
}

@include bp(sm) {
    .report-details,
    .grey-bg {
        margin: 0 bsu(0.25);

        table,
        table.green-header {
            thead {
                border-bottom: 2px solid $white;
                clip: 0;
                font-weight: 600;
                height: auto;
                margin: 0;
                position: relative;
                width: auto;

                tr {
                    background: $hub;
                }
            }

            tr {
                background: $concrete;
                border: none;
                display: table-row;
        
                td, th {
                    background: transparent;
                    display: table-cell;
                    text-align: left;

                    &:nth-of-type(2n+2) {
                        background: transparent;
                    }
                }
                
                th {
                    padding: bsu(0.25) bsu(0.5) bsu(0.5) bsu(0.5);
                }

                &:nth-of-type(2n+2) {
                    background: $white;
                }
            }
        
            td {
                border-radius: 0;
                padding: bsu(0.25) bsu(0.5);
            
                &:before {
                    display: none;
                }
            }
        }
    }
}

//table toggle styles
.row {
    position: relative;
}

.toggle-content.toggle-content-table {
    background: $black-haze;
    box-shadow: inset 0 0 20px rgba($gray-chateau, 0.5);
    padding: 0;

    table.non-responsive {
        border: none;
        margin: 0;

        tr {
            background: none;

            td {
                background: none;
                padding: bsu(0.5) bsu(0.75);
            }
        }
    }
}

.stripey-row {
    .row {
        table {
            background: $concrete;
        }

        &:nth-of-type(2n+2) {
            table {
                background: transparent;
            }
        }
    }

    table {
        tr {
            td {
                background: transparent;

                &:nth-of-type(2n+2) {
                    background: transparent;
                }
            }
        }
    }

    &.with-table {
        .row {
            background: transparent;

            table {
                background: $concrete;
            }

            &:nth-of-type(2n+2) {
                background: transparent;

                table {
                    background: transparent;
                }
            }
        }
    }
}

//basic toggle container {
.basic-toggle-container {
    border-bottom: 1px solid $ada-grey-3;
    padding: 20px 0;

    .toggle-button {
        height: auto;
        width: 100%;
        
        h5 {
            margin: 0;
            padding: 0 0 0 30px;
        }
    }

    .u-icon-accordion {
        &:before {
            top: 5px;
        }
    }

    .toggle-content {
        padding: 20px 30px 0 30px;
    }

    &.color-bar-background {
        border: none;
        margin: 0 0 5px 0;
        padding: 0;

        .toggle-button {
            h1, h5 {
                background: $pistachio;
                color: $white;
                font-weight: 600;
            }
            h1{
                font-size: 22px;
                padding: 4px 10px 9px 40px;
                margin-bottom: 0px;
                &:after{
                    display: none;
                }
            }
            h5 {
                padding: 8px 10px 9px 40px;
            }
        }

        .u-icon-accordion {
            &:before {
                color: $white;
                left: 10px;
                top: 14px;
            }
        }
    }
}

.basic-toggle-container2 {
    border: 1px solid $hub;
    border-radius: 6px;
    margin: 0 0 10px 0;
    padding: 10px 15px;

    .toggle-button {
        height: auto;
        width: 100%;
        
        h5 {
            font-size: 16px;
            margin: 0;
            padding: 0 30px 0 0;
        }

        h6 {
            color: $grey;
            font-size: 14px;
            font-weight: normal;
            margin: 0;
        }

        .u-icon-accordion2 {
            &:before {
                right: 0;
            }
        }
    }

    .toggle-content {
        padding: 10px 0;
    }
}

@include bp(md) {
    .basic-toggle-container2 {
        margin: 0 0 10px 0;
        padding: 10px 20px;
    
        .toggle-button {
            h5 {
                font-size: 18px;
            }
        }
    
        .toggle-content {
            padding: 10px 0;
        }
    }
}

//new design
body.new-design {
    .toggle-button,
    .toggle-button-season {
        &:hover {
            color: $silver3;
        }

        &.open {
            color: $silver3;

            .u-icon-accordion {
                &:before {
                    transform: rotate(90deg);
                }
            }
        }
    }

    h2 {
        &.toggle-button {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between;
            
            color: $emperor;
            height: auto;
            margin: 0;
            position: relative;
            width: 100%;

            &.open,
            &:hover {
                color: $emperor;
            }

            .u-icon-accordion {
                position: relative;
                height: 20px;
                width: 20px;

                &::before {
                    left: 0;
                    top: 0;
                }
            }

            &.quality-control-filters {
                display: flex;
            }
        }

        &.quality-control-filters {
            display: none;
        }
    }

    .row {
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }

        .toggle-button {
            background: $ada-grey-5 url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat right center;
            background-size: 24px;
            //border-radius: 6px;
            color: $mine-shaft;
            font-weight: bold;
            height: auto;
            padding: 10px;
            width: 100%;

            &.open {
                background: $ada-grey-5 url('../../Content/img/icons/icon_arrow_up_light.png') no-repeat right center;
                background-size: 24px;
            }
        }
    }

    .toggle-content {
        ul {
            list-style: none;
            margin: 0 0 0 10px;

            li {
                display: flex;
                flex-direction: row;
                flex-flow: row wrap;
                align-items: flex-start;
                justify-content: flex-start;

                background: $ada-grey-5;
                border-radius: 0;
                margin: 4px 0 0 0;
                padding: 10px;

                section {
                    display: flex;
                    flex-direction: row;
                    flex-flow: row wrap;
                    align-items: top;
                    justify-content: flex-start;
                }

                h3 {
                    margin: 0;
                }
            }

            &.non-striped {
                li {
                    background: transparent;
                    border-radius: 0;
                    margin: 4px 0 0 0;
                    padding: 5px 10px;
                }
            }
        }
    }

    .saved-item {
        ul {
            list-style: none;
            margin: 0;

            li {
                font-size: 16px;
                margin: 0;
                position: relative;

                .ellipsis-container {
                    position: absolute;
                    right: 10%;
                    top: 8px;

                    .u-icon-ellipsis {
                        width: 22px;
                    }
                }

                .ellipsis-option-list,
                .ellipsis-option-list-custom {
                    left: inherit;
                    right: 0;
                }

                .toggle-button {
                    background: transparent url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat 10px center;
                    background-size: 24px;
                    color: $mine-shaft;
                    height: auto;
                    font-size: 16px;
                    padding: 7px 0 7px 35px;
                    position: relative;
                    width: 60%;
                    z-index: 1;

                    &.open,
                    &:hover {
                        color: $mine-shaft;
                    }

                    &.open {
                        background: transparent url('../../Content/img/icons/icon_arrow_up_light.png') no-repeat 10px center;
                        background-size: 24px;
                    }
                }

                .toggle-content {
                    margin: 0 10%;
                    max-height: 200px;
                    overflow-y: scroll;
                    width: 80%;

                    ul {
                        li {
                            padding: 5px 10px;
                        }
                    }
                }
            }
        }

        &.recording-elements {
            ul {
                li {
                    .toggle-button {
                        display: flex;
                        flex-direction: row;
                        flex-flow: row wrap;
                        align-items: center;
                        justify-content: flex-start;

                        background: transparent url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat right center;
                        background-size: 24px;
                        border-bottom: 1px solid $sea-nymph;
                        padding: 7px 0 7px 10px;
                        width: 100%;

                        svg {
                            fill: $grey;
                            margin-right: 10px;
                            width: 28px;
                        }

                        &.open {
                            background: transparent url('../../Content/img/icons/icon_arrow_up_light.png') no-repeat right center;
                            background-size: 24px;
                        }
                    }

                    &:last-of-type {
                        .toggle-button {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}

@include bp(sm) {
    body.new-design {
        h2 {
            &.quality-control-filters {
                display: block;

                &.toggle-button {
                    display: none;
                }
            }
        }

        .toggle-content.quality-control-filters {
            display: block;
        }

        .saved-item {
            ul {
                li {
                    .ellipsis-option-list,
                    .ellipsis-option-list-custom {
                        left: 0;
                        right: inherit;
                    }
                }
            }
        }
    }
}

@include bp(md) {
    body.new-design {
        .row {
            margin: 0;

            .toggle-button {
                background: transparent url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat right center;
                background-size: 24px;
                border-radius: 0;
                height: 25px;
                left: 12%;
                padding: 0;
                position: absolute;
                text-indent: -5000px;
                top: 5px;
                width: 30px;

                &.open {
                    background: transparent url('../../Content/img/icons/icon_arrow_up_light.png') no-repeat right center;
                    background-size: 24px;
                }
            }

            .toggle-content {
                ul {
                    margin: 0 0 0 40px;

                    li {
                        padding-left: 60px;
                    }
                }
            }
        }

        .saved-item {
            min-height: 200px;

            &.recording-elements {
                ul {
                    li {
                        .toggle-button {
                            // background: transparent url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat 80% center;
                            // background-size: 24px;
                        }

                        &:last-of-type {
                            .toggle-button {
                                border-bottom: 1px solid $sea-nymph;
                            }
                        }
                    }
                }
            }
        }
    }
}

//toogle multiselect
.toggle-multiselect {
    .styled-checkbox {
        display: none;
        height: 20px;
        left: 50%;
        margin: 0;
        opacity: 0;
        position: absolute;
        top: 10px;
        width: 20px;
        z-index: 1;

        &:checked~label:before {
            background: $white;
        }
    }

    .toggle-button.select {
        background: $white url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat right center;
        background-size: 24px;
        color: $mine-shaft;

        border: $base-border;
        border-radius: 4px 4px 0 0;
        height: 44px;
        font-size: 16px;
        line-height: 1.15;
        margin-bottom: 0;
        max-width: 350px;
        position: relative;
        padding: 11px 13px;
        transition: border-color $base-duration $base-timing;
        width: 100%;
        z-index: 3;

        &:hover {
            border-color: $ada-grey-3;
        }

        &:focus {
            border-color: $ada-grey-3;
        }

        &::placeholder {
            color: $emperor;
        }
    }

    .toggle-content {
        border: $base-border;
        border-radius: 0 0 4px 4px;
        //box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
        margin-top: -1px;
        width: 100%;
        //width: 337px;
        z-index: 2;

        &.right-justified {
            right: 0;
        }

        .section-header {
            border-bottom: 2px solid $killarney;
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: center;
            justify-content: flex-start;
            margin: 0 0 5px 0;
            padding: 0 0 5px 0;

            h3 {
                font-size: 18px;
                font-weight: bold;
                margin: 0 10px 0 0;
            }
        }

        ul {
            li {
                background: $gallery;
                border-radius: 0;
                display: flex;
                flex-direction: row;
                flex-flow: row wrap;
                align-items: flex-start;
                justify-content: flex-start;
                margin: 0;
                padding: 0;
                position: relative;

                
                // section {
                //     background: $gallery;
                // }

                &:nth-of-type(2n+2) {
                    // section {
                    //     background: transparent;
                    // }
                    background: $white;
                }

                &:last-of-type {
                    border-radius: 0 0 4px 4px;
                }
                
                .toggle-content {
                    border: none;
                    border-radius: 0;
                    box-shadow: none;
                    margin: 0;
                }

                .toggle-button {
                    background: transparent url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat right center;
                    background-size: 24px;
                    border-radius: 0;
                    height: 25px;
                    padding: 0;
                    position: absolute;
                    right: 0;
                    text-indent: -5000px;
                    top: 9px;
                    width: 30px;
                    z-index: 3;
    
                    &.open {
                        background: transparent url('../../Content/img/icons/icon_arrow_up_light.png') no-repeat right center;
                        background-size: 24px;
                    }
                }
            }

            &.flex-list {
                li {
                    background: $white;
                    border-radius: 6px;
                    margin: 0 4% 5px 0;
                    padding: 3px;
                    width: 48%;

                    &:nth-of-type(2n+2) {
                        background: $white;
                        margin: 0 0 5px 0;
                    }

                    &:after {
                        display: none;
                    }

                    &.checked {
                        background: $gallery;
                    }
                }
            }
        }
    }

    .toggle-multiselect-checkbox,
    .question {
        padding: 10px;
    }
    
    .toggle-multiselect-checkbox {
        position: relative;
        width: 100%;
    }

    .question {
        display: none;
        
        p {
            margin: 0;
            position: relative;
            z-index: 4;
        }
    }
}

@include bp(sm) {
    .toggle-multiselect {
        .toggle-multiselect-checkbox {
            position: relative;
            width: 30%;
        }

        .toggle-content {
            width: 450px;
            
            ul {
                li {
                    position: relative;

                    &:after {
                        background: $gallery;
                        content: '';
                        display: block;
                        height: 44px;
                        position: absolute;
                        width: 100%;
                    }

                    .toggle-button {
                        right: 70%;
                    }

                    &:nth-of-type(2n+2) {
                        &:after {
                            background: transparent;
                        }
                    }
                }
            }
        }
    }

    .question {
        width: 70%;
    }
}


@include bp(md) {
    .toggle-multiselect {
        .toggle-content {
            width: 550px;
        }
    }
}

//multi-toggle full-width tabs
.multi-toggle-row {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.toggle-button {
    &.multi-toggle-full-width {
        align-content: center;
        align-items: center;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-flow: row wrap;
        
        font-size: 16px;
        font-weight: normal;
        height: 44px;
        padding: 10px 20px;
        width: 100%;

        label {
            margin: 0;
        }

        div {
            height: 16px;
            position: relative;
            width: 16px;

            &::before {
                left: 0;
                top: 0;
            }
        }

        &.open {
            div {
                &::before {
                    transform: rotate(90deg);
                }
            }
        }
    }
}

.toggle-content.multi-toggle-full-width {
    width: 100%;

    .toggle-button {
        border: 2px solid $chambray;
        border-radius: 4px;
        color: $biscay;
    }

    .toggle-content {
        padding: 10px 0;
    }

    .ui-accordion {
        h3 {
            &.ui-accordion-header {
                &.ui-accordion-header-active,
                &.ui-state-active,
                &.ui-state-default {
                    background: transparent;
                    border: 2px solid $chambray;
                    border-radius: 4px;
                    color: $biscay;
                    font-size: 16px;
                    font-weight: 400;
                    height: 44px;
                    line-height: 1.2;
                    padding: 10px 20px;
                    width: 100%;

                    &:before {
                        color: $silver3;
                        left: inherit;
                        right: 10px;
                        top: 12px;
                    }

                    &.inactive {
                        background: $ada-grey-5;
                        border: 2px solid $ada-grey-5;
                        color: $grey;

                        &::before {
                            content: "\e937";
                            display: block;
                        }

                        &:hover {
                            &::before {
                                content: "\edf5";
                            }
                        }
                    }
                }

                &.ui-accordion-header-active,
                &.ui-state-active {
                    &.inactive {
                        background: $ada-grey-5;
                        border: 2px solid $ada-grey-5;
                        color: $grey;

                        &::before {
                            content: "\edf5";
                        }
                    }
                }
            }
        }

        .ui-accordion-content {
            padding: 0 0 20px 0;
        }

    }

    .ui-tabs {
        ul.ui-tabs-nav {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: flex-start;
            //justify-content: space-between;

            margin: 0;
            width: 100%;

            li,
            li.ui-tabs-active {
                background: transparent;
                margin: 0 1% 0 0;
                padding: 0;
                width: 32.667%;

                a {
                    background: transparent;
                    border: 2px solid $chambray;
                    border-radius: 0;
                    color: $biscay;
                    font-size: 16px;
                    font-weight: 400;
                    height: 44px;
                    line-height: 1.2;
                    padding: 10px 20px;
                    position: relative;
                    width: 100%;

                    &:before {
                        font-family: 'icomoon' !important;
                        speak: none;
                        font-style: normal;
                        font-weight: normal;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1;

                        /* Better Font Rendering =========== */
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;

                        color: $silver3;
                        content: "\edf5";
                        font-size: 16px;
                        font-weight: 700;
                        left: inherit;
                        position: absolute;
                        right: 10px;
                        top: 12px;
                        transition: all .7s ease;
                    }

                    &.inactive {
                        background: $ada-grey-5;
                        border: 2px solid $ada-grey-5;
                        color: $grey;

                        &::before {
                            color: $silver3;
                            content: "\e937";
                            display: block;
                        }

                        &:hover {
                            &::before {
                                color: $silver3;
                                content: "\edf5";
                            }
                        }
                    }
                }

                &:first-of-type {
                    //margin: 0 1px 0 0;

                    a {
                        border-radius: 4px 0 0 4px;
                    }

                    &.ui-tabs-active {
                        a {
                            border-radius: 4px 0 0 0;
                        }
                    }
                }

                &:last-of-type {
                    margin: 0;

                    a {
                        border-radius: 0 4px 4px 0;
                    }

                    &.ui-tabs-active {
                        a {
                            border-radius: 0 4px 0 0;
                        }
                    }
                }
            }

            li.ui-tabs-active {
                a {
                    background: $chambray;
                    //border-bottom: 4px solid $chambray;
                    color: $white;
                    height: 49px;

                    &:before {
                        color: $white;
                        transform: rotate(90deg);
                    }

                    &.inactive {
                        background: $ada-grey-5;
                        border-bottom: 4px solid $ada-grey-5;
                        color: $grey;

                        &::before {
                            color: $silver3;
                            content: "\edf5";
                            display: block;
                        }
                    }
                }
            }
        }

        .ui-tabs-panel {
            border: 2px solid $chambray;
            border-radius: 0 0 6px 6px;
            margin: 0 0 20px 0;

            h3 {
                display: none;
            }

            ul.non-striped {
                display: flex;
                flex-direction: row;
                flex-flow: row wrap;
                align-items: flex-start;
                justify-content: flex-start;
                margin: 10px 0 0 0;

                li {
                    margin: 0 2% 4px 0;
                    width: 32%;

                    &:nth-of-type(3n+3),
                    &:last-of-type {
                        margin: 0 0 4px 0;
                    }
                }
            }
        }
    }
}

@include bp(sm) {
    .multi-toggle-row {
        margin: 0 0 40px 0;
    }

    .toggle-button.tab-expansion {
        cursor: not-allowed;
        margin-right: 5%;
		pointer-events: none;
        width: 20%;

        div {
            display: none;
        }
    }

    .toggle-content.tab-expansion {
        display: block !important;
        position: relative;
        width: 75%;

        .toggle-button {
            width: 33.333%;
        }

        .toggle-content {
            position: absolute;
            width: 100%;
        }

    }
}

//add-remove user toggle list
.add-remove-user-toggle {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    list-style: none;
    margin: 0 0 10px 0;

    li {
        margin: 0;
        text-align: center;
        width: 50%;

        a {
            border: 2px solid $chambray;
            border-radius: 6px 0 0 6px;
            color: $chambray;
            display: block;
            font-size: 18px;
            font-weight: 600;
            height: 40px;
            padding: 3px 0 5px 0;
            width: 100%;

            &.active {
                background: $chambray;
                color: $white;
            }
        }

        &:last-of-type {
            a {
                border-radius: 0 6px 6px 0;
            }
        }
    }
}

//read more continer
.read-more-container {
    height: 94px;
    overflow: hidden;
    position: relative;
    transition: all $base-duration $base-timing;

    &.open {
        height: auto;
        overflow: visible;

        .read-more-button {
            span {            
                &.read-more {
                    display: none;
                }

                &.show-less {
                    display: inline;
                }
            }
        }
    }

    .read-more-button {
        background: $white;
        bottom: 0;
        cursor: pointer;
        height: 22px;
        margin: 0;
        padding-left: 5px;
        position: absolute;
        right: 0;

        span {
            color: $link-green;
            
            &.read-more {
                display: inline;
            }

            &.show-less {
                display: none;
            }
        }
    }
}

@include bp(md) {
    .read-more-container {
        height: auto;

        .read-more-button {
           display: none;
        }
    }
}
