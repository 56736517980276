$icon-size-map: (
    "sm": 20px,
    "md": 24px,
    "lg": 28px,
    "xl": 33px,
);

// icon sizes
@each $name, $value in $icon-size-map {
  .u-icon-size--#{$name} {
      height: $value;
      font-size: $value;
      width: $value;
      left: 2px;
      position: relative;
      top: calc($value / 4);

      &:before {
        width: $value;
    }
  }
}
.u-icon-size--xl{
  top: 0 !important;
}
