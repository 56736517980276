//max-height mixin
@mixin maxheight-x {
    @for $i from 1 through 500 {
        .max-height-#{$i} { 
            max-height: #{$i}#{'px'} !important; 
        }
    }
}


// Overflow Helpers
//-------------------------------------------------------------------------- */
@include maxheight-x;

.u-overflow--hidden {
    overflow: hidden !important;
}

.u-overflow--x {
    //overflow-x: scroll !important;
    overflow-x: auto !important;
}

.u-overflow--y {
    //overflow-y: scroll !important;
    overflow-y: auto !important;
}


@each $key, $breakpoint in $breakpoints {

    .#{key}-u-overflow--hidden {
        @include bp($breakpoint) {
            overflow: hidden !important;
        }
    }
    
    .#{key}-u-overflow--x {
        @include bp($breakpoint) {
            overflow-x: scroll !important;
        }
    }
    
    .#{key}-u-overflow--y {
        @include bp($breakpoint) {
            overflow-y: scroll !important;
        }
    }   

}