// Border map for functions
$border-style-map: (
    "dashed":dashed, 
    "dotted":dotted, 
    "double":double, 
    "groove":groove,
    "hidden":hidden,
    "inset":inset, 
    "outset":outset, 
    "none":none,
    "ridge":ridge, 
    "solid":solid
);
