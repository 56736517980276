#top-bar {
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: white;
    height: $top-bar-height-mobile;
    width: 100%;

    .full-logo {
        display: none;
    }

    &:after {
        background: $concrete;
        bottom: -2px;
        content: "";
        display: block;
        height: 30px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 2;
    }
}

.logo-container {
    figure {
        text-align: center;

        img {
            margin: 0 auto;
        }
    }

    figcaption h1 {
        border: 0 !important;
        clip: rect(1px, 1px, 1px, 1px) !important;
        -webkit-clip-path: inset(50%) !important;
                clip-path: inset(50%) !important;
        height: 1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
        white-space: nowrap !important;
    }

    section {
        display: none;
    }
}

.c-main-nav__header-item {
    line-height: $top-bar-height-mobile;
    position: relative;
    text-align: center;

    a {
        position: relative;
        z-index: 2;
        
        &:before {
            font-size: 24px;
            left: 50%;
            margin: -12px 0 0 -12px;
            position: absolute;
            top: 50%;
        }
    }
    
    &:after {
        background: $hub;
        content: "";
        display: block;
        height: 30px;
        margin: -15px 0 0 0;
        position: absolute;
        right: 0;
        top: 50%;
        width: 1px;
    }

    &.logo-container {
        border: none;
        margin: bsu(0.25) 0;
        padding: 0 bsu(0.5);

        &:after {
            display: none;
        }
    }

    &.company-name,
    &.alerts,
    &.profile,
    &.settings {
        position: relative;
        
        a {
            &:hover {
                color: $hub;
            }
        }
    }

    &.company-name {
        font-size: 16px;
        font-weight: 500;
        overflow: hidden;
        padding: 0 bsu(0.5);
        text-align: right;
        text-transform: uppercase;
        width: 55%;

        a {
            font-size: 16px;
        }
    }

    &.alerts,
    &.profile,
    &.settings {
        padding: 0 bsu(0.5);
        width: 34px;
    }

    &.alerts {
        position: relative;

        &.new-notifications {
            &:before {
                content: "";
                background: $red-orange;
                border-radius: 50%;
                display: block;
                height: 14px;
                left: 45%;
                margin: -7px 0 0 -7px;
                position: absolute;
                top: 40%;
                width: 14px;
            }
        }
    }

    &.settings {
        display: none;
    }

    &:last-child {
        &:after {
            display: none;
        }
    }
}

.c-main-nav.ecommerce {
    .c-main-nav__header-item {
        &.shopping-cart {
            position: relative;
            
            a {
                &:hover {
                    color: $hub;
                }
            }
        }

        &.shopping-cart {
            margin-left: 44%;
            width: 16%;
        }

        &.mobile-utility-nav {
            padding: 0;
            width: 14%;
        }

        &.mobile-utility-nav {
            &:after {
                display: none;
            }

            ul.c-utility-nav__menu {
                &:before {
                    right: 5px;
                }
            }
        
        }

        &.desk {
            display: none;
        }
    }
}

@include bp(sm) {
    #top-bar {
        height: $top-bar-height;

        .small-logo {
            display: none;
        }
        
        .full-logo {
            display: block;
            padding-top: bsu(0.35);
        }
    }

    .logo-container {
        figure {
            width: 200px;
        }
    }

    .c-main-nav__header-item {
        line-height: $top-bar-height;

        &.logo-container {
            margin: bsu(0.25) 0;
            padding: 0 0 0 3%;
            width: 27%;
        }

        &.company-name {
            padding: 0 2% 0 0;
            width: 48%;
        }

        &.alerts,
        &.profile,
        &.settings {
            width: 7%;
        }

        &.settings {
            display: block;
        }
    }

    .c-main-nav.ecommerce {
        .c-main-nav__header-item {
            &.shopping-cart {
                margin-left: 50%;
                width: 14%;
            }
    
            &.mobile-utility-nav {
                padding: 0;
                width: 12%;
            }
    
            &.mobile-utility-nav {    
                ul.c-utility-nav__menu {
                    &:before {
                        right: 9px;
                    }
                }
            
            }
        }
    }
}

@include bp(md) {
    .c-main-nav__header-item {
        &.logo-container {
            display: flex;
            line-height: $base-line-height;
            padding: 0 0 0 1%;
            width: 65%;

            section {
                display: flex;
                flex-direction: row;
                flex-flow: row wrap;
                align-items: center;
                border-left: 1px solid $hub;
                margin: bsu(0.5) 0 bsu(0.5) bsu(1.5);
                padding: 0 0 0 bsu(1.5);

                position: relative;

                svg {
                    color: $hub;
                    fill: currentColor;
                    left: 0;
                    margin-right: bsu(0.75);
                    width: 2rem;
                }
            }
        }

        &.company-name {
            padding: 0 1% 0 1%;
            width: 36%;
        }

        &.alerts,
        &.profile,
        &.settings {
            width: 5%;
        }

        &.settings {
            display: block;
        }
    }

    .c-main-nav.ecommerce {
        .c-main-nav__header-item {
            &.desk {
                display: block;
            }

            &.company-name {
                width: 27%;
            }

            &.shopping-cart {
                margin: 0;
            }

            &.shopping-cart,
            &.alerts,
            &.profile,
            &.settings {
                width: 6%;
            }

            &.mobile-utility-nav {
                display: none;
            }
        }
    }
}

@include bp(lg) {
    .c-main-nav.ecommerce {
        .c-main-nav__header-item {
            &.company-name {
                width: 35%;
            }

            &.shopping-cart,
            &.alerts,
            &.profile,
            &.settings {
                width: 4%;
            }
        }
    }
}

@include bp(xlg) {
    .c-main-nav.ecommerce {
        .c-main-nav__header-item {
            &.company-name {
                width: 39%;
            }

            &.shopping-cart,
            &.alerts,
            &.profile,
            &.settings {
                width: 3%;
            }
        }
    }
}

//new header
.top-bar {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    background-color: $white;
    border-bottom: 2px solid $concrete;
    height: 57px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1001;

    .full-logo {
        display: none;
        height: auto;
        width: 195px;
    }

    .company-name {
        border-right: 1px solid $marigold;
        display: none;
        font-size: 17px;
        font-weight: 500;
        margin-right: bsu(1.5);
        padding: bsu(0.25) bsu(1.5) bsu(0.25) 0;
    }

    a {
        color: $ship-gray;

        &:hover {
            color: $marigold;
        }
    }

    .section-manage {
        display: none;
        margin-left: 10px;

        a.btn {
            color: $white;
            font-size: 12px;
            padding: 5px;
            text-transform: uppercase;
        }
    }
}

.main-nav,
.utility-nav {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
}

.u-icon-menu {
    background: $hub;
    cursor: pointer;
    display: block;
    height: 55px;
    position: relative;
    width: 55px;

    &::before {
        color: $white;
        font-size: 28px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.nav-container {
    position: relative;
    user-select: none;
    background-color: $hub;
    color: $white;
}

.logo-wrapper {
    padding: 0 10px 0 10px;
    
    h1 {
        border: 0 !important;
        clip: rect(1px, 1px, 1px, 1px) !important;
        -webkit-clip-path: inset(50%) !important;
        clip-path: inset(50%) !important;
        height: 1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
        white-space: nowrap !important;
    }
}

.section-wrapper {
    border-left: 1px solid $marigold;
    padding-left: 10px;

    a {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;

        padding: 5px 0;
    }

    h2 {
        font-size: 16px;
        margin: 0;
        padding: 0;

        a {
            color: $ship-gray;

            &:hover {
                color: $marigold;
            }
        }
    }

    span {
        display: none;
        height: 30px;
        width: 35px;
    }

    svg {
        height: auto;
        max-height: 30px;
        max-width: 30px;
        width: auto;
    }
}

section.utility-nav {
    margin-right: 20px;

    .notifications,
    .profile {
        position: relative;
    }

    .notifications {
        padding: 15px 20px 15px 0;

        ul {
            &:before {
                border-bottom: 12px solid $pistachio;
            }
        }
    }

    .profile {
        margin-top: 1px;
        padding: 15px 0;
    }

    a.u-icon-notifications,
    a.u-icon-account_circle {
        display: block;
        position: relative;
        z-index: 2;
    }
        
    a.u-icon-notifications {
        background: $dark-pistachio;
        border-radius: 100%;
        color: $white;
        font-size: 20px;
        padding: 0 0 0 2px;
        height: 24px;
        width: 24px;

        &:before {
            color: $white;
            fill: $white;
        }
    }

    a.u-icon-account_circle {
        color: $dark-pistachio;
        height: 24px;
        width: 24px;

        &:before {
            color: $dark-pistachio;
            font-size: 28px;
            left: -2px;
            position: absolute;
            top: -2px;
        }
    }

    ul {
        background: $hub-lightest;
        display: none;
        list-style: none;
        margin: 0;
        position: absolute;
        right: 0;
        top: 46px;
        width: 200px;
        z-index: 1;

        li {
            border-bottom: 1px solid $white;
            margin: 0;

            a {
                color: $ship-gray;
                display: block;
                padding: bsu(0.5) bsu(1);

                &:hover {
                    background: $marigold;
                    color: $white;
                }
            }

            &:first-of-type {
                border-bottom: none;

                a {
                    background: $pistachio;
                    color: $white;
                    font-weight: 600;
                }
            }

            &:last-of-type {
                border-bottom: none;
            }

            &.username {
                background: $pistachio;
                border-bottom: none;
                padding: bsu(0.5) bsu(1);

                h4,
                p {
                    color: $white;
                    margin: 0;
                    padding: 0;
                }

                h4 {
                    border-bottom: 1px solid rgba($white,0.25);
                    margin: 0 0 bsu(0.5) 0;
                    padding: 0 0 bsu(0.5) 0;
                }
            }
        }
    }
}

@include bp(sm) {
    .top-bar {
        .small-logo {
            display: none;
        }

        .full-logo {
            display: block;
        }
    
        .company-name {
            display: block;
        }
    }

    .section-wrapper {
        h2 {
            font-size: 20px;
        }
        
        span {
            display: block;
        }
    }
}

@include bp(md) {
    .top-bar {
        .section-manage {
            display: block;
        }
    }

    .logo-wrapper {
        padding: 0 20px 0 10px;
    }

    .section-wrapper {
        padding-left: 20px;
    }

    section.utility-nav {
        .profile {    
            li.manage {
                display: none;
            }
        }
    }
}

//new-header
.new-header {
    position: fixed;
    top: 0;
    z-index: 100;

    align-content: flex-start;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-flow: row wrap;
    
    height: 60px;
    width: 100%;

    .u-icon-menu {
        background: transparent;
        height: 60px;
        width: 60px;
    }

    &.header-quality-control {
        .u-icon-menu {
            background: transparent;
        }
    }

    .c-main-nav__wrapper {
        top: 60px;
    }

    .nav-logo {
        align-content: flex-start;
        align-items: center;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-flow: row wrap;

        .logo-label-container {
            align-content: flex-start;
            align-items: center;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            flex-flow: row wrap;
        }

        svg {
            margin-left: 10px;
            width: 120px;

            &.hub-logo {
                margin: 0;
            }

            &.desk {
                display: none;
            }

            &.mobile {
                width: 36px;
            }
        }

        .dashboard-label {
            color: $white;
            display: none;
            font-size: 10px;
            margin-left: 10px;
            width: auto;
        }

        .commodity-toggle {
            display: none;
        }
    }

    &.header-marketwatch {
        background: $chambray;

        nav.app-nav {
            .c-main-nav__scroller {
                background: $chambray;
            }
        }
    }

    &.header-marketwatch-lite {
        background-image: linear-gradient(rgba(40,61,142,1.0), rgba(15,26,65,1.0));

        nav.app-nav {
            .c-main-nav__scroller {
                background: $biscay;
            }
        }
    }

    &.header-quality-control {
        background: $cutty-shark-dark;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

        nav.app-nav {
            .c-main-nav__scroller {
                background: $palm-green;
            }
        }
    }
}

@include bp(lg) {
    .new-header .nav-logo {
        justify-content: flex-start;
        width: 50%;
    }
}

body.new-design {
    ul.c-utility-nav__menu {
        top: 98%;

        &::before {
            right: 13px;
        }

        li {
            a {
                color: $emperor;
                text-decoration: underline;

                &:after {
                    display: none;
                }

                &:hover {
                    color: $mine-shaft;
                }
            }
        }
    }
}

@include bp(sm) {
    .new-header {
        .nav-logo {
            .logo-label-container { 
                width: 450px;
            }

            svg {
                margin-left: 35px;
                width: 150px;

                &.hub-logo {
                    width: 215px;
                }

                &.desk {
                    display: block;
                }
    
                &.mobile {
                    display: none;
                }
            }

            .dashboard-label {
                display: block;
            }

            .dashboard-label,
            .commodity-toggle {
                color: $white;
                font-size: 16px;
                margin-left: 10px;
                padding: 0 0 0 10px;
                position: relative;
                width: auto;
            }

            .commodity-toggle {
                cursor: pointer;
                padding: 20px 0 20px 10px;

                &:before {
                    background: $white;
                    content: '';
                    display: block;
                    height: 20px;
                    left: 0;
                    margin-top: -10px;
                    position: absolute;
                    top: 50%;
                    width: 1px;
                }

                span {
                    &:before {
                        color: $white;
                        padding: 0 0 0 5px;
                    }
                }
            }
        }

        &.header-quality-control,
        &.header-marketwatch {
            .nav-logo {
                .dashboard-label {
                    display: block;
                    margin-left: 20px; 
                    padding: 20px 0 20px 20px;
    
                    &:before {
                        background: $white;
                        content: '';
                        display: block;
                        height: 38px;
                        left: 0;
                        margin-top: -19px;
                        position: absolute;
                        top: 50%;
                        width: 1px;
                    }
                }
            }
        }
    }
}

@include bp(md) {
    .new-header {
        .nav-logo {
            .logo-label-container { 
                width: 510px;
            }

            .commodity-toggle {
                ul {
                    li {
                        a {
                            font-size: 18px;
                        }
                    }
                }
            }
            
            .commodity-toggle {
                display: block;
            }
            
        }
    }
}

//new nav              
nav.app-nav {
    .c-main-nav__menu {
        list-style: none;
        margin: 0;

        li {
            margin: 0;
            width: 100%;

            &.feedback,
            &.faqs,
            &.logout {
                a {
                    padding-left: 30px;
                }
            }
                
            &.feedback,
            &.logout {
                margin-top: 10px;
                position: relative;

                &:before {
                    background: $white;
                    content: '';
                    display: block;
                    height: 1px;
                    left: 15px;
                    opacity: 0.4;
                    position: absolute;
                    top: 0;
                    width: 85%;
                }
            }

            section, 
            a {
                align-content: flex-start;
                align-items: center;
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
                flex-flow: row wrap;
                
                color: $white;
                cursor: pointer;
                font-size: 16px;
                padding: 15px;
                position: relative;
                transition: all $base-duration $base-timing;
                width: 100%;
                z-index: 1;

                &:hover,
                &.active {
                    background: $biscay;

                    &:before {
                        display: block;
                    }
                }

                &:before {
                    background: $chambray;
                    content: '';
                    display: none;
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    transition: all $base-duration $base-timing;
                    width: 3px;
                    z-index: 2;
                }

                span {
                    margin-right: 13px;

                    &::before {
                        color: $white;
                    }
                }
                
                svg {
                    fill: $white;
                    height: 20px;
                    margin: 0 10px 0 0;
                    width: auto;
                }
            }

            &.marketwatch-lite {
                section,
                a {
                    svg {
                        height: auto;
                        width: 150px;
                    }
                }
            }
            
            &.shipping-point,
            &.terminal {
                section,
                a {
                    svg {
                        height: auto;
                        margin: 0 10px 0 5px;
                        width: 15px;
                    }
                }
            }

            &.movement {
                section,
                a {
                    svg {
                        height: auto;
                        margin: 0 5px 0 0;
                        width: 25px;
                    }
                }
            }

            &.hassubmenu-links {
                margin-bottom: 30px;

                section,
                a {
                    padding: 15px 0 0 0;

                    &:hover,
                    &.active {
                        background: $chambray;
                    }

                    svg {
                        margin-left: 15px;
                    }

                    ul {
                        list-style: none;
                        margin: 5px 0 0 0;
                        width: 100%;

                        li {
                            section {
                                padding: 5px 0 5px 45px;

                                &:hover {
                                    background: $biscay;
                                }
                            }
                        }
                    }
                }
            }

            a {
                padding: 20px 15px;

                &:hover {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

@include bp(md) {
    nav.app-nav {
        .c-main-nav__menu {
            li {
                section, 
                a {
                    font-size: 18px;
                }
            }
        }
    }
}

.header-marketwatch-lite {
    nav.app-nav {
        .c-main-nav__menu {
            li {
                &.feedback,
                &.faqs,
                &.logout {
                    a {
                        padding-left: 45px;
                    }
                }
            }
        }
    }
}

.header-quality-control {
    nav.app-nav {
        .c-main-nav__menu {
            li {
                section, 
                a {
                    &:hover,
                    &.active {
                        background: $dark-fern;
                    }
    
                    &:before {
                        background: $key-lime-pie;
                    }
                }
            }
        }
    }
}

//utility nav
.header-utility-nav-container {
    align-content: flex-start;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-flow: row wrap;

    a {
        color: $white;

        &:before {
            color: $white;
            font-size: 25px;
        }

        &:hover {
            color: $white;

            &:before {
                color: $white;
            }
        }
    }

    section {
        margin: 0 5px 0 0;

        &.alerts,
        &.profile,
        &.settings {
            padding: 0;
            position: relative;
            z-index: 2;
            
            a {
                align-content: center;
                align-items: center;
                display: flex;
                justify-content: center;

                height: 60px;
                padding: 0 10px;
                position: relative;
                z-index: 3;

                &:hover {
                    color: $white;
                }
            }
        }

        &.alerts {
            position: relative;

            &.new-notifications {
                &:before {
                    content: "";
                    background: $red-orange;
                    border-radius: 50%;
                    display: block;
                    height: 14px;
                    left: 12px;
                    position: absolute;
                    top: 17px;
                    width: 14px;
                }
            }
        }

        &.profile,
        &.settings {
            a {
                &:before {
                    font-size: 19px;
                    left: 0;
                    position: relative;
                    top: -2px;
                    z-index: 5;
                }

                &:after {
                    content: "";
                    background: $blue-zodiac;
                    border-radius: 50%;
                    display: block;
                    height: 30px;
                    margin-top: -15px;
                    top: 50%;
                    left: 4px;
                    position: absolute;
                    width: 30px;
                    z-index: 4;
                }
            }
        }

        &.profile {
            a {
                &:before {
                    font-size: 18px;
                }
            }
        }
    }
}

.new-header.header-quality-control,
.new-header.header-marketwatch {
    height: 80px;

    .c-main-nav__wrapper {
        top: 80px;
    }

    .header-utility-nav-container {
        height: 80px;

        section {
            &.alerts,
            &.profile,
            &.settings {
                .utility-sub-nav {
                    top: 75px;

                    a {
                        height: auto;
                    }
                }

                a {
                    height: 80px;

                    &:before {
                        speak: none;
                        font-style: normal;
                        font-weight: normal;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1;

                        /* Better Font Rendering =========== */
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;

                        top: 0;
                    }
                }
            }

            &.alerts {
                position: relative;
    
                &.new-notifications {
                    &:before {
                        display: none;
                    }

                    a {
                        position: relative;

                        svg {
                            width: 20px;
                        }

                        &:before {
                            display: none;
                        }

                        &:after {
                            display: none;
                        }
                    }

                    .utility-sub-nav {
                        a {
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
    
            &.profile,
            &.settings {
                a {
                    &:before {
                        font-size: 26px;
                    }

                    &:after {
                        display: none;
                    }
                }
            }

            &.profile {
                a {
                    &:before {
                        content: '\eb32';
                        display: none;
                    }

                    svg {
                        width: 18px;
                    }
                }
            }

            &.settings {
                a {
                    &:before {
                        display: none;
                    }

                    svg {
                        width: 20px;
                    }
                }
            }
        }
    }
}

@include bp(sm) {
    .header-utility-nav-container {
        margin-left: 0%;

        section {
            &:last-of-type {
                margin: 0;
            }

            &.alerts,
            &.profile,
            &.settings {
                a {
                    margin: 0 10px;
                }
            }

            &.alerts {    
                &.new-notifications {
                    &:before {
                        left: 22px;
                    }

                    a {
                        position: relative;

                        svg {
                            width: 25px;
                        }
                    }
                }
            }

            &.profile {
                a {
                    svg {
                        width: 25px;
                    }
                }
            }

            &.settings {
                a {
                    svg {
                        width: 25px;
                    }
                }
            }
        }
    }
}


@include bp(md) {
    .header-utility-nav-container {
        margin-left: 10%;
    }
}

@include bp(lg) {
    .header-utility-nav-container {
        margin: 0;
        justify-content: flex-end;
        width: 45%;
    }
}

//waffle nav
.new-header {
    .header-waffle-menu-container {
        display: none;
        margin-right: 10px;
        position: relative;

        a {
            color: $white;
            display: block;

            svg {
                color: $white;
                fill: $white;
                margin-top: 5px;
                width: 25px;
            }

            &:before {
                font-size: 25px;
            }
        }

        .c-main-nav__scroller,
        .c-main-nav__menu {
            width: 270px;
        }

        .c-main-nav__wrapper {
            left: inherit;
            right: -270px;
            transform: none;
            width: 270px;

            &.c-main-nav--open-all {
                right: 0;
                width: 270px;

                .c-main-nav__scroller {
                    width: 290px;
                }
            }
        }

        nav.app-nav {
            .c-main-nav__menu {
                li {
                    a svg,
                    section svg {
                        height: auto;
                        width: 30px
                    }

                    &.marketwatch {
                        section,
                        a {
                            svg {
                                width: 135px;
                            }
                        }
                    }

                    &.marketwatch-lite {
                        section,
                        a {
                            svg {
                                width: 165px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include bp(sm) {
    .new-header {
        .header-waffle-menu-container {
            display: block;
        }
    }
}

@include bp(lg) {
    .new-header {
        .header-waffle-menu-container {
            margin-right: 0;
            text-align: center;
            width: 5%;
        }
    }
}

//utility-sub-nav
.utility-sub-nav {
    background: $emperor;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.13);
    display: none;
    left: 0;
    list-style: none;
    margin: 0;
    min-width: 180px;
    padding: 15px 0;
    position: absolute;
    top: 55px;
    z-index: 1;

    li {
        margin: 0;
    }

    a {
        color: $white !important;
        display: block;
        padding: 5px 20px;

        &:hover,
        &.active {
            background: $grey;
            color: $white;
        }
    }
}

.header-utility-nav-container {
    height: 60px;

    section {
        &.settings,
        &.profile,
        &.new-notifications {
            .utility-sub-nav {
                left: inherit;
                right: 0;
                top: 55px;

                a {
                    color: $white;
                    display: block;
                    height: auto;
                    font-size: 16px;
                    padding: 5px 20px;

                    &:after {
                        display: none;
                    }

                    h4,
                    p {
                        color: $white;
                        font-size: 16px;
                        margin: 0;
                    }

                    h4 {
                        font-weight: bold;
                    }
                }
            }
        }

        &.new-notifications {
            .utility-sub-nav {
                min-width: 280px;
            }
        }
    }
}

@include bp(md) {
    .header-utility-nav-container {    
        section {
            &.settings,
            &.profile,
            &.new-notifications {
                .utility-sub-nav {
                    a {
                        font-size: 18px;

                        h4,
                        p {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}