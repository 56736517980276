///////////////////////////////////////////////
// ICOMOON
// =========================

@font-face {
    font-family: 'icomoon';
    src: url('../../Content/fonts/icomoon.ttf') format('truetype'), url('../../Content/fonts/icomoon.woff') format('woff'), url('../../Content/fonts/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="u-icon-"],
[class*=" u-icon-"],
.k-i-expand,
.k-i-collapse {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    fill: $grey !important;

    &:before {
        color: $grey;
        fill: $grey !important;
        transition: all $base-duration $base-timing;
    }

    &:hover {
        &:before {
            color: $mine-shaft;
            fill: $mine-shaft !important;
        }
    }
}

.actions {
    a [class^="u-icon-"],
    a [class*=" u-icon-"] {
        fill: $white !important;

        &:before {
            color: $white;
            fill: $white !important;
        }

        &:hover {
            &:before {
                color: $white;
                fill: $white !important;
            }
        }
    }
}

.u-icon-arrow-left:before {
    content: "\edfc";
}

.u-icon-arrow-right:before {
    content: "\edf4";
}

.u-icon-arrow-left2:before {
    content: "\edfd";
}

.u-icon-arrow-right2:before {
    content: "\edf5";
}

.u-icon-arrow-up {
    &:before {
        content: '\edf0';
    }
}

.u-icon-arrow-up2 {
    &:before {
        content: '\edf1';
    }
}

.u-icon-arrow-down2 {
    &:before {
        content: '\edf9';
    }
}

.u-icon-arrow-right-bold {
    &:before {
        content: '\edf4';
    }
}

.u-icon-arrow-up5 {
    &:before {
        content: '\ee00';
    }
}

.u-icon-arrow-down5 {
    &:before {
        content: '\ee02';
    }
}

.u-icon-account_circle:before {
    content: "\e853";
}

.u-icon-user3:before {
    content: "\eb2f";
}

.u-icon-add:before {
    content: "\e145";
}

.u-icon-attach_file:before {
    content: "\e226";
}

.u-icon-build:before {
    content: "\e869";
}

.u-icon-photo_camera:before {
    content: "\e412";
}

.u-icon-check_box:before {
    content: "\e834";
}

.u-icon-circle-small:before {
    content: "\eca4";
}

.u-icon-circle2:before {
    content: "\eeb1";
}

.u-icon-close:before {
    content: "\e5cd";
}

.u-icon-mode_edit:before {
    content: "\e254";
}

.u-icon-dashboard:before {
    content: "\e871";
}

.u-icon-description:before {
    content: "\e873";
}

.u-icon-insert_invitation:before {
    content: "\e24f";
}

.u-icon-expand_less:before {
    content: "\e5ce";
}

.u-icon-expand_more:before {
    content: "\e5cf";
}

.u-icon-filter_list:before {
    content: "\e152";
}

.u-icon-help:before {
    content: "\e887";
}

.u-icon-info:before {
    content: "\e88e";
}

.u-icon-info-new {
    cursor: pointer;
    height: 16px;
    display: block;
    font-size: 14px;
    width: 16px;

    &:before {
        background: url("../../Content/img/icons/icon-info.svg") no-repeat center center;
        content: "";
        display: block;
        height: 16px;
        text-align: center;
        top: 0;
        width: 16px;
    }
}

.u-icon-input:before {
    content: "\e890";
}

.u-icon-keyboard_arrow_down:before {
    content: "\e313";
}

.u-icon-keyboard_arrow_left:before {
    content: "\e314";
}

.u-icon-keyboard_arrow_right:before {
    content: "\e315";
}

.u-icon-keyboard_arrow_up:before {
    content: "\e316";
}

.u-icon-arrow-down8:before {
    content: "\ee19";
}

.u-icon-location_off:before {
    content: "\e0c7";
}

.u-icon-room:before {
    content: "\e8b4";
}

.u-icon-menu:before {
    content: "\ecaa";
}

.u-icon-utility-nav-mobile:before {
    content: "\edfa";
}

.u-icon-notifications:before {
    content: "\e7f4";
}

.u-icon-notifications_active:before {
    content: "\e7f7";
}

.u-icon-photo_album:before {
    content: "\e411";
}

.u-icon-picture_as_pdf:before {
    content: "\e415";
}

.u-icon-download:before {
    content: "\ecbe";
}

.u-icon-priority_high:before {
    content: "\e645";
}

.u-icon-visibility:before {
    content: "\e8f4";
}

.u-icon-warning:before {
    content: "\e002";
    font-size: 22px;
}

.u-icon-send:before {
    content: "\e163";
}

.u-icon-settings:before {
    content: "\e8b8";
}

.u-icon-shuffle:before {
    content: "\e043";
}

.u-icon-timeline:before {
    content: "\e922";
}

.u-icon-unfold_more:before {
    content: "\e5d7";
}

.u-icon-view_list:before {
    content: "\e8ef";
}

.u-icon-view_module:before {
    content: "\e8f0";
}

.u-icon-write:before,
.u-icon-edit:before {
    content: "\e938";
    font-size: 16px;
    position: relative;
    top: 1px;
}

// .u-icon-delete:before {
//     content: "\e92b";
// }

.u-icon-history:before {
    content: "\ea73";
    font-size: 16px;
    font-weight: bold;
}

.u-icon-copy:before {
    content: "\e9ee";
}
.u-icon-paste:before {
    content: "\e9f2";
}

.u-icon-question:before {
    content: "\e887";
}

.u-icon-checkmark:before {
    content: "\edab";
}

.u-icon-checkmark-bold {
    &:before {
        content: '\eda8';
    }
}

.u-icon-checkmark-bold {
    &:before {
        content: '\eda8';
    }
}

.u-icon-checkmark3 {
    &:before {
        content: '\edaa';
    }
}

.u-icon-none:before {
    content: "\e53c";
}

.u-icon-time:before {
    content: "\ea74";
}

.u-icon-refresh:before {
    content: "\e5d5";
}

.u-icon-view:before {
    content: "\e8a0";
}

.u-icon-view-figure:before {
    content: "\e56e";
}

.u-icon-calendar:before {
    content: "\e916";
}

.u-icon-leaf:before {
    content: "\ec06";
}

.u-icon-map:before {
    content: "\e55b";
}

.u-icon-quill:before {
    content: "\e940";
}

.u-icon-cog:before {
    content: "\eb9c";
}

.u-icon-approve:before {
    content: "\e065";
}

.u-icon-cancel:before {
    content: "\e5c9";
}

.u-icon-setup:before {
    content: "\eb98";
}

.u-icon-grid:before {
    content: "\ec9b";
}

.u-icon-list:before {
    content: "\e241";
}

.u-icon-pencil4 {
    &:before {
        content: "\e938";
        font-size: 16px;
        position: relative;
    }
}

.u-icon-pending-approval {
    background: url('../../Content/img/icons/icon_request.svg') no-repeat center center;
    background-size: 20px;
    display: inline-block;
    height: 20px;
    transition: all $base-duration $base-timing;
    width: 20px;

    &:before {
        display: none;
    }

    &:hover {
        background: url('../../Content/img/icons/icon_request-hover.svg') no-repeat center center;
        background-size: 20px;
    }
}

.u-icon-pen6 {
    &:before {
        content: "\e94e";
        font-size: 14px;
        position: relative;
    }
}

.u-icon-play {
    &:before {
        content: "\e97e";
        font-size: 17px;
        position: relative;
    }
}

.u-icon-save:before {
    content: "\e161";
    font-size: 18px;
}

.u-icon-delete {
    &:before {
        content: "\e872";
        font-size: 18px;
        position: relative;
    }
}

.u-icon-accordion {
    color: $ada-grey-2;

    &:before {
        content: "\edf7";
        font-size: 16px;
        font-weight: bold;
        position: relative;
        top: 1px;
    }
}

.u-icon-accordion2 {
    color: $ada-grey-2;

    &:before {
        content: "\ee02";
        font-size: 22px;
        font-weight: bold;
        margin: -11px 0 0 0;
        position: relative;
        top: 50%;
    }
}


.u-icon-reset {
    &:before {
        content: "\ea72";
        font-size: 16px;
        left: 5px !important;
        top: 3px !important;
    }
}

.u-icon-unkink {
    &:before {
        content: "\e88b";
        font-size: 16px;
        left: 5px !important;
        top: 4px !important;
    }
}

.u-icon-check {
    &:before {
        background: $hub;
        border-radius: 100%;
        color: $white;
        content: "\edab";
        font-family: 'icomoon' !important;
        font-size: 12px;
        height: 20px;
        left: 0;
        line-height: 1;
        padding: 4px;
        position: absolute;
        top: 0;
        width: 20px;
    }
}

.u-icon-star {
    &:before {
        content: '\ed1b';
    }
}

.u-icon-reorder {
    cursor: pointer;
    
    &:before {
        content: '\e8fe';
        font-size: 22px;
    }
}

.u-icon-view {
    &:before {
        content: '\e8a0';
        font-size: 19px;
        position: relative;
        top: 1px;
    }
}

.u-icon-heatmap {
    &:before {
        color: $almost-black;
        content: "\ea5f";
        position: relative;
        top: 3px;
    }
}

.u-icon-lab {
    &:before {
        content: "\ec23";
    }
}

.u-icon-camera {
    &:before {
        content: "\e412";
    }
}

.u-icon-graph {
    &:before {
        content: "\EBC3";
    }
}

.u-icon-print {
    &:before {
        content: "\e8ad";
        font-size: 18px;
    }

    &.big {
        &:before {
            color: $grey;
            font-size: 30px;
        }
    }
}

.u-icon-draw {
    &:before {
        content: "\e939";
    }
}

.u-icon-split {
    &:before {
        content: "\eee5";
    }
}

.u-icon-phone-outline {
    &:before {
        content: "\ea4d";
    }
}

.u-icon-wifi {
    &:before {
        content: "\e63e";
    }
}
.u-icon-envelope {
    &:before {
        content: "\ea59";
    }
}

.u-icon-envelope-dark {
    &:before {
        content: "\ea5b";
    }
}

.u-icon-link {
    &:before {
        content: "\ecd2";
    }
}

.u-icon-upload {
    &:before {
        content: "\ecb8";
    }
}

.u-icon-publish {
    &:before {
        content: "\e94d";
    }
}

.u-icon-unpublish {
    &:before {
        content: "\e042";
    }
}

.u-icon-file-button {
    &:before {
        content: "\e24d";
    }
}

.u-icon-share {
    &:before {
        content: "\ef01";
    }
}

.u-icon-search {
    &:before {
        content: "\e8b6";
        font-size: 20px;
        font-weight: 600;
        left: 0;
        position: relative;
        top: 2px;
    }
}

.u-icon-plus {
    &:before {
        content: '\ed96';
        font-weight: bold;
    }
}

//action icons
.u-icon-lock4 {
    &:before {
        content: "\eb8c";
    }
}

.u-icon-unlocked {
    &:before {
        content: "\eb8d";
    }
}
.u-icon-archive {
    &:before {
        content: "\eaab";
        font-size: 16px;
        position: relative;
        top: 1px;
    }
}

.u-icon-archive2 {
    &:before {
        content: "\e149";
        font-size: 16px;
        position: relative;
        top: 1px;
    }
}

.u-icon-unarchive2 {
    &:before {
        content: "\e169";
        font-size: 16px;
        position: relative;
        top: 1px;
    }
}

.u-icon-diagnostic {
    &:before {
        content: '\e869';
    }
}

.u-icon-report {
    &:before {
        content: '\e160';
    }
}

//cart icons
.u-icon-add-to-cart {
    &:before {
        content: "\ea2a";
    }
}

.u-icon-cart {
    &:before {
        content: "\ea25";
    }
}

.u-icon-online {
    &:before {
        content: "\e1d8";
    }
}

.u-icon-offline {
    &:before {
        content: "\e1da";
    }
}

.u-icon-mail {
    &:before {
        content: "\e0e1";
    }
}

//ellipsis list
.ellipsis-container {
    position: relative;
}

a.u-icon-ellipsis {
    color: $mine-shaft;
    display: block;
    height: 15px;
    margin-top: 5px;
    position: relative;
    width: 50px;
    
    &:before {
        content: "•••";
        font-size: 22px;
        left: 0;
        position: absolute;
        top: -10px;
    }

    &:hover {
        color: $marigold-hover;

        &:before {
            color: $marigold-hover;
        }
    }
}

.u-icon-home {
    &:before {
        content: "\e88a";
    }
}

.new-design {
    a.u-icon-ellipsis {
        color: $plantation;
        
        &:hover {
            color: $sea-nymph;
        }
    }
}


//elipsis
.ellipsis-option-list,
.ellipsis-option-list-custom,
.toggle-content ul.ellipsis-option-list,
.toggle-content ul.ellipsis-option-list-custom {
    background: $white;
    border-radius: 6px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
    display: none;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 10px 15px;
    position: absolute;
    top: 90%;
    min-width: 100px;
    max-width: 160px;
    z-index: 3;

    li {
        background: transparent;
        font-size: 16px;
        margin: 0;
        padding: 0 !important;

        a {
            color: $mine-shaft;
            display: block;;
            font-weight: normal;
            text-decoration: none;
            width: 100%;

            &:hover {
                color: $marigold-hover;
            }
        }
    }
}

.new-design {
    .ellipsis-option-list,
    .ellipsis-option-list-custom,
    .toggle-content ul.ellipsis-option-list,
    .toggle-content ul.ellipsis-option-list-custom {
        li {
            a {
                &:hover {
                    color: $plantation;
                }
            }
        }
    }
}

td {
    .ellipsis-option-list,
    .ellipsis-option-list-custom {
        left: 54%;
    }

    .u-relative {
        .ellipsis-option-list,
        .ellipsis-option-list-custom {
            left: 0;
        }
    }
}

@include bp(md) {
    //elipsis
    td {
        .ellipsis-option-list,
        .ellipsis-option-list-custom {
            left: bsu(0.5);
            top: 90%;
        }

        .u-relative {
            a.u-icon-ellipsis {
                &:before {
                    left: initial;
                    right: 0;
                }
            }

            .ellipsis-option-list,
            .ellipsis-option-list-custom {
                left: inherit;
                right: 10px;
            }
        }
    }
}

//Chart Legend
.legend {
    margin: 0 0 0 35%;
    width: 65%;

    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row wrap;
    
        align-content: flex-end;
        list-style: none;
        margin: 0;

        list-style: none;
        margin: 0;

        li {
            margin: 0;
            padding: 0 bsu(0.5) 0 bsu(1);
            position: relative;
            width: 50%;

            &:nth-of-type {
                padding: 0 0 0 bsu(1);
            }

            &:before {
                /* use !important to prevent issues with browser extensions that change fonts */
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                left: -1px;
                line-height: 1;
                position: absolute;
                top: 4px;

                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
    }

    .heat-map {
        &:before {
            color: $almost-black;
            content: "\ea5f";
        }
    }

    .graph {
        &:before {
            content: "\ebc3";
        }
    }

    .pending,
    .complete {
        &:before {
            content: "";
            display: block;
            height: 10px;
            margin: 5px 0 0 0;
            width: 10px;
        }
    }

    .pending {
        &:before {
            background: $yellow;
        }
    }

    .complete {
        &:before {
            background: $hub;
        }
    }
}

@include bp(sm) {
    .legend {
        margin: 0 0 0 65%;
        width: 35%;
    }
}

@include bp(md) {
    .legend {
        margin: 0 0 0 75%;
        width: 25%;
    }
}

//actins buttons in tables
[data-label="Actions"],
[data-label="Supplier Profile"],
[data-label="View"],
[data-label="Edit"],
[data-label="Delete"],
[data-label="PDF"],
[data-label="SDS"],
[data-label="Label"],
[data-label="Edit Product"],
[data-label="Edit Attachments"],
[data-label="Edit Crop Rates"],
.additional-resource-line div.toggle-content li section,
.action-buttons {
    .disabled-title-wrapper{
        margin: 0 bsu(0.5) 0 0;
    }
    a {
        background: $marigold;
        border-radius: 3px;
        color: $white;
        display: inline-block;
        height: 30px;
        margin: 0 bsu(0.5) 0 0;
        padding: bsu(0.5);
        position: relative;
        transition: all $base-duration $base-timing;
        width: 30px;

        &:last-of-type {
            margin: 0;
        }

        &.inactive {
            cursor: default;
            opacity: 0.5;
            //width: 20px;

            &:hover {
                background: $marigold;
            }

            span {
                &:before {
                    opacity: 0.5;
                }
            }
        }

        &:disabled,
        &.disabled {
            background: $gray-chateau;
            color: rgba(255,255,255,0.8);
            cursor: not-allowed;
            opacity: 0.5;
            pointer-events: none;

            &:hover {
                background: $gray-chateau;
                color: rgba(255,255,255,0.8);
            }
        }

        &.active {
            background: $pistachio;
        }
            
        span {
            &:before {
                color: $white;
                left: 6px;
                position: absolute;
                top: 5px;
            }

            &:hover {
                background-color: $pistachio;

                &::before {
                    color: $white;
                }
            }

            &.u-icon-pencil4,
            &.u-icon-edit,
            &.u-icon-play {
                &::before {
                    left: 6px;
                    top: 6px;
                }
            }

            &.u-icon-link,
            &.u-icon-download {
                &:before {
                    left: 8px;
                    top: 8px;
                }
            }

            &.u-icon-history {
                &:before {
                    top: 3px;
                }
            }

            &.u-icon-archive2,
            &.u-icon-unarchive2,
            &.u-icon-camera {
                &:before {
                    font-size: 20px;
                    left: 5px;
                    top: 4px;
                }
            }

            &.u-icon-envelope,
            &.u-icon-diagnostic,
            &.u-icon-report {
                &:before {
                    font-size: 18px;
                    left: 6px;
                    top: 5px;
                }
            }

            &.u-icon-diagnostic,
            &.u-icon-report {
                &:before {
                    left: 3px;
                    top: 2px;
                }
            }

            &.u-icon-camera {
                &:before {
                    left: 3px;
                    top: 2px;
                }
            }

            &.u-icon-lock4, 
            &.u-icon-unlocked {
                &:before {
                    left: 6px;
                    top: 3px;
                }
            }

            &.u-icon-lock4 {
                &:before {
                    left: 8px;
                }
            }

            &.u-icon-pending-approval {
                background: url('../../Content/img/icons/icon_request-white.svg') no-repeat center center;
                background-size: 20px;
                left: 5px;
                position: absolute;
                top: 5px;
            }
        }

        &:hover,
        &:focus,
        &:active {
            background: $pistachio;
        }

        &:visited {
            background: $marigold;
        }
        
        &.u-menu-link{
            background: inherit;
            padding: 0;
            span{
                &:hover{
                    background-color: inherit;
                }
            }
        }
    }
    
}

.action-buttons {
    a {
        height: 25px;
        margin: 0 4px 0 0;
        padding: 0;
        width: 25px;

        &:last-of-type {
            margin: 0;
        }

        span {
            &:before {
                left: 3px;
                top: 2px;
            }

            &.u-icon-pencil4,
            &.u-icon-play {
                &:before {
                    left: 4px;
                    top: 4px;
                }
            }
        }
    }
}


@include bp(md) {
    [data-label="Actions"],
    [data-label="Supplier Profile"],
    [data-label="View"],
    [data-label="Edit"],
    [data-label="Delete"],
    [data-label="PDF"],
    [data-label="SDS"],
    [data-label="Label"],
    [data-label="Edit Product"],
    [data-label="Edit Attachments"],
    [data-label="Edit Crop Rates"],
    .additional-resource-line div.toggle-content li section,
    .action-buttons {
        // align-items: flex-start;
        // justify-content: center;
        text-align: center;

        .disabled-title-wrapper{
            margin: 0 bsu(0.25) 0 0;
        }
        a {
            //display: block;
            //float: left;
            display: inline-block;
            height: 25px;
            margin: 1px bsu(0.25) 1px 0;
            width: 25px;

            span {    
                &:before {
                    left: 3px;
                    top: 2px;
                }

                &.u-icon-copy {
                    &:before{
                        left: 6px;
                        top: 5px;
                    }
                }

                &.u-icon-pencil4,
                &.u-icon-edit,
                &.u-icon-play {
                    &:before {
                        left: 4px;
                        top: 4px;
                    }
                }

                &.u-icon-link,
                &.u-icon-download {
                    &:before {
                        left: 5px;
                        top: 4px;
                    }
                }

                &.u-icon-archive2,
                &.u-icon-unarchive2 {
                    &:before {
                        left: 3px;
                        top: 1px;
                    }
                }

                &.u-icon-envelope {
                    &:before {
                        font-size: 14px;
                    }
                }
            }

            &:last-of-type {
                margin: 0;
            }
        }
    }
}



//k-grid action buttons on food safety logs
.k-grid {
    a {
        .u-icon-pending-approval {
            background: url('../../Content/img/icons/icon_request.svg') no-repeat center center;
            height: 16px;
            left: inherit;
            position: relative;
            top: inherit;

            &:hover {
                background: url('../../Content/img/icons/icon_request-hover.svg') no-repeat center center;
            }
        }
    }
}

//k-grid and general telerik expand icon
.k-i-expand,
.k-i-collapse {
    height: 20px;
    opacity: 1;
    width: 20px;

    &:before {
        color: $grey;
        content: "\edf7";
        font-size: 16px;
        font-weight: bold;
        position: relative;
        top: 0px;
        transition: none;
    }
}

.k-i-collapse {
    &:before {
        transform: rotate(90deg);
    }
}

// Custom Icons
.u-icon-stopwatch {
    display: inline-block;
    height: 24px;
    width: 20px;
    background: url("../../Content/img/icons/stopwatch_gray.svg") no-repeat center center;
    background-size: 21px;
    margin-bottom: -0.35rem;
    
    &:before {
      content: "";
    }
}
.u-icon-ellipsis-vertical {
    display: inline-block;
    height: 24px;
    width: 20px;
    background: url("../../Content/img/icons/icon-ellipsis-vertical.svg") no-repeat center center;
    background-size: 21px;
    margin-bottom: -0.35rem;
    
    &:before {
      content: "";
    }
}

//statuses
.status-legend {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    section {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;

        font-size: 15px;
        margin: 0 20px 20px 0;

        &:last-of-type {
            margin-right: 0;
        }

        .status {
            margin: 0 10px 0 0;
        }
    }
}

@include bp(sm) {
    .status-legend {
        section {
            margin: 0 30px 0 0;
        }
    }
}

.status {
    border-radius: 100%;
    display: block;
    height: 14px;
    width: 14px;

    &.status-approved {
        background: $approved url("../../Content/img/icons/Status_Green.svg");
    }

    &.status-pending {
        background: $pending url("../../Content/img/icons/Status_Yellow.svg");
    }

    &.status-not-submitted {
        background: $not-submitted url("../../Content/img/icons/Status_Red.svg");
    }
}

table {
    .status {
        margin-top: 5px;
    }
}

///////////////////////////////////////////////
//new design
///////////////////////////////////////////////
body.new-design {

    //accordian
    .u-icon-accordion {
        color: $silver3;

        &::before {
            font-weight: bold;
        }
    }

    a [class^="u-icon-"],
    a [class*=" u-icon-"] {
        &:before {
            color: $grey;
        }

        &:hover {
            &:before {
                color: $mine-shaft;
            }
        }
    }

    .u-icon-delete,
    .u-icon-edit {
        display: block;
        height: 20px;
        width: 20px;

        &:before {
            content: '';
        }
    }
    
    .u-icon-delete {
        background: url('../../Content/img/icons/icon-trash.svg') no-repeat center center;
        background-size: 15px;
    }

    .u-icon-edit {
        background: url('../../Content/img/icons/icon-edit.svg') no-repeat center center;
        background-size: 18px;
    }

    .u-icon-link {
        &:before {
            background: url('../../Content/img/icons/icon-link.svg') no-repeat 0 0;
            content: "";
            display: inline-block;
            height: 14px;
            width: 14px;
        }
    }
}

//new design action buttons
//action buttons in tables
body.new-design {
    .table-delete {
        display: flex;
		flex-direction: row;
		flex-flow: row wrap;
		align-items: center;
		justify-content: flex-start;

        cursor: pointer;

        p {
            display: none;
            margin: 0;
            font-weight: bold;

            &.close {
                //margin: 6px 0;
                padding: 0 0 0 5px;
            }
        }

        .u-icon-delete {
            &:before {
                color: $plantation;
                display: block;
                fill: $plantation !important;
                top: -1px;
            }
        }

        &:hover {
            .u-icon-delete {
                &:before {
                    color: $spring-leaves;
                    fill: $spring-leaves !important;
                }
            }
        }

        &.validate {
            p {
                display: block;
                font-size: 14px;
                width: 100%;

                &.close {
                    width: auto;
                }
            }

            .u-icon-delete {
                &:before {
                    font-size: 22px;
                }
            }
        }
    }

    [data-label="Actions"],
    [data-label="Supplier Profile"],
    [data-label="View"],
    [data-label="Edit"],
    [data-label="Delete"],
    [data-label="PDF"],
    [data-label="SDS"],
    [data-label="Label"],
    [data-label="Edit Product"],
    [data-label="Edit Attachments"],
    [data-label="Edit Crop Rates"],
    .additional-resource-line div.toggle-content li section,
    .action-buttons {
        a {
            background: transparent;
            border: 2px solid $plantation;
            border-radius: 6px;
            color: $plantation;
            display: inline-block;
            height: 25px;
            margin: 0 5px 0 0;
            padding: 5px;
            position: relative;
            transition: all $base-duration $base-timing;
            width: 25px;

            &:last-of-type {
                margin: 0;
            }

            &.text-link {
                border: none;
                border-radius: 0;
                float: left;
                height: auto;
                margin: 0 10px 0 0;
                padding: 0;
                width: auto;
            }
            
            &.active {
                background: transparent;
            }
                
            span {
                &:before {
                    color: $plantation;
                    left: 3px;
                    top: 3px;
                }

                &:hover {
                    background: $grey;

                    &::before {
                        color: $plantation;
                    }
                }

                &.u-icon-delete {
                    &::before {
                        left: 2px;
                        top: 1px;
                    }
                }
            }

            &:hover {
                background: $grey;
            }
        }
    }
}

@include bp(md) {
    body.new-design {
        .table-delete {
            justify-content: flex-end;
            text-align: right;
    
            &.validate {
                p {
                    //margin: 6px 4px 6px 0;
                    width: auto;
                }
            }
        }
    }
}

@each $name, $value in $color-map {
    .u-icon--#{$name} {
        &:before{
            color: $value !important;
            fill: $value !important;
        }
        
    }
}


// svg icons
.u-icon-info-outline,
.u-icon-warning-outline,
.u-icon-alert,
.u-icon-calculator,
.u-icon-time-outline,
.u-icon-launch,
.u-icon-delete-outline{
    cursor: pointer;
    display: block;
    height: 24px;
    font-size: 24px;
    width: 24px;
        
    &:before {
        content: "";
        display: block;
        text-align: center;
        top: 0;
        height: 100%;
        width: 22px;
    }
}
.u-icon-info-outline{
    &:before{
        background: url("../../Content/img/icons/icon-info-outline.svg") no-repeat center center;
    }
}
.u-icon-warning-outline {  
    &:before {
        background: url("../../Content/img/icons/icon-warning-outline.svg") no-repeat center center;
    }
}
.u-icon-alert {
    &:before {
        background: url("../../Content/img/icons/icon-alert.svg") no-repeat center center;
    }
}
.u-icon-calculator {
    &:before {
        background: url("../../Content/img/icons/tank_calculator.svg") no-repeat center center;
    }
}
.u-icon-time-outline {
    &:before {
        background: url("../../Content/img/icons/time_outline.svg") no-repeat center center;
    }
}
.u-icon-launch {
    &:before {
        background: url("../../Content/img/icons/icon_launch.svg") no-repeat center center;
    }
}
.u-icon-delete-outline {
    &:before {
        background: url("../../Content/img/icons/icon_delete_outline.svg") no-repeat center center;
    }
}
