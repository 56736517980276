$width-percentage: 100;

@mixin percent-x {
    @for $i from 1 through $width-percentage {
        &.percent-#{$i} { 
            width: #{$i}#{'%'} !important; 
        }
    }
}

table.non-responsive {
    th,
    td {
        @include percent-x;
    }
}