$trigger-width: 58px;
$trigger-width-large: 86px;
$trigger-width-large-tweak: 68px;
$icon-size: 1.75rem;

//nav toggle-button
.c-main-nav__toggle {
    &:before {
        color: $white!important;
    }
}

.u-icon-menu {
    &::before {
        color: $white;
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover,
    &:active,
    &:visited {
        &:before {
            color: $white;
        }
    }
}

.c-main-nav__trigger {
    position: relative;
    width: $trigger-width;
    user-select: none;
    background-color: $hub;
    color: $white;
    height: $top-bar-height-mobile;
    max-width: bsu(3.5);
}

@include bp(sm) {
    .c-main-nav__trigger {
        height: $top-bar-height;
    }
}

//main-nav container
.c-main-nav__wrapper {
    position: fixed;
    top: 57px;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: $trigger-width;
    background: $white;
    transform: translateX(-65px);
    transition: all $base-duration $base-timing;

    border-right: 1px solid $concrete;
    border-top: 2px solid $concrete;

    &.c-main-nav--open-part {
        border-right: 1px solid $concrete;
    }
}

.new-design {
    .c-main-nav__wrapper {
        border-right: none;
        border-top: none;
    }
}

.c-main-nav,
.c-main-nav ul {
    margin: 0;
    padding: 0;
    background: $white;
    color: $base-font-color;
    list-style: none;
    margin: 0;
    text-transform: none;

    li {
        margin: 0;
    }
}

.c-main-nav__scroller {
    position: absolute;
    width: bsu(16);
    height: 100%;
    background-color: $white;
    overflow-y: scroll;
}

.c-main-nav__menu {
    text-align: left;
    font-size: 18px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.c-main-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $top-bar-height-mobile;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    z-index: 102;

    a {
        color: $ship-gray;
        display: block;
        height: 100%;
        font-size: 18px;
        font-weight: 500;
        text-decoration: none;
        text-transform: uppercase;
        transition: all $base-duration $base-timing;
        cursor: pointer;
    }

    & li > a {
        padding: 0;
    }

    nav {
        & li > a {
            padding: bsu(1) bsu(1.5) bsu(1) bsu(3);
            position: relative;
            z-index: 2;
        }
    }

    span[class^="u-icon-"],
    span[class*=" u-icon-"] {
        position: absolute;
        left: bsu(1);
        margin-top: -14px;
        top: 50%;
        width: $trigger-width;
        height: $trigger-width;
        font-size: $icon-size;
        z-index: 1;

        svg {
            width: $icon-size;
            height: auto;
            transition: all $base-duration $base-timing;
        }
    }

    span.u-icon-farm-details,
    span.u-icon-farm-details {
        svg {
            width: 1.5rem;
        }
    }
}

.c-main-nav__menu-item {
    width: 100%;
    background: $white;
    border-bottom: 1px solid $concrete;
    color: $almost-black;
    margin: 0;

    a {
        color: $almost-black;
        position: relative;

        svg {
            color: $almost-black;
            fill: currentColor;
        }

        &.hassubmenu {
            &:after {
                color: $almost-black;
                content: "\edf6";
                position: absolute;
                right: 20px;
                top: bsu(1.25);
                transition: all $base-duration $base-timing;

                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
            
                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }

            &:before {
                background: $hub;
                content: "";
                height: 100%;
                left: 0;
                opacity: 0;
                position: absolute;
                top: 0;
                transition: all $base-duration $base-timing;
                width: 5px;
            }

            &.open {
                color: $hub;

                svg {
                    color: $hub;
                    fill: currentColor;
                }

                &:before {
                    opacity: 1;
                }

                &:after {
                    color: $hub;
                    transform: rotate(90deg);
                }

                &:hover {
                    background: $hub;
                    color: $white;

                    svg {
                        color: $white;
                    }
    
                    &:before {
                        background: $white;
                    }
    
                    &:after {
                        color: $white;
                    }
                }
            }
        }
    }

    &.c-main-nav__admin-action {
        a {
            color: $hub;

            svg {
                color: $hub;
                fill: currentColor;
            }
        }
    }
}

@include bp(sm) {
    .c-main-nav__wrapper {
        top: $top-bar-height;
        width: $trigger-width-large-tweak;
        transform: translateX(-95px);
    }

    .c-main-nav {
        height: $top-bar-height;
    
        span[class^="u-icon-"],
        span[class*=" u-icon-"] {
            width: $trigger-width-large;
            height: $trigger-width-large;
            margin-top: -14px;
            font-size: $icon-size;
        }
    }
            
    .c-main-nav__trigger {
        width: $trigger-width-large;
    }
}

@include bp(md) {
    .c-main-nav__menu-item {
        a {
            &.hassubmenu {
                &:after {
                    right: 20px;
                }
            }
        }
    }
}

//subnav
.c-main-nav__submenu {
    display: none;

    .c-main-nav__menu-item {
        border-bottom: 1px solid $almost-black;
        border-top: 1px solid $scarpa-flow;
		overflow: hidden;
		height: 0;
        transition: height 0.3s;
        background: $ship-gray;
        margin: 0;
        
        a {
            color: $white;
            font-size: 16px;
            padding: bsu(0.75) 0 bsu(0.75) bsu(3.25);

            svg {
                color: $white;
                fill: currentColor;
                height: auto;
                left: bsu(1.5);
                position: absolute;
                transition: all $base-duration $base-timing;
                width: 1.5rem;
            }
        }
    }

    span[class^="u-icon-"],
    span[class*=" u-icon-"] {
        svg {
            fill: $jumbo;
        }
    }
}

.c-main-nav__menu-item > a,
.c-main-nav__submenu > .c-main-nav__menu-item > a {
    &:hover {
        background-color: $hub;
        color: $white;

        &:after {
            color: $white;
        }

        svg {
            fill: $white;
        }
    }
}

.c-main-nav__wrapper,
.c-main-nav__wrapper.c-main-nav--open-part {
    .c-main-nav__submenu {
        height: 0;
    }
}

.c-main-nav__wrapper.c-main-nav--open-all {
    .c-main-nav__submenu {
        height: auto;
    }
}

// styles for opening menu
.c-main-nav__wrapper.c-main-nav--open-all,
.c-main-nav__wrapper.c-main-nav--open-part {
    transform: translateX(0px);
}

.c-main-nav__wrapper.c-main-nav--open-all {
    width: bsu(15.5);
}

.c-main-nav__wrapper.c-main-nav--open-all .c-main-nav__submenu li {
    height: auto;
}

.no-touch {
    .c-main-nav {
        a:hover {
            background: $jumbo;
            color: $white;
        }
    }

    .u-icon-menu:hover::before,
    .u-icon-menu:hover::before {
        background: $white;
        box-shadow: 0 3px $jumbo, 0 -6px $white, 0 -9px $jumbo, 0 -12px $white;
    }
}

@include bp(sm) {
	.c-main-nav__wrapper.c-main-nav--open-all {
        transform: translateX(0px);
        width: 235px;
	}

	.c-main-nav__wrapper.c-main-nav--open-all .c-main-nav__scroller {
        //width: bsu(15.75);
        width: 255px;
    }
    
    .c-main-nav__header-item {
        display: block;
    }
}

.nav-container {
    width: 55px;
    height: 55px;

    ul {
        margin: 0;
        padding: 0;
        background: $white;
        color: $ship-gray;
        list-style: none;
        margin: 0;
        text-transform: none;

        li {
            margin: 0;
        }
    }

    a {
        color: $ship-gray;
        display: block;
        height: 100%;
        font-size: 18px;
        font-weight: 500;
        text-decoration: none;
        text-transform: uppercase;
        transition: all $base-duration $base-timing;
        cursor: pointer;
    }

    & li > a {
        padding: 0;
    }

    nav {
        & li > a {
            padding: bsu(1) bsu(1.5) bsu(1) bsu(3.35);
            position: relative;
            z-index: 2;
        }
    }

    span[class^="u-icon-"],
    span[class*=" u-icon-"] {
        position: absolute;
        left: bsu(1);
        margin-top: -12px;
        top: 50%;
        width: $trigger-width;
        height: $trigger-width;
        font-size: $icon-size;
        z-index: 1;

        svg {
            width: $icon-size;
            height: auto;
            transition: all $base-duration $base-timing;
        }
    }
}

@include bp(sm) {
    .nav-container {
        height: 55px;
    
        span[class^="u-icon-"],
        span[class*=" u-icon-"] {
            width: 55px;
            height: 55px;
            margin-top: -14px;
            font-size: $icon-size;
        }
    }
}
