.c-cube-loader {
    width: 64px;
    height: 64px;
}

.c-cube-loader__cube {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white url('../img/spinner.gif') no-repeat center;
    z-index: 1003;
}

/* Preloader */
#dynamic-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba($white, 0.66);
    margin: 0;
    padding: 0;
    z-index: z('goku');

    .c-cube-loader,
    #tractor {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

}

#preloader-relative {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,0.7) url('../img/spinner.gif') no-repeat center;
    z-index: 1003;
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white url('../img/spinner.gif') no-repeat center;
    z-index: 1003;
}
  
#preloader-status {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    background-image: url('../img/spinner.gif');
    background-repeat: no-repeat;
    background-position: center;
    margin: -100px 0 0 -100px;
    z-index: 1004;
}

#loadingIcon {
    background: $white url('../img/spinner.gif') no-repeat center;
    height: 100px;
    width: 100%;
}

.dot-loader-wrapper{
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.dot-loader {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $almost-black;
    box-shadow: 32px 0 $almost-black, -32px 0 $almost-black;
    position: relative;
    animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
0% {
    background-color: unquote($almost-black + "20");
    box-shadow: 32px 0 unquote($almost-black + "20"), -32px 0 $almost-black;
}
50% {
    background-color: $almost-black;
    box-shadow: 32px 0 unquote($almost-black + "20"), -32px 0 unquote($almost-black + "20");
}
100% {
    background-color: unquote($almost-black + "20");
    box-shadow: 32px 0 $almost-black, -32px 0 unquote($almost-black + "20");
}
}