.SumoSelect {
    p {
        margin: 0;
    }

    .hidden { 
        display: none; 
    }
}

.SelectBox {
    padding: 5px 8px;
}

.sumoStopScroll{
    overflow:hidden;
}

/*this is applied on that hidden select. DO NOT USE display:none; or visiblity:hidden; and Do not override any of these properties. */
.SelectClass,
.SumoUnder { 
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    height: 0; 
    width: 100%; 
    border: none; 
    opacity: 0; 
}

.SelectClass{
    z-index: 1;
}

.SumoSelect > .optWrapper > .options  li.opt label, 
.SumoSelect > .CaptionCont,
.SumoSelect .select-all > label { 
    user-select: none; 
    -o-user-select: none; 
    -moz-user-select: none; 
    -khtml-user-select: none; 
    -webkit-user-select: none; 
}

.SumoSelect { 
    display: inline-block; 
    position: relative;
    outline:none;
    width: 100%;

    > p.CaptionCont { 
        background-color: $white;
        border: $base-border;
        border-radius: $base-border-radius;
        box-shadow: none;
        box-sizing: border-box;
        color: $jumbo;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.15;
        margin-bottom: $base-spacing;
        padding: 0.5625rem 1.25rem 0.6875rem 1.25rem;
        transition: border-color $base-duration $base-timing;
        width: 100%;

        span {
            color: $almost-black;
            font-weight: 400;

            &.placeholder {
                color: $jumbo;
            }
        }

        > label {
            height: bsu(2);
            position: absolute; 
            top: 0; 
            right: 0; 
            width: 100%;

            > i { 
                background: $white url('../../Content/img/icons/icon_arrow_down.svg') no-repeat 0 0;
                background-size: 12px;
                display: block;
                height: 12px;
                position: absolute; 
                right: 10px;
                top: 13px; 
                width: 12px;
            }
        }
    }
}

.SumoSelect:focus,
.SumoSelect:hover, 
.SumoSelect.open {
    > .CaptionCont {
        border-color: $pistachio;
        box-shadow: none;
        outline: none;
    }
}

.SumoSelect {
    > .optWrapper {
        display:none; 
        z-index: 1000; 
        top: 30px; 
        width: 100%; 
        position: absolute; 
        left: 0; 
        box-sizing: border-box; 
        background: $white; 
        border: $base-border; 
        overflow: hidden;
    }
}

.SumoSelect.open > .optWrapper {
    top: 35px; 
    display: block;
}

.SumoSelect.open > .optWrapper.up {
    top: auto;
    bottom: 100%;
    margin-bottom: 5px;
}

.SumoSelect > .optWrapper ul {
    list-style: none; 
    display: block; 
    padding: 0; 
    margin: 0; 
    overflow: auto;
}

.SumoSelect > .optWrapper > .options { 
    position:relative;
    max-height: 250px;
}

.SumoSelect > .optWrapper.selall.okCancelInMulti > .options { 
    border-radius: 0;
}

.SumoSelect > .optWrapper > .options li.group.disabled > label{
    opacity: 0.5;
}

.SumoSelect > .optWrapper > .options li ul li.opt{
    padding-left: 22px;
}

.SumoSelect > .optWrapper.multiple > .options li ul li.opt{
    padding-left: 50px;
}

.SumoSelect > .optWrapper.isFloating > .options {
    max-height: 100%;
}

.SumoSelect > .optWrapper > .options  li.opt { 
    padding: 6px 6px; 
    position: relative;
    border-bottom: $base-border;
}

.SumoSelect > .optWrapper.selall > .options > li.opt:first-child { 
    border-radius:0; 
}

.SumoSelect > .optWrapper > .options > li.opt:last-child {
    border-bottom: none;
}

.SumoSelect > .optWrapper.okCancelInMulti > .options > li.opt:last-child {
    border-radius: 0;
}

.SumoSelect > .optWrapper > .options li.opt:hover { 
    background: $iron; 
}

.SumoSelect > .optWrapper > .options li.opt.sel, 
.SumoSelect .select-all.sel {
    background: $concrete;
}


.SumoSelect > .optWrapper > .options li label { 
    text-overflow: ellipsis; 
    white-space: nowrap; 
    overflow: hidden; 
    display: block;
    cursor: pointer;
}

.SumoSelect > .optWrapper > .options li span { 
    display: none;
}

.SumoSelect > .optWrapper > .options li.group > label {
    cursor: default;
    padding: 8px 6px;
}

/*styling for multiple select*/
.SumoSelect > .optWrapper.multiple > .options li.opt { 
    padding-left: 35px;
    cursor: pointer;
}

.SumoSelect > .optWrapper.multiple > .options li.opt span,
.SumoSelect .select-all > span{
    position:absolute;
    display:block;
    width:30px;
    top:0;
    bottom:0
    ;margin-left:-35px;
}

.SumoSelect > .optWrapper.multiple > .options li.opt span i,
.SumoSelect .select-all > span i{
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    border: $base-border;
    background: $white;
}

.SumoSelect > .optWrapper > .MultiControls { 
    display: none; 
    border-top: $base-border; 
    background: $white; 
}

.SumoSelect > .optWrapper.multiple.isFloating > .MultiControls { 
    display: block; 
    margin-top: 5px; 
    position: absolute; 
    bottom: 0;
    width: 100%; 
}


.SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls { 
    display: block; 
}

.SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls > p { 
    padding: 6px; 
}

.SumoSelect > .optWrapper.multiple > .MultiControls > p {
    background: $white;
    display: inline-block; 
    cursor: pointer; 
    padding: 12px; 
    width: 50%; 
    box-sizing: border-box; 
    text-align: center;
    transition: all $base-duration $base-timing;
}

.SumoSelect > .optWrapper.multiple > .MultiControls > p:hover { 
    background: $hub;
    color: $white; 
}

.SumoSelect > .optWrapper.multiple > .MultiControls > p.btnOk { 
    border-right: $base-border; 
}

/*styling for select on popup mode*/
.SumoSelect > .optWrapper.isFloating > .options li.opt { 
    padding: 12px 6px; 
}


/*styling for only multiple select on popup mode*/
.SumoSelect > .optWrapper.multiple.isFloating > .options li.opt { 
    padding-left: 35px; 
}

.SumoSelect > .optWrapper.multiple.isFloating { 
    padding-bottom: 43px; 
}


.SumoSelect > .optWrapper.multiple > .options li.opt.selected span i,
.SumoSelect .select-all.selected > span i,
.SumoSelect .select-all.partial > span i {
    background-color: $pistachio;
    box-shadow: none;
    border-color: transparent;
    font-style: normal;

    &:before {
        color: $white;
        content: "\edab";
        font-family: 'icomoon';
        font-size: 12px;
        font-weight: 400;
        left: 3px;
        position: relative;
        top: -1px;
    }
}

//multiselect non-specific
ul.multiselect {
    list-style: none;
    margin: 0;

    li {
        // background: $white url('../../Content/img/icons/icon_arrow_down.svg') no-repeat 98% 10px;
        // background-size: 12px;
        // border: $base-border;
        // border-radius: $base-border-radius;
        // box-shadow: none;
        // box-sizing: border-box;
        // color: $almost-black;
        // display: block;
        // font-family: $base-font-family;
        // font-size: 14px;
        // line-height: 0.9;
        // margin-bottom: 0;
        // padding: bsu(0.45) bsu(1) bsu(0.55) bsu(1);
        // position: relative;
        // transition: border-color $base-duration $base-timing;
        // width: 100%;
        // z-index: 2;
        
        // &:hover {
        //     border-color: $pistachio;
        // }
        
        // &:focus {
        //     border-color: $pistachio;
        //     box-shadow: none;
        //     outline: none;
        // }
        appearance: none;
        background-color: $white;
        border: $base-border;
        border-radius: $base-border-radius;
        box-shadow: none;
        box-sizing: border-box;
        color: $almost-black;
        font-size: 16px;
        height: 44px;
        line-height: 1;
        margin: 0;
        padding: 12px;
        transition: border-color $base-duration $base-timing;
        width: 100%;

        background: $white url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat right center;
        background-size: 24px;
        color: $jumbo;
        width: 100%;

        &:hover {
            border-color: $ada-grey-3;
        }

        &:focus {
            border-color: $pistachio;
            border-width: 2px;
            box-shadow: none;
            outline: none;
        }
    }

    ul {
        background: $white;
        border: $base-border;
        box-shadow: 0 0 5px rgba($ship-gray, 0.2);
        display: none;
        left: -2.5%;
        list-style: none;
        margin: 0;
        padding-top: bsu(0.5);
        position: absolute;
        top: 90%;
        width: 105%;
        z-index: 99;

        &:before,
        &:after {
            content: "";
            height: 0;
            left: 50%;
            position: absolute;
            width: 0;
        }
        
        &:before {
            border-bottom: 10px solid $base-border-color;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            margin-left: -9px;
            top: -10px;
        }

        &:after {
            border-bottom: 8px solid $white;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            margin-left: -7px;
            top: -8px;
        }

        li {
            background: transparent;
            border: none;
            border-radius: 0;
            margin: 0;
            padding: 0;
            position: relative;
            z-index: 4;
        }
    }

    .styled-checkbox,
    .styled-radio {
        height: 44px;
        left: 0;
        margin: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 5;

        & + label {
            font-weight: 400;
            padding: bsu(0.4) bsu(0.75) bsu(0.45) bsu(0.75);
            margin: 0;
            
            span {
                font-size: 14px;
                font-style: normal;
                position: relative;
                z-index: 2;
            }
        }

        // Box
        & + label:before {
            background: transparent;
            border: none;
            content: "";
            display: block;
            height: 44px;
            left: 0;
            padding: 0 0 2px 4px;
            position: absolute;
            top: 0;
            transition: all $base-duration $base-timing;
            width: 100%;
            z-index: 1;
        }

        // Box hover
        &:hover + label:before{
            background: rgba($hub, 0.3);
        }

        &:focus + label:before {
            background: transparent;
        }

        // Box checked
        &:checked + label {
            font-weight: 600;
            
            &:before {
                background: $iron;
                border-left: 2px solid $pistachio;
            }
        }
    }
}

//kendo overrides

.k-state-hover,
.k-state-focused {
    box-shadow: none !important;
}

.k-autocomplete {
    margin: 0 0 bsu(0.75) 0;
}

.k-autocomplete.k-state-default, 
.k-dropdown-wrap.k-state-default, 
.k-numeric-wrap.k-state-default, 
.k-picker-wrap.k-state-default {
    appearance: none;
    background-color: $white;
    border: $base-border;
    border-radius: $base-border-radius;
    box-shadow: none;
    box-sizing: border-box;
    color: $almost-black;
    font-size: 16px;
    margin-bottom: bsu(0.75);
    padding: 4px 0;
    transition: border-color $base-duration $base-timing;
    width: 100%;

    &:hover {
        border-color: $ada-grey-3;
    }

    &:focus {
        border-color: $pistachio;
        border-width: 2px;
        box-shadow: none;
        outline: none;
    }

    input {
        border: none;
        padding: 0;
    }
}

.k-autocomplete.k-state-default.k-state-hover, 
.k-dropdown-wrap.k-state-default.k-state-hover, 
.k-numeric-wrap.k-state-default.k-state-hover, 
.k-picker-wrap.k-state-default.k-state-hover {
    background: transparent;
    box-shadow: none !important;
    border-color: $ada-grey-3;
    color: $almost-black;

    input {
        border: none;
        padding: 0;
    }

    &.k-state-focused {
        border-color: $pistachio;
        border-width: 2px;
    }
}

.k-dropdown .k-input, .k-menu .k-popup {
    color: $almost-black;
    padding: 5px;
}

.k-i-arrow-60-down:before {
    background-color: $white; 
    background-image: url('../../Content/img/icons/icon_arrow_down_light.png');
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: 21px;
    content: "";
    //top: -10px;
}

.k-list>.k-state-focused.k-state-selected, 
.k-listview>.k-state-focused.k-state-selected, 
.k-state-focused.k-state-selected, 
td.k-state-focused.k-state-selected {
    background: $iron;
    background-image: none;
    border-left: 2px solid $pistachio;
    box-shadow: none;
}

.k-item {
    transition: all $base-duration $base-timing;
}

.k-state-hover {
    background-image: none;
    background: none !important;
    color: $ship-gray;

    &.hover {
        background-image: none;
        background: none !important;
        color: $ship-gray;
    }
}

.k-multiselect .k-multiselect-wrap,
.k-multiselect-wrap.k-state-focused, 
.k-state-focused > .k-multiselect-wrap,
.k-multiselect-wrap.k-state-focused.k-state-border-down {
    border-radius: $base-border-radius !important;
    box-shadow: none;

    .k-select {
        z-index: 999;
    }
}

// .k-combobox-clearable .k-input, 
// .k-dropdowntree-clearable .k-dropdown-wrap .k-input, 
// .k-dropdowntree-clearable .k-multiselect-wrap, 
// .k-multiselect-clearable .k-multiselect-wrap {
//     padding: 2px 2em 2px 2px;
// }

.k-multiselect-wrap {
    // appearance: none;
    // background-color: $white;
    // border: $base-border !important;
    // border-radius: $base-border-radius;
    // box-shadow: none;
    // box-sizing: border-box;
    // color: $jumbo;  
    // margin-bottom: bsu(0.75);
    // min-height: 35px;
    // padding: bsu(0.45) bsu(0.25) bsu(0.48) bsu(0.25);
    // transition: border-color $base-duration $base-timing;
    // width: 100%;

    // &:hover {
    //     border-color: $pistachio;
    // }

    // &:focus {
    //     border: none;
    //     border-color: $pistachio;
    //     box-shadow: none;
    //     outline: none;
    // }

    appearance: none;
    background-color: $white;
    border: $base-border;
    border-radius: $base-border-radius;
    box-shadow: none;
    box-sizing: border-box;
    color: $almost-black;
    font-size: 16px;
    min-height: 44px;
    margin-bottom: bsu(0.75);
    padding: 4px;
    transition: border-color $base-duration $base-timing;
    width: 100%;

    background: $white;
    background-size: 24px;
    color: $jumbo;
    width: 100%;

    &:hover {
        border-color: $ada-grey-3;
    }

    &:focus {
        border-color: $pistachio;
        border-width: 2px;
        box-shadow: none;
        outline: none;
    }

    .k-input {
        //removed display: none to re-inable keyboard controls for multiselects
        //display: none;
        height: 0;
        opacity: 1;
        font-family: sans-serif;
    }

    .k-reset {
        li.k-button {
            padding: 5px bsu(1) 0 10px;
        }
    }

    li {
        border-radius: 5px;
        float: left;
        margin: 0;
        line-height: 1.1em;
        position: relative;

        &.k-button {
            font-size: 14px;
            text-transform: none;

            &.k-state-hover {
                background: $marigold !important;
            }
        }

        span {
            font-weight: normal;
            margin: 0;
        }
    }
}

.k-multiselect-wrap.k-state-focused, 
.k-state-focused>.k-multiselect-wrap {
    border: $base-border;
}

.k-list-container {
    border: $base-border;
    border-radius: 0 5px 5px 0;
    box-shadow: none;
    box-sizing: border-box;
}

.k-autocomplete .k-input, 
.k-autocomplete.k-state-focused .k-input, 
.k-dropdown-wrap .k-input, 
.k-dropdown-wrap.k-state-focused .k-input, 
.k-numeric-wrap.k-state-focused .k-input, 
.k-picker-wrap.k-state-focused .k-input, 
.k-textbox>input {
    border-radius: $base-border-radius;
    box-shadow: none;
}

.k-autocomplete .k-input, 
.k-dropdown-wrap .k-input, 
.k-textbox>input {
    border: $base-border;
}

.k-multiselect-wrap {
    .k-input[readonly=readonly] {
        opacity: 0.66;
        pointer-events: none;
        cursor: initial;
    }
    
    .k-reset {
        li.k-button {
            padding: 5px bsu(1.5) 0 10px;
            margin: 0 5px 2px 0;
            width: auto;

            &:last-of-type {
                margin: 0 0 2px 0;
            }

            .k-icon {
                &:before  {
                    top: 8px;
                }
            }
        }
    }
}

//color blocks

ul.multiselect.color-blocks {
    position: relative;

    li {
        background: $white url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat right center;
        background-size: 24px;
        color: $jumbo;
        height: 44px;
        padding: 10px bsu(0.5) bsu(0.6) bsu(0.5);

        &.disabled {
            pointer-events: none;
        }
    }

    ul.block-color {
        left: 0;
        padding: 0;
        width: 100% !important;

        .styled-radio {
            & + label {
                padding: bsu(0.4) bsu(0.5);
            }

            & + label:before {
                height: bsu(2);
            }
        }

        li {
            background: transparent;
            border-bottom: 1px solid $concrete;
            //float: left;
            padding: 0;
            width: 100%;

            label {
                display: flex;
                flex-direction: row;
                flex-flow: row wrap;
                align-items: center;
                justify-content: flex-start;

                div {
                    display: inline-block;
                    height: 20px;
                    margin: 0 bsu(0.5) 0 0; 
                    position: relative; 
                    width: 20px;
                    z-index: 2;
                }
            }
        }
    }

    &.planting-setup-templates {
        ul.block-color {
            top: 40px;

            // &:before,
            // &:after {
            //     top: 40px;
            // }
        }
    }
}


@include bp(md) {
    ul.multiselect.color-blocks {
        ul.block-color {
            left: -135px;
            width: 400px !important;

            li {
                float: left;
                width: 50%;
            }
        }

        &.planting-setup-templates {
            ul.block-color {
                left: 0;

                &:before,
                &:after {
                    left: 30px;
                }
            }
        }
    }
}