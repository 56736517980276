//.swiper-slide,
.swiper-container {
    margin-bottom: 20px;
    width: 100% !important;

    .swiper-wrapper {
        margin-bottom: 35px;
    }

    //padding-bottom: 25px;

    .form-flex .form-item label {
        width: auto;
    }
}

.swiper-pagination-bullet:only-child {
    visibility: hidden;
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets {
    .swiper-pagination-bullet {
        background: $pistachio;
        display: inherit;
        height: 20px;
        margin: 0 15px 0 0;
        opacity: 1;
        outline: none;
        width: 20px;

        &:last-of-type {
            margin: 0;
        }
    }

    .swiper-pagination-bullet-active {
        background: $marigold;
    }
}

.swiper-pagination-bullet:only-child {
    //visibility: hidden;
    display: none;
}

.swiper-pagination,
.swiper-pagination2,
.swiper-pagination3,
.swiper-pagination4,
.swiper-pagination5,
.swiper-pagination6,
.swiper-pagination7,
.swiper-pagination8,
.swiper-pagination9,
.swiper-pagination10,
.swiper-pagination11,
.swiper-pagination12,
.swiper-pagination13,
.swiper-pagination14,
.swiper-pagination15,
.swiper-pagination16,
.swiper-pagination17,
.swiper-pagination18,
.swiper-pagination19,
.swiper-pagination20,
.swiper-pagination21,
.swiper-pagination22,
.swiper-pagination23,
.swiper-pagination24,
.swiper-pagination25,
.swiper-pagination26,
.swiper-pagination27,
.swiper-pagination28,
.swiper-pagination29,
.swiper-pagination30 {
// .swiper-pagination,
// [class^="swiper-pagination"],
// [class*="swiper-pagination"] {
    bottom: 0;
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: top;
    justify-content: center;
    width: 100%;

}


.swiper-pagination,
.swiper-pagination2,
.swiper-pagination3,
.swiper-pagination4,
.swiper-pagination5,
.swiper-pagination6,
.swiper-pagination7,
.swiper-pagination8,
.swiper-pagination9,
.swiper-pagination10,
.swiper-pagination11,
.swiper-pagination12,
.swiper-pagination13,
.swiper-pagination14,
.swiper-pagination15,
.swiper-pagination16,
.swiper-pagination17,
.swiper-pagination18,
.swiper-pagination19,
.swiper-pagination20,
.swiper-pagination21,
.swiper-pagination22,
.swiper-pagination23,
.swiper-pagination24,
.swiper-pagination25,
.swiper-pagination26,
.swiper-pagination27,
.swiper-pagination28,
.swiper-pagination29,
.swiper-pagination30 {
    margin-top: 20px;
}

.step-slider-widget {
    .slide-inner-wrapper {
        background: $hub;
        padding: bsu(0.5);

        h2,
        h3 {
            font-weight: 600;
            text-transform: uppercase;
        }

        h2 {
            font-size: 16px;
            margin: 0 0 bsu(0.25) 0;
        }

        h3 {
            color: $white;
            margin: 0 0 bsu(0.5) 0;
        }

        p {
            margin: 0 0 bsu(0.5) 0;
        }

        .inner-wrapper {
            background: $white;
            padding: bsu(0.5);

            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            justify-content: center;
        }

        div {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: flex-start;

            margin: 0 8% 0 0;
            max-width: 225px;
            width: 46%;

            &:last-of-type{
                margin: 0;
            }

            h2 {
                margin: bsu(0.25) 0 bsu(0.25) 10%;
                width: 60%;
            }

            svg {
                color: $hub;
                fill: currentColor;
                height: auto;
                margin: 0 0 bsu(0.25) 0;
                max-width: 70px;
                width: 30%;
            }

            p,
            a.button {
                width: 100%;
            }
        }
    }
}

.swiper-wrapper.disabled {
    transform: translate3d(0px, 0, 0) !important;
}

@include bp(md) {
    .swiper-container {
        .swiper-wrapper {
            margin-bottom: 40px;
            //padding-bottom: 30px;
        }
    }

    .step-slider-widget {
        .slide-inner-wrapper {
            h2 {
                font-size: 18px;
            }

            div {
                h2 {
                    width: 75%;
                }

                svg {
                    width: 15%;
                }
            }
        }
    }
}

@include bp(lg) {
    .swiper-pagination-bullets {
        .swiper-pagination-bullet {
            height: 15px;
            margin: 0 10px 0 0;
            width: 15px;
        }
    }
}

//mobile table
.sticky-relative {
    margin: 0 0 30px 0;
    padding: 0 0 30px 0;
    position: relative;
}

.sticky-positioning {
    border-bottom: 1px solid $pistachio;
    overflow: hidden;
    position: relative;
    width: 100%;

    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
}

.mobile-table-wrapper {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    position: relative;
    height: 100%;

    &.sticky {
        width: 100%;

        .slider-header,
        .slider-header.before-swiper,
        .slider-header.after-swiper {
            position: sticky;
            //position: -webkit-sticky;
            height: auto;
            top: 0;
            z-index: 11;

            .grid-item {
                background: $hub;
                color: $white;
                font-weight: 600;
                word-break: break-all;
                //max-height: 50px;
            }
        }

        .slider-header.before-swiper,
        .slider-header.after-swiper {
            .grid-item {
                background: $pistachio;
            }
        }
    }

    .swiper-container.mobile-table-header,
    [class^="swiper-container mobile-table-header"],
    [class*="swiper-container mobile-table-header"],
    .swiper-container.mobile-table,
    [class^="swiper-container mobile-table"],
    [class*="swiper-container mobile-table"] {
        margin: 0;
        padding: 0;
        width: 70% !important;
    }

    .swiper-wrapper {
        margin: 0;
    }

    .swiper-slide {
        .grid-item {
            background: $white;
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: center;
            padding: bsu(0.5);
            word-break: break-all;

            &:nth-of-type(2n+2) {
                background: $concrete;
            }

            svg {
                margin: 0 10% 0 0;
                max-width: 50px;
                width: 55%;
            }

            div {
                width: 35%;
            }
        }

        &.before-swiper,
        &.after-swiper {
            width: 30%;
    
            .grid-item {
                background: $hub-lightest;
    
                &:nth-of-type(2n+2) {
                    background: $hub-light;
                }

                a {
                    color: $dark-pistachio;
                    display: block;
                    text-decoration: underline;

                    &:hover {
                        color: $marigold;
                    }
                }
            }
        }
    }
}

@include bp(md) {
    .mobile-table-wrapper {
        .swiper-container.mobile-table-header,
        [class^="swiper-container mobile-table-header"],
        [class*="swiper-container mobile-table-header"],
        .swiper-container.mobile-table,
        [class^="swiper-container mobile-table"],
        [class*="swiper-container mobile-table"] {
            width: 80% !important;
        }
    
        .swiper-slide {
            &.before-swiper,
            &.after-swiper {
                width: 20%;
            }
        }
    }
}

@include bp(lg) {
    .sticky-relative {
        //margin: 0 0 30px 0;
        padding: 0 0 15px 0;
        //position: relative;
    }

    .mobile-table-wrapper {
        &.sticky {
            max-height: 700px;
        }

        .swiper-container.mobile-table-header,
        [class^="swiper-container mobile-table-header"],
        [class*="swiper-container mobile-table-header"],
        .swiper-container.mobile-table,
        [class^="swiper-container mobile-table"],
        [class*="swiper-container mobile-table"] {
            width: 85% !important;
        }
    
        .swiper-slide {
            &.before-swiper,
            &.after-swiper {
                width: 15%;
            }
        }
    }
 }

//step-swiper
.swiper-container {
    &.step-swiper,
    &.step-swiper2,
    &.step-swiper3 {
        .swiper-slide {
            background: $black-haze;
            box-shadow: inset 0 0 20px rgba($gray-chateau, 0.5);
        }
    }
}

.step-swiper,
.step-swiper2 {
    .form-flex {
        // background: $black-haze;
        // box-shadow: inset 0 0 20px rgba($gray-chateau, 0.5);
        //margin: 0 0 bsu(1) 0;
        padding: bsu(1);
    }
}

.step-swiper3 {
    .swiper-slide {
        // background: $black-haze;
        // box-shadow: inset 0 0 20px rgba($gray-chateau, 0.5);
        margin-bottom: bsu(1);
        padding-bottom: bsu(1);
        padding-top: bsu(1);

        h1,
        .form-flex {
            margin-left: bsu(1);
            margin-right: bsu(1);
        }
    }
}

@include bp(md) {

    // .swiper-container {
    //     &.step-swiper {
    //         background: transparent;
    //         box-shadow: none;

    //         .form-flex {
    //             background: $black-haze;
    //             box-shadow: inset 0 0 20px rgba($gray-chateau, 0.5);
    //         }
    //     }
    // }

    .step-swiper2 {
        .form-flex {
            background: transparent;
            box-shadow: none;
            margin: 0 0 0 0;
            padding: 0 bsu(1);

            &:first-of-type {
                padding: bsu(1) bsu(1) 0 bsu(1);
            }
        }

        .swiper-wrapper {
            // background: $black-haze;
            // box-shadow: inset 0 0 20px rgba($gray-chateau, 0.5);
        }
    }

    .swiper-container.step-swiper3 {
        background: $black-haze;
        box-shadow: inset 0 0 20px rgba($gray-chateau, 0.5);
        padding: bsu(1) bsu(1) 30px bsu(1);

        .swiper-slide {
            background: transparent;
            box-shadow: none;
            margin-bottom: 0;
            padding-bottom: 0;
            padding-top: 0;
    
            h1,
            .form-flex {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}

//pager generic
.pager {
    padding: bsu(1) 0 0 0;

    ul {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;

        margin: 0 auto 40px auto;
        text-align: center;

        li {
            color: $silver;
            cursor: pointer;
            display: block;
            font-size: 16px;
            font-weight: bold;
            list-style: none;
            margin: 0 5px;
            position: relative;
            text-align: center;
            text-decoration: underline;
            text-transform: uppercase;
            transition: all $base-duration $base-timing;

            &:first-of-type,
            &:last-of-type {
            margin: 0;
            padding: 3px 0 0 0;
            text-decoration: none;
            width: 30px;
            }

            span {
            position: relative;

                &:before {
                    font-size: 24px;
                    font-weight: bold;
                    position: relative;
                    text-decoration: none;
                    top: 2px;
                }
            }

            &:hover {
                color: $sea-nymph;
            }

            &.active {
                color: $sea-nymph;
            }

            &.disabled {
                a {
                    cursor: not-allowed;
                    opacity: 0.6;
                    pointer-events: none;
                }
            }

        }
    }
}

