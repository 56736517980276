.k-header>.k-header-column-menu {
    display: inline-block;
    float: none;
    margin: 0;
    padding: 0;
    vertical-align: top;

}

.k-popup {
    box-shadow: none;
    color: $ship-gray;
}

.k-filter-menu {
    border-radius: 0;
    max-width: 440px;
}

.k-colorpicker {
    .k-picker-wrap.k-state-default>.k-select {
        background: $white;
    }
}

.k-check {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: top;
    justify-content: flex-start;

    input[type="checkbox"] {
        margin: 0 bsu(0.5) 0 0;
    }
}

.k-widget.k-datepicker.k-header {
    background-color: transparent !important;
}

.k-i-calendar:before {
    color: $white;
}

.k-block, 
.k-draghandle, 
.k-grouping-header, 
.k-header, 
.k-treemap-tile, 
html .km-pane-wrapper .k-header {
    background-color: $pistachio;

    &.k-datepicker,
    &.k-datetimepicker {
        background-color: transparent;

        .k-picker-wrap.k-state-default {
            border: none;
            padding: 0;

            input {
                border: $base-border;
                border-radius: 5px;
            }

            &.k-state-hover {
                border: none;

                input {
                    border: 2px solid $pistachio;
                }
            }
        }

        .k-select {
            border: none;
        }
    }

    // &.k-datetimepicker {
    //     border: 1px solid $iron;
    //     background-color: transparent;
    //     border-radius: 5px;

    //     .k-picker-wrap.k-state-default {
    //         border: none;

    //         &.k-state-hover {
    //             input {
    //                 padding: bsu(0.45) bsu(0.5) bsu(0.48) bsu(0.5);
    //             }
    //         }
    //     }

    //     input {
    //         border-radius: 5px 0 0 5px;
    //         line-height: inherit;
    //         padding: bsu(0.45) bsu(0.5) bsu(0.48) bsu(0.5);
    //         text-indent: 0;
    //     }

    //     .k-select {
    //         border: none;
    //     }
    // }

    &.k-grid-toolbar {
        border: none;
        border-color: transparent;
    }
}

.k-event, 
.k-task-complete {
    background: $marigold !important;
    border-color: transparent !important;
}

.k-link {
    .k-icon {
        color: $marigold;
    }
}

.k-event-actions {
    .k-link {
        .k-icon {
            color: $white !important;
        }
    }
}

.k-event-task {
    background: $pistachio !important;
}

.k-button {
    box-shadow: none;

    &:hover {
        box-shadow: none;
    }
}

.k-edit-form-container .k-edit-buttons {
    border-left: none;
    border-right: none;

    &.k-state-default {
        border-left: none;
        border-right: none;
    }
}

.k-i-reload {
    padding: 0 bsu(0.5) 0 0;
}

.k-input,
.k-dropdown-wrap .k-input, 
.k-numeric-wrap .k-input, 
.k-picker-wrap .k-input {
    border-radius: 5px;
}

.k-picker-wrap.k-state-default>.k-select {
    background: $pistachio;
    border-radius: 0 5px 5px 0;
}

.k-list>.k-state-selected.k-state-focused {
    color: $pistachio;
}

.k-header {
    .k-link {
        .k-icon {
            color: $white !important;
        }
    }
}

.k-scheduler-agendaview .k-scheduler-datecolumn, 
.k-scheduler-header {
    color: $white !important;

    .k-link {
        color: $white !important;

        &:link {
            color: $white !important;
        }
    }

    .k-header {
        color: $white !important;

        .k-link {
            color: $white !important;
        }
    }
}

.k-scheduler-header,
.k-nav-day,
.k-scheduler-header .k-nav-day {
    color: $ship-gray !important;
}

.k-autocomplete, 
.k-block, 
.k-calendar th, 
.k-content, 
.k-dropdown-wrap, 
.k-dropzone-active, 
.k-editable-area, 
.k-filter-row>th, 
.k-footer-template td, 
.k-group, 
.k-group-footer td, 
.k-grouping-header, 
.k-grouping-header 
.k-group-indicator, 
.k-input, 
.k-link, 
.k-panel>.k-item>.k-link, 
.k-panelbar .k-content, 
.k-panelbar .k-panel, 
.k-panelbar>.k-item>.k-link, 
.k-popup.k-align .k-list .k-item:last-child, 
.k-separator, .k-slider-track, 
.k-splitbar, 
.k-state-default, 
.k-state-default .k-select, 
.k-state-disabled, 
.k-textbox, 
.k-textbox>input, 
.k-tiles, 
.k-tooltip, 
.k-treemap-tile, 
.k-upload-files {
    border-color: $iron;
    border-width: 1px;
    outline: none;

    &:focus,
    &:hover {
        border-color: $pistachio;
        border-width: 1px;
        box-shadow: none;
        outline: none;
    }
}

div[name=recurrenceRule]>.k-dropdown {
    width: auto;
}

.k-reset {
    li.k-button {
        padding: bsu(0.25) bsu(1) bsu(0.5) bsu(0.25);
        text-align: left;
        width: 100%;
        word-break: break-all;

        .k-scheduler-mark {
            display: none;
        }
    }
}

.k-dropdown {
    &.k-header {
        background: transparent;
        background-color: $white !important;
        border: none;
    }
}

input.k-textbox,
.k-textbox {
    background: transparent;
    border-radius: 5px;
    line-height: inherit;
    padding: bsu(0.45) bsu(0.5) bsu(0.48) bsu(0.5);
    text-indent: 0;
}

.k-filebrowser .k-search-wrap {
    padding: 0;
}

.k-search-wrap {
    input.k-input {
        background: $pistachio;
        border: none;
        color: $white;

        &::placeholder {
            color: $white;
        }
    }
        

    a.k-icon {
        color: $white;
    }

    &:hover {
        input.k-input {
            background: $marigold;
            color: $white;
        }

        a.k-icon {
            color: $white;
        }
    }
}

.k-filebrowser .k-search-wrap .k-search {
    right: 10px;
    margin-top: -15px;
}

.k-autocomplete.k-state-hover, 
.k-dropdown-wrap.k-state-hover, 
.k-numeric-wrap.k-state-hover, 
.k-overflow-anchor:hover, 
.k-picker-wrap.k-state-hover, 
.k-textbox:hover {
    box-shadow: none;
}

.daily-logs {
    .k-grid tr.k-state-selected,
    .k-grid tr:nth-child(odd).k-state-selected {
        background-color: transparent !important;
        background: $concrete !important;

        &:hover {
            background: $concrete !important;

            td {
                background: transparent !important;
            }
        }

        td {
            background: transparent !important;
        }
    }
}

.k-state-selected {
    color: $ship-gray;
}

.k-edit-form-container .k-primary, 
.k-popup .k-primary {
    background: $orange;
    color: $white;
}

.k-edit-form-container {
    p {
        padding: bsu(0.5);
        text-align: left;
    }

    .k-button {
        float: none;
        margin: 0 bsu(0.25) bsu(0.25) 0;

        &:last-of-type {
            margin: 0 0 bsu(0.25) 0;
        }
    }
}

.k-flatcolorpicker.k-group, 
.k-group, 
.k-menu, 
.k-menu .k-group, 
.k-popup.k-widget.k-context-menu {
    color: $ship-gray;
}

.k-scheduler,
.k-scheduler-calendar {
    .k-state-default:hover {
        border-width: 0;
    }

    .k-scheduler-toolbar>ul.k-scheduler-views {
        z-index: 10 !important;
    }

    &.k-floatwrap,
    .k-scheduler-times {
        height: inherit !important;
    }

    .k-header {
        background-color: $pistachio;
        border-color: $iron;

        li,
        .k-link {
            background-color: $pistachio;
            border-color: $pistachio;
            transition: all $base-duration $base-timing;
        }
    }

    .k-scheduler-header,
    .k-scheduler-table {
        th,
        td {
            border-color: $iron;
            //height: auto !important;
            padding: bsu(0.5) !important;
        }
    }

    .k-scheduler-layout {
        border: none;
        border-bottom: 1px solid $iron;
        border-left: 1px solid $iron;
        border-right: 1px solid $iron;

        &.k-scheduler-agendaview {
            border: none;
        }
    }

    .k-scheduler-times {
        border-color: $iron;

        th,
        tr {
            border-color: $iron;
        }
    }

    .k-scheduler-header-wrap,
    .k-month {
        border-color: $iron;
    }

    .k-dropzone .k-upload-status, 
    .k-dropzone em, 
    .k-other-month, 
    .k-other-month .k-link, 
    .k-slider .k-draghandle, 
    .k-state-disabled, 
    .k-state-disabled .k-link, 
    .k-tile-empty strong {
        color: $ship-gray;
    }

    .k-scheduler-toolbar .k-state-selected {
        background-color: $pistachio;
        border-color: $pistachio;
    }

    .k-gantt .k-treelist .k-alt.k-state-selected, 
    .k-gantt .k-treelist .k-alt.k-state-selected>td, 
    .k-gantt .k-treelist .k-state-selected, 
    .k-gantt .k-treelist .k-state-selected td, 
    .k-grid td.k-state-selected, 
    .k-grid td.k-state-selected.k-state-focused, 
    .k-grid tr.k-state-selected, 
    .k-listview>.k-state-selected, 
    .k-marquee-color, 
    .k-today.k-state-selected, 
    .k-scheduler-layout .k-state-selected, 
    .k-state-selected.k-line {
        background-color: $hub;
    }

    .k-gantt .k-treelist .k-alt.k-state-selected, 
    .k-gantt .k-treelist .k-alt.k-state-selected>td, 
    .k-gantt .k-treelist .k-state-selected, 
    .k-gantt .k-treelist .k-state-selected td, 
    .k-grid td.k-state-selected, 
    .k-grid td.k-state-selected.k-state-focused, 
    .k-grid tr.k-state-selected, 
    .k-listview>.k-state-selected, 
    .k-marquee-color, 
    .k-today.k-state-selected, 
    .k-scheduler-layout .k-state-selected, 
    .k-state-selected.k-line {
        background-color: $hub;
    }

    .k-gantt .k-gantt-toolbar .k-gantt-views li.k-state-selected.k-state-hover, 
    .k-gantt-toolbar>.k-gantt-views>li.k-state-selected, 
    .k-scheduler-toolbar .k-scheduler-views li.k-state-selected.k-state-hover, 
    .k-scheduler-toolbar>.k-scheduler-views>li.k-state-selected {
        border-bottom-color: transparent;
    }

    //weekly
    .k-scheduler-weekview {
        tr {
            &:first-of-type {
                background: $concrete;
            }
        }
    }

    .k-scheduler-layout.k-scheduler-weekview.k-scrollbar-h {
        tr {
            &:nth-of-type(2) {
                table {
                    tr.k-middle-row,
                    tr {
                        height: 41px !important;
                    }
                }
            }
        }

        th, 
        td {
            display: table-cell;
            text-align: center;
            width: auto;

            &:first-of-type {
                width: 70px;
            }

            table {
                // tr.k-middle-row,
                // tr {
                //     height: 41px !important;
                // }

                th,
                td {
                    width: 100%;

                    &:first-of-type {
                        width: 100%;
                    }
                }
            }

            table {
                &.k-scheduler-table.k-scheduler-header-all-day {
                    tr.k-middle-row,
                    tr {
                        height: auto !important;
                    }
                }
            }
        }
    }

    //daily
    .k-scheduler-layout.k-scheduler-dayview {
        tr {
            td {
                width: 80%;

                &:first-of-type {
                    width: 20%;
                }
            }
        }
    }

    //agenda view
    .k-scheduler-layout.k-scheduler-agenda {
        margin-bottom: bsu(2);

        .k-scheduler-table {
            border-left: 1px solid $iron;
            border-right: 1px solid $iron;
        }
    }

    //timeline view
    table.k-scheduler-layout.k-scheduler-timelineview {
        border: transparent !important;

        tr {
            th,
            td {
                &:first-of-type {
                    display: none !important;
                }
            }
        }

        .k-scheduler-table {
            border: 1px solid $iron !important;
            border-top: transparent !important;
            max-width: 100% !important;

            tr {
                &:nth-of-type(2) {
                    width: 5.88%;
                }

                th,
                td {
                    &:first-of-type {
                        display: none;
                    }
                }
            }
        }

        .k-scheduler-header-wrap {
            overflow: hidden !important;
        }
    }
}

@include bp(md) {
    .k-scheduler,
    .k-scheduler-calendar {
        //daily
        .k-scheduler-layout.k-scheduler-dayview {
            tr {
                td {
                    width: 85%;

                    &:first-of-type {
                        width: 15%;
                    }
                }
            }
        }
    }
}

@include bp(md) {
    .k-scheduler,
    .k-scheduler-calendar {
        //daily
        .k-scheduler-layout.k-scheduler-dayview {
            tr {
                td {
                    width: 90%;

                    &:first-of-type {
                        width: 10%;
                    }
                }
            }
        }
    }
}

.k-scheduler-times th,
.k-scheduler-times td {
    text-align: center;
}

.k-scheduler-layout.k-scheduler-dayview.k-scrollbar-h {
    .k-scheduler-times th,
    .k-scheduler-times td {
        text-align: left;
    }
}

.k-scheduler-table.k-scheduler-header-all-day tr {
    height: 41px !important;
}

.k-safari .k-scheduler-toolbar .k-state-selected,
.k-safari .k-scheduler-toolbar>ul.k-scheduler-views.k-state-expanded {
    background-color: $pistachio;
}

.k-calendar-container.k-popup {
    box-shadow: none;
}

.k-widget.k-calendar {
    border: 1px solid $iron;
    
    .k-link {
        color: $ship-gray;
    }

    .k-header {
        background-color: $hub;
        border-color: $iron;

        .k-link {
            color: $white;
        }
    }

    .k-today {
        .klink {
            color: $white;
        }
    }

    td.k-state-selected {
        background-color: $pistachio;
        box-shadow: none;
        color: $white;

        &.k-state-focused {
            border-left: none;
            box-shadow: none;
        }
    }

    .k-today {
        background-color: $marigold;
    }

    .k-footer {
        border-color: $iron;

        .k-nav-today {
            color: $marigold;
        }
    }
}

.k-scheduler-toolbar .k-nav-current {
    margin: 0 bsu(0.5);
}

.k-gantt-toolbar .k-button, 
.k-scheduler .k-scheduler-toolbar .k-state-selected, 
.k-scheduler .k-scheduler-toolbar ul li.k-state-hover {
    background-color: transparent;
    border-color: transparent;
}

.k-gantt .k-gantt-toolbar .k-button, 
.k-gantt .k-gantt-toolbar .k-gantt-views li, 
.k-gantt .k-gantt-toolbar .k-gantt-views li .k-link, 
.k-scheduler .k-header .k-button, 
.k-scheduler .k-scheduler-toolbar .k-scheduler-views li, 
.k-scheduler .k-scheduler-toolbar .k-scheduler-views li .k-link, 
.km-pane-wrapper .k-header .k-button {
    background-color: $pistachio;
    border-color: transparent;
    color: $white;
}

.k-gantt .k-gantt-toolbar .k-button:hover, 
.k-gantt .k-gantt-toolbar .k-gantt-views li.k-state-hover, 
.k-gantt .k-gantt-toolbar .k-gantt-views li.k-state-hover .k-link, 
.k-scheduler .k-header .k-button:hover, 
.k-scheduler .k-scheduler-toolbar .k-scheduler-views li.k-state-hover, 
.k-scheduler .k-scheduler-toolbar .k-scheduler-views li.k-state-hover .k-link, 
.km-pane-wrapper .k-header .k-button:hover {
    background-color: $marigold;
    border-color: transparent;
}

table:not(.k-scheduler-dayview) 
.k-scheduler-header-wrap .k-today {
    color: $pistachio;
}

//scheduler recurring
.k-scheduler-edit-form {
    .k-picker-wrap.k-state-default {
        padding: 0;
    }

    .k-dropdown-wrap {
        padding: 0 35px 0 0;

        .k-input {
            height: 44px;
            padding: 5px 0 0 0;
        }

        .k-icon.k-i-arrow-60-down {

            &:before {
                height: 24px;
                width: 24px;
            }
        }
    }

    .k-recur-view {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;

        margin: 0 5%;

        .k-header.k-datepicker .k-picker-wrap.k-state-active,
        .k-header.k-datepicker .k-picker-wrap.k-state-default,
        .k-header.k-datepicker .k-picker-wrap.k-state-disabled,
        .k-header.k-datepicker .k-picker-wrap.k-state-focused {
            background: transparent;
            border: none;
            border-radius: 5px;
            height: 44px;

            input {
                border: $base-border;
                border-radius: 5px 0 0 5px;
                border-right: none;
                height: 44px;
            }
        }

        .k-dropdown-wrap.k-state-active,
        .k-dropdown-wrap.k-state-default,
        .k-dropdown-wrap.k-state-disabled,
        .k-dropdown-wrap.k-state-focused {
            height: 44px;
            padding-top: 0;

            .k-input {
                height: 44px;
                padding-top: 5px;
            }

            .k-i-arrow-60-down:before {
                top: 0;
            }
        }

        .k-picker-wrap.k-state-active>.k-select,
        .k-picker-wrap.k-state-default>.k-select,
        .k-picker-wrap.k-state-disabled>.k-select,
        .k-picker-wrap.k-state-focused>.k-select {
            background: $pistachio;
            border-radius: 0 5px 5px 0;
            height: 44px;
        }

        .k-numeric-wrap,
        .k-numeric-wrap.k-state-active,
        .k-numeric-wrap.k-state-default,
        .k-numeric-wrap.k-state-disabled,
        .k-numeric-wrap.k-state-focused {
            border-radius: 5px;
            margin: 0 5px 0 0;
            padding: 0;
            height: 46px;

            .k-input {
                height: 44px !important;
            }

            .k-select {
                border: none !important;
                border-radius: 0 5px 5px 0;
                height: 44px !important;
            }

            .k-link {
                height: 20px;

                .k-icon {
                    color: $jumbo !important;
                    font-size: 14px !important;
                }
            }

            .k-i-arrow-60-down:before {
                background: none !important;
                content: "\e006";
                top: 0;
            }
        }

        .k-numeric-wrap,
        .k-numeric-wrap.k-state-active,
        .k-numeric-wrap.k-state-disabled,
        .k-numeric-wrap.k-state-focused {
            height: 44px;
        }

        .k-numeric-wrap.k-state-disabled {
            height: 44px !important;
        }

        .k-numeric-wrap.k-state-focused>.k-select {
            background: transparent !important;
        }

        .k-edit-label {
            width: 26%;

            label {
                padding: 10px 0 12px 0;
                width: 100%;
            }
        }

        .k-edit-field {
            margin: 0 0 10px 0;
            width: 74%;

            ul.k-reset {
                li {
                    display: flex;
                    flex-direction: row;
                    flex-flow: row wrap;
                    align-items: flex-start;
                    justify-content: flex-start;
                    margin: 0 0 5px 0;
                    width: 100%;

                    label {
                        display: flex;
                        flex-direction: row;
                        flex-flow: row wrap;
                        align-items: center;
                        justify-content: flex-start;
                        margin: 0;
                        width: 27%;
                    }

                    input {
                        float: left;
                        margin: 0 10px 0 0;
                    }

                    span.k-widget.k-recur-count {
                        width: 30%;
                    }

                    span.k-widget.k-datepicker.k-recur-util {
                        margin: 0;
                        width: 72%;
                    }
                }
            }
        }
    }
}


//fix overflows
.k-scheduler-header,
.k-scheduler-header-wrap,
.k-scheduler-content {
    overflow: visible!important;
}

.k-scheduler-content {
    height: auto !important;
}

//wysiwyg
.k-tool-group {
    .k-header {
        background: transparent !important;

        .k-picker-wrap.k-state-default {
            background: $white;
            border: $base-border;
            color: $ship-gray;
            height: 44px;
            margin: 0;
        }

        .k-i-arrow-60-down:before {
            background: $white url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat right center;
            background-size: 24px;
            width: 24px;
        }

        .k-picker-wrap.k-state-default>.k-select {
            border: none;
        }
    }
}


//editor
table.k-editor {
    border-color: $iron;
    height: 450px !important;

    tr {
        display: table-row;

        td {
            display: table-cell;
            padding: 0;
        }
    }
}

.k-editor {
    .k-header {
        border: none;
    }
}

.k-autocomplete .k-input, 
.k-dropdown-wrap .k-input, 
.k-textbox>input {
    border: none;
}

.k-dropdown-wrap .k-input {
    border-radius: 0;
    margin: 0;
    padding: 0;
}

.k-editor-toolbar>li {
    margin-bottom: 0;

    .k-dropdown-wrap {
        margin: 0;
    }
}

.k-editor-toolbar .k-dropdown-wrap.k-state-default, 
.k-toolbar .k-dropdown-wrap.k-state-default {
    background-color: $white;
    border: 1px solid $iron;
    border-radius: $base-border-radius;
    color: $jumbo;  
    padding: 2px 3px 3px 3px;
    margin: 0;
}

.editorToolbarWindow.k-header, 
.k-editor.k-header, 
.k-filebrowser .k-header {
    background-color: $iron;
    color: $ship-gray;
}

.k-filebrowser {
    .k-header {
        background-color: $pistachio;
        border: none;
        color: $white;
        padding: bsu(0.5);
    }

    .k-tiles-arrange {
        label {
            margin: 0;
        }

        .k-dropdown {
            width: auto;
        }
    }

    .k-dropdown-wrap.k-state-default,
    .k-dropdown-wrap.k-state-hover {
        background: $white;
        border: none;
        color: $ship-gray;
    }

    .k-dropdown-wrap .k-input {
        border: none;
        margin: 0;
    }

    .k-toolbar-wrap {
        .k-button {
            &.k-button-icon {
                height: auto;
            }

            &:hover {
                background: $marigold;
            }
        }
    }
}

.k-panelbar .k-tabstrip-items 
.k-state-active, 
.k-tabstrip-items .k-state-active {
    background-color: $pistachio;
    border-bottom-color: $hub;
}

.k-autocomplete, 
.k-draghandle, 
.k-dropdown-wrap, 
.k-grouping-header, 
.k-numeric-wrap, 
.k-panelbar .k-tabstrip-items .k-item, 
.k-picker-wrap, 
.k-progressbar, 
.k-state-highlight, 
.k-tabstrip-items .k-item, 
.k-textbox, 
.km-pane-wrapper>.km-pane>.km-view>.km-content {
    background-color: $pistachio;

    &.k-state-hover {
        background-color: $marigold;
    }
}


//supplier protal report
.report-preview {
    .k-grid {
        td {
            border: none !important;
            border-color: transparent !important;
            border-width: 0 !important;

            &:hover {
                border: none !important;
                border-color: transparent !important;
                border-width: 0 !important;
            }

            &.k-state-selected {
                border: none !important;
                border-color: transparent !important;
                border-width: 0 !important;

                &:hover {
                    border: none !important;
                    border-color: transparent !important;
                    border-width: 0 !important;
                }
            }
        }
    }
}

//checkboxes
.k-checkbox+.k-checkbox-label:before, 
.k-checkbox+.k-checkbox-label:hover:before, 
.k-checkbox:hover+.k-checkbox-label:before {
    background-color: $white;
    border-color: $scarpa-flow;
}

.k-checkbox:checked+.k-checkbox-label:before, 
.k-checkbox:checked+.k-checkbox-label:hover:before, 
.k-checkbox:checked:hover+.k-checkbox-label:before {
    background-color: $marigold;
    border-color: $scarpa-flow;
}

.k-checkbox:checked+.k-checkbox-label:before {
    background-color: $marigold;
    border-color: $scarpa-flow;
}

//maps

.k-layer {    
    img {
        max-width: none !important;
        visibility: visible !important;
    }
}

.k-map .km-scroll-wrapper {
    height: 100%;
    width: 100%;
}

.k-scheduler-toolbar .k-nav-current .k-sm-date-format {
    color: $white;
}

//scheduler mobile
.km-pane-wrapper {
    height: 550px !important;
    position: relative !important;

    .k-mobile-list .k-widget, 
    .km-pane .k-mobile-list input:not([type=checkbox]):not([type=radio]), 
    .km-pane .k-mobile-list select:not([multiple]), 
    .km-pane .k-mobile-list textarea {
        appearance: none;
        background-color: $white;
        border: $base-border;
        border-radius: $base-border-radius;
        box-shadow: none;
        box-sizing: border-box;
        color: $jumbo;  
        //margin-bottom: bsu(0.75);
        padding: bsu(0.45) bsu(0.5) bsu(0.48) bsu(0.5);
        transition: border-color $base-duration $base-timing;
        width: 100%;

        &:hover {
            border-color: $pistachio;
        }

        &:focus {
            border-color: $pistachio;
            box-shadow: none;
            outline: none;
        }
    }

    .km-pane-wrapper .km-pane * {
        border: none;
    }

    .km-pane-wrapper .k-mobile-list .k-edit-field {
        box-shadow: none;;
    }

    .km-widget .k-button {
        color: $white !important;
    }

    .k-i-arrow-60-down:before {
        background: transparent;
        content: '\e006';
    }

    .k-link:link, .k-link:visited {
        color: $dark-pistachio;
    }

    .k-nav-today,
    .k-state-selected {
        .k-link:link, 
        .k-link:visited {
            color: $white;
        }
    }

    .k-header.k-datetimepicker .k-select {
        height: 44px;
        top: 8px;
    }

    .k-scheduler-footer .k-link, .k-scheduler-toolbar .k-link {
        padding: 0;
    }

    .k-mobile-list .k-numerictextbox .k-select {
        border: none;
    }    

    .k-edit-buttons {
        background-color: transparent;
    }

    .km-content .k-button.k-scheduler-delete {
        background: $marigold;
    } 
}

.k-panelbar>li.k-state-default>.k-link.k-state-selected, .k-panelbar>li>.k-state-selected, .k-state-selected>.k-link {
    color: $white;
}

.km-pane-wrapper .k-mobile-list .k-edit-field.k-scheduler-toolbar .k-scheduler-navigation {
    margin-bottom: 10px;
}

.km-pane-wrapper .k-mobile-list .k-edit-field.k-scheduler-toolbar {
    border: none;
}

.km-pane-wrapper .k-mobile-list .k-edit-field {
    box-shadow: none;
}

.km-content.km-stretched-view {
    overflow: visible;
}

.k-scheduler-header {
    th {
        text-align: center;
    }
}

.km-content, .km-popover-root .km-content .km-widget, .km-widget {
    background: transparent !important;

    .k-button {
        background: $hub;
        //color: $ship-gray !important;
    }
}

//schedular and other modals, mobile
.k-scheduler-edit-form .k-edit-form-container, 
.k-scheduler-timezones .k-edit-form-container {
    width: auto;
}

.k-scheduler-edit-form .k-edit-label {
    word-break: break-word;
}

.k-scheduler-edit-form .k-edit-label {
    float: none;
    margin: 0;
    padding: 0;
    width: 100%;

    label {
        margin: 0 auto;
        padding: 0 0 5px 0;
        width: 90%;
    }
}

.k-scheduler-edit-form .k-edit-field {
    float: none;
    margin: 0 auto 10px auto;
    padding: 0 0 0 0;
    width: 90%;
}

div.k-window.k-widget {
    //margin: 0 5%;
    width: 90%;
}

@include bp(md) {
    div.k-window.k-widget {
        margin: 0;
        width: auto;
    }

    .k-scheduler-edit-form .k-edit-form-container, 
    .k-scheduler-timezones .k-edit-form-container {
        width: 520px;
    }
}

//blazor editor
body.new-design {
    .k-i-arrow-60-down {
        background: $white url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat 0 5px;
        background-size: 24px;

        &:before {
            display: none;
        }
    }

    .k-list-container .k-button.k-state-selected,
    .k-textbox::selection,
    .k-textbox .k-input::selection,
    .k-tooltip-primary,
    .k-fab-primary,
    .k-slider .k-slider-selection,
    .k-slider .k-draghandle,
    .k-slider .k-draghandle:hover,
    .k-treeview .k-in.k-state-selected,
    .k-text-selection ::selection, 
    .k-block ::selection, 
    .k-panel ::selection, 
    .k-pane-wrapper ::selection, 
    .k-view ::selection, 
    .k-progressbar ::selection, 
    .k-numerictextbox ::selection, 
    .k-flatcolorpicker ::selection, 
    .k-combobox ::selection, 
    .k-datepicker ::selection, 
    .k-timepicker ::selection, 
    .k-datetimepicker ::selection, 
    .k-dateinput ::selection, 
    .k-dropdown ::selection, 
    .k-dropdowntree ::selection, 
    .k-multiselect ::selection, 
    .k-maskedtextbox ::selection, 
    .k-rating ::selection, 
    .k-window ::selection, 
    .k-drawer ::selection, 
    .k-notification ::selection, 
    .k-breadcrumb ::selection, 
    .k-panelbar ::selection, 
    .k-card ::selection, 
    .k-splitter ::selection, 
    .k-spreadsheet ::selection, 
    .k-taskboard ::selection, 
    .k-scheduler ::selection, 
    .k-chat ::selection, 
    .k-mediaplayer ::selection, 
    .k-pdf-viewer ::selection, 
    kendo-scrollview.k-scrollview-wrap ::selection, 
    kendo-scrollview.k-scrollview ::selection, 
    div.k-scrollview ::selection, 
    .k-chart ::selection, 
    .k-sparkline ::selection, 
    .k-stockchart ::selection,
    .k-button.k-state-selected,
    .k-button-secondary.k-state-selected,
    .k-button-primary, .k-button.k-primary,
    .k-button-primary:hover, 
    .k-button.k-primary:hover, 
    .k-button-primary.k-state-hover, 
    .k-state-hover.k-button.k-primary,
    .k-button-outline.k-primary:active, 
    .k-primary.k-button.k-outline:active, 
    .k-button-outline.k-primary.k-state-active, 
    .k-primary.k-state-active.k-button.k-outline,
    .k-button-outline.k-primary.k-state-selected, 
    .k-primary.k-state-selected.k-button.k-outline,
    .k-list .k-item.k-state-selected,
    .k-list-optionlabel.k-state-selected {
        background-color: $mercury;
        border-color: transparent;
        color: $emperor;
    }

    // .k-bg-primary {
    //     background-color: $mercury !important;
    // }

    .k-dropdown .k-dropdown-wrap:hover, 
    .k-dropdown .k-dropdown-wrap.k-state-hover, 
    .k-dropdowntree .k-dropdown-wrap:hover, 
    .k-dropdowntree .k-dropdown-wrap.k-state-hover {
        background-color: $white;
    }

    .k-treeview {
        color: $emperor;
    }
     
    .k-treeview .k-in:hover,
    .k-treeview .k-in.k-state-hover {
        color: $emperor;
        background-color: $white;
    }
     
    .k-treeview .k-in.k-state-selected {
        color: $emperor;
        background-color: $mercury;
    }
     
    .k-treeview .k-in.k-state-focused {
        box-shadow: none;
    }
     
    .k-treeview .k-treeview-load-more-button {
        color: $plantation;
        background-color: transparent;
    }
     
    .k-treeview .k-treeview-load-more-button:hover,
    .k-treeview .k-treeview-load-more-button.k-state-hover {
        color: $plantation;
        background-color: transparent;
    }
     
    .k-treeview .k-treeview-load-more-button:focus,
    .k-treeview .k-treeview-load-more-button.k-state-focused {
        color: $plantation;
        background-color: transparent;
        box-shadow: none;
    }

    .k-text-selection ::selection, 
    .k-block ::selection, 
    .k-panel ::selection, 
    .k-pane-wrapper ::selection, 
    .k-view ::selection, 
    .k-progressbar ::selection,
    .k-numerictextbox ::selection, 
    .k-flatcolorpicker ::selection, 
    .k-combobox ::selection, 
    .k-datepicker ::selection, 
    .k-timepicker ::selection, 
    .k-datetimepicker ::selection, 
    .k-dateinput ::selection, 
    .k-dropdown ::selection, 
    .k-dropdowntree ::selection, 
    .k-multiselect ::selection, 
    .k-maskedtextbox ::selection, 
    .k-rating ::selection, 
    .k-window ::selection, 
    .k-drawer ::selection, 
    .k-notification ::selection, 
    .k-breadcrumb ::selection, 
    .k-panelbar ::selection, 
    .k-card ::selection, 
    .k-splitter ::selection, 
    .k-spreadsheet ::selection, 
    .k-taskboard ::selection, 
    .k-scheduler ::selection, 
    .k-chat ::selection, 
    .k-mediaplayer ::selection, 
    .k-pdf-viewer ::selection, 
    kendo-scrollview.k-scrollview-wrap ::selection, 
    kendo-scrollview.k-scrollview ::selection, 
    div.k-scrollview ::selection, 
    .k-chart ::selection, 
    .k-sparkline ::selection, 
    .k-stockchart ::selection {
        background-color: $mercury;
    }

    .k-editor {
        border-color: transparent;
        border-radius: 0 0 6px 6px;
        margin-bottom: 50px;
    }

    .k-toolbar {
        background-color: $mercury;
        border-color: $silver3;
        border-width: 1px;

        button,
        button.k-button:nth-of-type(2) {
            background: transparent;
            border-color: transparent;
            border-radius: 0;
            padding: 4px;

            &:hover,
            &:focus {
                background: $silver3;
                border-color: transparent;
            }
        }

        .k-dropdown.k-header {
            background-color: transparent !important;
        }
    }

    .k-button:focus, 
    .k-button.k-state-focus, 
    .k-button.k-state-focused, 
    .k-button-secondary:focus, 
    .k-button-secondary.k-state-focus, 
    .k-button-secondary.k-state-focused {
        box-shadow: none;
    }

    .k-multiselect .k-multiselect-wrap:hover, 
    .k-dropdowntree .k-multiselect-wrap:hover, 
    .k-multiselect-wrap.k-state-hover,
    .k-dropdowntree .k-multiselect-wrap.k-state-hover {
        border-color: transparent;
    }

    .k-multiselect.k-state-focused > .k-multiselect-wrap, 
    .k-state-focused.k-dropdowntree > .k-multiselect-wrap {
        box-shadow: none;
    }

    .k-multiselect,
    .k-dropdowntree {
        color: $emperor;
        line-height: 1;

        &.k-header {
            background-color: transparent;
            width: 100% !important;
        }

        .k-multiselect-wrap {
            appearance: none;
            background-color: $white;
            //border: $base-border;
            //border-radius: $base-border-radius; 
            border: 1px solid $silver2;
            color: $emperor;  
            transition: border-color $base-duration $base-timing;

            box-shadow: none;
            box-sizing: border-box;
            color: $jumbo;  
            margin-bottom: 0;
            min-height: 34px;
            padding: 5px 7px 2px 7px;
            transition: border-color $base-duration $base-timing;
            width: 100%;

            &:hover {
                border-color: $silver-chalice;
            }
        
            &::placeholder {
                color: $emperor;
            }

            &:focus {
                border-color: $silver-chalice;
                border: none;
                box-shadow: none;
                outline: none;
            }

            &:before {
                height: 0;
            }
        
            input[type="text"].k-input {
                //removed display: none to re-inable keyboard controls for multiselects
                //display: none;
                //height: 0;
                border: none;
                border-radius: 0;
                height: 23px;
                margin: 0;
                min-width: 50px;
                padding: 0;
                transition: none;
                width: 100%;
            }
        
            .k-reset {
                li.k-button,
                li.k-button:nth-of-type(2) {
                    background: $mercury !important;
                    border: none;
                    padding: 3px 0 0 5px;

                    &:hover,
                    &:focus {
                        background: $mercury;
                        border: none;
                    }

                    .k-icon {
                        &::before {
                            position: absolute;
                            top: -2px;
                        }
                    }
                }
            }
        
            li {
                border-radius: 5px;
                float: left;
                margin: 0;
                line-height: 1.1em;
                position: relative;
        
                &.k-button {
                    font-size: 14px;
                    text-transform: none;
        
                    &.k-state-hover {
                        background: $marigold !important;
                    }
                }
        
                span {
                    font-weight: normal;
                    margin: 0;
                }
            }
        }
    }
}


.k-spreadsheet-sheets-items .k-state-default .k-link, .k-spreadsheet-tabstrip .k-state-default .k-link {
    color: $black;
}

//blazor filters
.k-popup {
    color: $emperor;
}

body.new-design {

    .telerik-blazor {
        color: $emperor;

        .k-edit-form-container .k-primary, 
        .k-edit-form-container .k-primary:visited, 
        .k-popup .k-primary, 
        .k-popup .k-primary:visited {
            background: $plantation !important;
            color: $white;

            &:visited {
                background: $spring-leaves !important;
                color: $white;

                &:hover,
                &:focus {
                    background: $spring-leaves !important;
                    color: $white;
                }
            }

            &:hover,
            &:focus {
                background: $spring-leaves !important;
                color: $white;
            }
        }

        .k-button, 
        .k-edit-form-container .k-button, 
        .k-edit-form-container .k-primary, 
        .k-filter-menu .k-button, 
        .k-popup .k-button, 
        .k-popup .k-primary {
            background: transparent;
            border: 2px solid $spring-leaves;
            color: $spring-leaves;
            font-weight: bold;
            padding: 11px 41px 11px 41px;

            &:visited {
                background: transparent;
                border: 2px solid $spring-leaves;
            }

            &:hover,
            &:focus {
                background: $ada-grey-5;
                    color: $spring-leaves;
            }
    

            &.k-primary {
                // background: $plantation;
                // color: $white;

                // &:visited {
                //     background: $spring-leaves;
                //     color: $white;
        
                //     &:hover,
                //     &:focus {
                //         background: $spring-leaves;
                //         color: $white;
                //     }
                // }
        
                // &:hover,
                // &:focus {
                //     background: $spring-leaves;
                //     color: $white;
                // }
                background: $spring-leaves;
                border: none;
                color: $white;
                padding: 13px 45px 13px 45px;
    
                &:visited {
                    background: $rio-grande;
                    color: $emperor;
    
                    &:hover,
                    &:focus {
                        background: $plantation;
                        color: $white;
                    }
                }
    
                &:hover,
                &:focus {
                    background: $plantation;
                    color: $white;
                }
            }
        }

        .k-column-list-item, .k-columnmenu-item {
            &:hover, 
            &:focus,
            &:visited, 
            &.k-state-focus {
                background: transparent;
                border: none;
            }
        }

        .k-filter-menu {

        }

        .k-filter-menu .k-button {
            margin: 0;
        }

        .k-dropdown .k-dropdown-wrap, 
        .k-dropdowntree .k-dropdown-wrap {
            background: $white;
            background-image: none;
            border: 1px solid $silver2;
            border-radius: 4px;
            color: $emperor;
            padding: 0 0 0 13px;
            transition: border-color $base-duration $base-timing;
        
            &:hover {
                border-color: $silver-chalice;
            }
        
            &:focus {
                border-color: $silver-chalice;
            }
        
            &::placeholder {
                color: $emperor;
            } 
        }
    }
}

//general log builder
body.new-design {
    .k-tilelayout {
        background-color: transparent;
        padding: 0 !important;
    }

    //card headers
    .k-card-header {
        border: none;
        color: $mine-shaft;
        font-size: 16px;
        font-weight: normal;
        padding: 0;

        strong {
            display: flex;
            justify-content: space-between;
            font-weight: normal;
            width: 100%;
        }

        .heading-container {
            height: 44px;
            padding: 7px 13px 7px 30px;

            h1 {
                display: flex;
                justify-content: space-between;
                font-size: 18px;
                font-weight: 600;
                width: 100%;
            }
        }
    }

    //k-card
    .k-tilelayout {
        .k-card {
            border: none;
            margin-right: 100px;
        }

        .k-card-body {
            font-size: 16px;
            padding: 5px 0 0 0;

            .content-container-white-bg-inner-padding {
                border: 1px solid $sea-nymph-hover;
                border-top: none;
                margin-top: -5px;
            }
        }
    }

    //radios
    .k-radio {
        border: 1px solid $grey;
        // background-color: $white;
        // border-color: $white;
        // color: $rio-grande;
        height: 26px;
        // position: relative;
        width: 26px;
        // z-index: 2;

        &:checked,
        &:focus,
        &.k-checked {
            background-color: $white;
            border-color: $grey;
            color: $rio-grande;

            &:focus,
            &.k-state-focus {
                border-color: $grey;
            }
        }

        &::before {
            height: 19px;
            width: 19px;
        }

        &.k-checked + label:after {
            background: $rio-grande;
            border-radius: 100%;
            content: '';
            display: block;
            height: 16px;
            left: -25px;
            position: absolute;
            top: 4px;
            width: 16px;
        }
    }

    .k-radio-list {
        margin: 0 auto;
        text-align: center;
    }
}

//radios
.k-radio {
    border: 1px solid $grey;
    height: 26px;
    width: 26px;

    &:checked,
    &:focus,
    &.k-checked {
        background-color: $white;
        border-color: $grey;
        color: $rio-grande;

        &:focus,
        &.k-state-focus {
            border-color: $grey;
        }
    }

    &::before {
        height: 19px;
        width: 19px;
    }
}

//checks
.k-checkbox.k-checked, .k-checkbox:checked {
    border-color: $grey;
    color: #fff;
    background-color: $grey;
}

//textEditor
body.new-design {
    #textEditor.k-editor {
        border: 1px solid $sea-nymph-hover;
        border-radius: 6px;

        .k-toolbar {
            background: $ada-grey-4;
            border: none;
            border-bottom: 1px solid $sea-nymph-hover;
            border-radius: 6px 6px 0 0;

            button {
                border: none;
                color: $mine-shaft;
                padding: 0;

                &:hover {
                    background: $ada-grey-3;
                }
            }

        }
    }
}

//timepicker
body.new-design {
    .k-pop-up,
    .k-animation-container > .k-pop-up {
        border-color: $ada-grey-3;
        box-shadow: none;
        color: $mine-shaft;
    }

    .k-datepicker, .k-timepicker, .k-datetimepicker {
        height: 44px;
        width: auto;

        .k-picker-wrap {
            border-color: $ada-grey-3;
            border: $base-border;
            border-radius: 4px;
            color: $mine-shaft;
            font-style: 16px;
            //padding: 12px;

            .k-dateinput {
                .k-input {
                    height: 42px;
                    padding: 12px;
                }
            }
        }
    }

    .k-timepicker .k-select {
        background-color: transparent;
        background-image: none;
        border-color: transparent;
        color: $mine-shaft;
        padding: 0;
    }

    .k-icon.k-i-clock {
        background: $white url('../../Content/img/icons/newdesign-TimePicker.svg') no-repeat 0 0;
        background-size: 24px 24px;
        height: 24px;
        margin-right: 12px;
        width: 24px;

        &::before {
            display: none;
        }
    }

    .telerik-blazor button.k-button.k-time-now {
        background: transparent;
        color: $midgreen-bright;
        font-size: 16px;
        padding: 0;

        &:hover,
        &:active,
        &:visited {
            background: transparent;
            color: $midgreen-bright;
            font-size: 16px;
            padding: 0;
        }
    }

    .k-time-list .k-item {
        &:hover {
            color: $midgreen-bright;
        }
    }
}

.k-colorpicker {
    .k-picker-wrap.k-state-default {
        padding: 0 30px 0 0;
    }
}

.k-link-decrease {
    .k-i-arrow-60-down:before {
        width: 14px;
    }
}

//numeric wrap for table wizard used in food safety sops
.k-editor-dialog.k-editor-table-wizard-dialog .k-tabstrip-wrapper .k-content {
    overflow: auto;
}

.k-editor-table-wizard-dialog {
    .k-numeric-wrap.k-state-default,
    .k-dropdown-wrap.k-state-default,
    .k-numerictextbox {
        background-color: transparent;
        border: none;
        margin: 0;
        padding: 0;

        span.k-input,
        input {
            appearance: none;
            background-color: $white;
            border: $base-border;
            border-radius: $base-border-radius;
            box-shadow: none;
            box-sizing: border-box;
            color: $almost-black;
            font-size: 16px;
            height: 44px;
            margin-bottom: 0;
            padding: 12px 12px;
            transition: border-color $base-duration $base-timing;
            width: 100%;

            &:hover {
                border-color: $ada-grey-3;
            }

            &:focus {
                border-color: $pistachio;
                border-width: 2px;
                box-shadow: none;
                outline: none;
            }
        }

        .k-select {
            background-color: transparent;
            border: none;

            .k-link {
                border-radius: 0;
                height: auto;
                line-height: inherit;

                .k-icon {
                    color: $graphite-primary !important;

                    &.k-i-arrow-60-up,
                    &.k-i-arrow-60-down {
                        font-family: 'icomoon' !important;
                        speak: none;
                        font-style: normal;
                        font-weight: normal;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1;

                        /* Better Font Rendering =========== */
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;

                        fill: $grey !important;

                        background: $ada-grey-5;
                        border: $base-border;
                        border-left: none;
                        height: 21px;
                        margin: 0;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 0;
                        width: 23px;
                        z-index: 2;

                        &:before {
                            background: transparent;
                            color: $graphite-primary;
                            font-size: 20px;
                            fill: $grey !important;
                            height: 22px;
                            margin: 0;
                            padding: 0;
                            transition: all $base-duration $base-timing;
                            width: 23px;
                        }

                        &:hover {
                            &:before {
                                color: $mine-shaft;
                                fill: $mine-shaft !important;
                            }
                        }
                    }

                    &.k-i-arrow-60-up {
                        border-bottom: none;
                        border-radius: 0 4px 0 0;

                        &:before {
                            content: '\ee00';
                        }
                    }

                    &.k-i-arrow-60-down {
                        border-top: none;
                        border-radius: 0 0 4px 0;
                        top: 22px;

                        &:before {
                            content: '\ee02';
                        }
                    }
                }
            }
        }
    }

    .k-widget.k-dropdown.k-header {
        .k-dropdown-wrap.k-state-default {
            span.k-input {
                padding: 2px 0 0 0;
            }

            .k-i-arrow-60-down:before {
                background: $white url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat 0 5px;
                background-size: 24px;
                color: $jumbo;
                width: 100%;
            }
        }

        &.k-align {
            .k-dropdown-wrap.k-state-default {
                span.k-input {
                    padding: 6px 0 0 0;
                }
            }
        }
    }

    .k-widget.k-colorpicker.k-header {
        .k-picker-wrap.k-state-default {
            height: 44px;
            margin: 0;
            padding: 0 32px 0 0;

            &:hover {
                border: $base-border;
            }

            .k-selected-color {
                border-radius: 4px 0 0 4px;
                height: 40px;
                padding: 1px;
            }

            .k-select {
                border: none;

                .k-i-arrow-60-down:before {
                    background: $white url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat 3px 5px;
                    background-size: 24px;
                    color: $jumbo;
                    width: 100%;
                }
            }
        }
    }

    .k-input.k-textbox {
        margin: 0;
    }
}

//overwrite opacity on k-icons
.k-grid .k-icon {
    opacity: 1;
}
