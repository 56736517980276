.six-columns {
    border-bottom: 2px solid $hub;
    margin-bottom: bsu(2);

    h1 {
        width: 100%;
    }

    section {
        p {
            margin: 0;
        }
    }
}

@include bp(sm) {
    .six-columns {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: center;

        section {
            margin: 0 2% bsu(1) 0;
            width: 49%;

            &:nth-of-type(2n+2) {
                margin: 0 0 bsu(1) 0;
            }
        }
    }
}

@include bp(md) {
    .six-columns {
        section {
            margin: 0 2% bsu(1) 0;
            width: 15%;

            &:nth-of-type(2n+2) {
                margin: 0 2% bsu(1) 0;
            }
            
            &:nth-of-type(6n+6) {
                margin: 0 0 bsu(1) 0;
            }
        }
    }
}

