.blocker {
    background-color: rgba($almost-black, 0.5) !important;
    z-index: 1001 !important;
}

.exit-intent {
    display: none;
}

.modal {
    border-radius: 6px;
    box-shadow: 0 0 10px rgba($almost-black, 0.7);
    max-width: 600px;
    padding: 30px;
    text-align: left;

    //for all the word breaks
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;

    // p {
    //     font-size: 16px;
    // }

    .modal-log-content,
    .log-info {
        p {
            font-size: 14px;
        }
    }

    h1 {
        // font-size: 30px;
        // font-weight: 600;
        // padding: 0;

        &:after {
            display: none;
        }

        &.green-background,
        &.orange-background,
        &.pistachio-background {
            background: $hub;
            border-radius: $base-border-radius $base-border-radius 0 0;
            color: $white;
            padding: bsu(0.5);
        }

        &.orange-background {
            background: $marigold;
        }

        &.pistachio-background {
            background: $pistachio;
        }

        &.left {
            padding-left: bsu(2);
            text-align: left;
        }
    }

    .toggle-button h1 {
        font-style: 20px;
    }

    &.modal-745 {
        max-width: 745px;
    }

    &.narrow-modal {
        max-width: 420px;
    }

    &.big-honkin-modal {
        max-width: 70em;
        text-align: left;
        width: 100%;

        h1 {
            font-size: 20px;
            margin: 0 0 bsu(1) 0;  
            padding: 0 0 bsu(0.5) 0;    
            position: relative;
            text-align: left;
    
            &:after {
                background: $hub;
                bottom: 0;
                content: '';
                display: block;
                height: 2px;
                position: absolute;
                width: 100%;
            }
        }

        p {
            margin: 0;
        }
    }

    &.bigger-honkin-modal {
        max-width: 1600px;
        text-align: left;
        width: 100%;
    }

    .overflow-y {
        overflow-y: scroll;
        max-height: 250px;
    }

    .button-container {
        &.single-button {
            button,
            .button,
            .btn {
                margin: 0 auto;
                text-align: center;
            }
        }

        &.double-buttons-right-aligned {
            margin-left: 38%;
        }
    }
    
    .modal-two-button-container {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;

        margin: bsu(1) auto 0 auto;
        max-width: 330px;

        button,
        a.button {
            margin: 0;
            max-width: 150px;
            width: 48%;
        }
    }

    &.green-header,
    &.orange-header,
    &.pistachio-header {
        padding: 0;

        h1 {
            margin: 0;
        }

        section {
            padding: bsu(2);
            text-align: left;
        }
    }
}

a.close-modal {
    background-image: none !important;
    height: 40px !important;
    right: 0 !important;
    top: 0 !important;
    width: 55px !important;

    &:after {
        background: transparent;
        border-radius: 0;
        color: $ada-grey-2;
        content: '\e5cd';
        display: block;
        font-family: 'icomoon' !important;
        font-size: 35px;
        font-weight: 200;
        height: 30px;
        line-height: 1;
        padding: 0;
        position: absolute;
        right: 13px;
        text-align: center;
        text-indent: 0;
        top: 7px;
        width: 30px;
        z-index: 5;
    }

    &.button,
    &.btn {
        background-image: none;
        height: 44px !important;
        position: relative;
        right: inherit;
        top: 0 !important;
        text-indent: 0;
        width: auto !important;

        &:after {
            display: none;
        }
    }
}

@include bp(sm) {
    // a.close-modal {
    //     height: 40px;
    //     right: 10px;
    //     width: 40px;

    //     &:after {
    //         font-size: 50px;
    //         height: 40px;
    //         width: 40px;
    //     }
    // }
}

.jconfirm.jconfirm-hub {
    .jconfirm-bg {
        background-color: rgba($almost-black, 0.5);
        z-index: z('modal');
    }
    .jconfirm-scrollpane {
        position: relative;
        z-index: z('goku');
    }
    .jconfirm-box {
        border-radius: $base-border-radius;
        box-shadow: 0 0 10px rgba($almost-black, 0.7);
        padding: bsu(2);
        text-align: center;
        max-width: 450px;
        min-width: 300px;
        width: 25%;

        &.jconfirm-type-green {
            border-top: solid 7px $hub;
        }


        &.loading {
            &:before {}
            &:after {}
        }

        div.jconfirm-closeIcon {
            background-image: none;

            &:after {
                background: $almost-black;
                border-radius: 100%;
                color: $white;
                content: "\e5cd";
                display: block;
                font-family: 'icomoon' !important;
                font-size: 18px;
                font-weight: 600;
                height: 30px;
                padding: 1px 0 0 0;
                position: absolute;
                right: 0;
                text-align: center;
                text-indent: 0;
                top: 0;
                width: 30px;
                z-index: 5;
            }
        }

        div.jconfirm-title-c {}
        div.jconfirm-content-pane {}
        div.jconfirm-content {
            &:empty {}
        }

        .jconfirm-buttons {
            button {
                width: 33%;
                margin: 2px 5px;
            }
            
            button+button {}
        }

        &.hilight {}
    }
}

//delete-green-header
#delete-green-header.modal {
    padding: 0;

    h1 {
        margin: 0;
    }

    section {
        background: $concrete;
        padding: bsu(2);
    }
}

@include bp(sm) {
    #delete-green-header.modal {
        max-width: 500px;
        width: 80%;

        section {
            border-radius: 5px;
        }
    }
}

//add/edit category modal - additional resources
#add-category.modal,
#edit-category.modal {
    h1 {
        font-size: 20px;
        margin: 0 0 bsu(1) 0;  
        padding: 0 0 bsu(0.5) 0;    
        position: relative;
        text-align: left;

        &:after {
            background: $hub;
            bottom: 0;
            content: '';
            display: block;
            height: 2px;
            position: absolute;
            width: 100%;
        }
    }

    form {
        text-align: right;
    }
}

//feedback form
#feedback.modal,
#learnmore.modal {
    h1 {
        font-size: 20px;
        margin: 0 0 bsu(1) 0;  
        padding: 0 0 bsu(0.5) 0;    
        position: relative;

        &:after {
            background: $hub;
            bottom: 0;
            content: '';
            display: block;
            height: 2px;
            position: absolute;
            width: 100%;
        }
    }

    textarea {
        margin: 0 0 bsu(1.5) 0;
    }
}

@include bp(sm) {
    #feedback.modal,
    #learnmore.modal {
        form {
            text-align: right;

            .button {
                min-width: 150px;
            }
        }
    }

    #learnmore.modal {
        form {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: top;
            justify-content: center;
        }

        .form-item {
            margin: 0 4% 0 0;
            width: 48%;

            &:nth-of-type(2n+2) {
                margin: 0;
            }

            &:last-of-type {
                margin: 0;
                width: 100%;
            }
        }
    }
}

//save-as form
#save-as.modal {
    background: $concrete;
    padding: 0;

    h1 {
        background: $hub;
        border-radius: $base-border-radius $base-border-radius 0 0;
        color: $white;
        padding: bsu(0.5);
    }

    form {
        padding: bsu(0.5) bsu(2) bsu(2) bsu(2);
    }

    .button {
        width: 100%;
    }
}

@include bp(sm) {
    #save-as.modal {
        form {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            flex-flow: row wrap;
        }

        .form-item {
            width: 100%;

            &:first-of-type,
            &:nth-of-type(2) {
                width: 48%;
            }

            &:first-of-type {
                margin-right: 4%;
            }
        }
    }
}

@include bp(md) {
    #save-as.modal {
        .button {
            margin: 0 0 0 auto;
            width: 150px;
        }
    }
}

//instructions
#instructions.modal,
#delete_attribute.modal {
    background: $concrete;
    padding: 0;

    section {
        padding: bsu(1);
        text-align: left;

        h4 {
            margin: 0 0 bsu(0.25) 0;
        }

        p {
            margin-bottom: bsu(1);
        }

        ul {
            list-style: disc;
            margin: 0 0 bsu(0.75) bsu(1);

            li {
                margin: 0 0 bsu(0.25) 0;
            }
        }

        ul.flex-list {
            list-style: none;
            margin: 0 0 bsu(0.75) bsu(1);

            li {
                margin: 0 0 bsu(0.5) 0;
                padding: 0 0 0 10px;
                position: relative;
                text-align: left;
                width: 100%;

                &:before {
                    content: "•";
                    left: 0;
                    position: absolute;
                }
            }
        }
    }
}

@include bp(sm) {
    #instructions.modal,
    #delete_attribute.modal {
        section {
            ul.flex-list {
                list-style: none;
                margin: 0 auto bsu(0.75) auto;
                width: 65%;
    
                li {
                    margin: 0 4% bsu(0.5) 0;
                    width: 46%;
    
                    &:nth-of-type(2n+2) {
                        margin: 0 0 bsu(0.5) 0;
                    }
                }
            }
        }
    }
}

@include bp(md) {
    #instructions.modal {
        max-width: 600px;
        width: 80%;

        section {
            ul.flex-list {
                width: 80%;

                li {
                    margin: 0 2% bsu(0.5) 0;
                    width: 32%;

                    &:nth-of-type(2n+2) {
                        margin: 0 2% bsu(0.5) 0;
                    }

                    &:nth-of-type(3n+3) {
                        margin: 0 0 bsu(0.5) 0;;
                    }
                }
            }
        }
    }
}

//explanation
#explanation.modal {
    padding: 0;

    h1 {
        margin: 0;
    }

    section {
        background: $concrete;
        padding: bsu(1);
        text-align: left;
    }
}

@include bp(sm) {
    #explanation.modal {
        max-width: 400px;
        width: 80%;

        label {
            margin-left: auto;
            margin-right: auto;
            width: 90%;
        }
    }
}

//approval code
#approval-code.modal {
    background: $concrete;
    padding: 0;

    h1 {
        background: $hub;
        border-radius: $base-border-radius $base-border-radius 0 0;
        color: $white;
        padding: bsu(0.5);
    }

    form {
        padding: bsu(1.5) bsu(1) bsu(2) bsu(1);

        .form-flex {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            flex-flow: row wrap;

            .form-item {
                margin: 0 4% 0 0;
                width: 22%;
        
                &:last-of-type {
                    margin: 0;
                }

                input {
                    text-align: center;
                }
            }
        }
    }
}

@include bp(sm) {
    #approval-code.modal {
        max-width: 400px;
        width: 80%;

        form {
            padding: bsu(1.5) bsu(3) bsu(2) bsu(3);
        }
    }
}

#existing-manual.modal,
#existing-audit.modal {
    background: $concrete;
    padding: 0;

    h1 {
        span {
            font-size: 14px;
            font-weight: 400;
        }
    }

    ul {
        margin: 0;
        padding: 0 bsu(2);
    }
}

#save-settings {
    padding: 0;

    section {
        padding: bsu(1);

        .button-container {
            padding: bsu(1) 0;

            .button {
                margin: 0 10% 0 0;
                width: 45%;

                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }
}

@include bp(sm) {
    #save-settings {
        section {    
            .button-container {
                padding: bsu(1) bsu(4);
            }
        }
    }
}

//expectations
#expectationsModal {
    text-align: left;
}

//rei/phi
.active-rei-phi-overlay {
    opacity: 1;
}

#active-rei-phi.modal {
    display: inline-block;
    opacity: 1;
    padding: 0;

    h1 {
        background: $hub;
        border-radius: $base-border-radius $base-border-radius 0 0;
        color: $white;
        margin: 0;
        padding: bsu(0.5);
    }

    section {
        padding: bsu(1);
    }

    table {
        tr {
            td {
                &:first-of-type {
                    background: $marigold;
                    color: $white;
                }
            }
        }
    }
}

@include bp(md) {
    #active-rei-phi.modal {
        max-width: 70em;
        width: 80%;

        section {
            padding: bsu(2);
        }

        table {
            tr {
                th {
                    background: $marigold;
                    color: $white;
                }

                td {
                    &:first-of-type {
                        background: $concrete;
                        color: $almost-black;
                    }
                }

                &:nth-of-type(2n+2) {
                    td {
                        &:first-of-type {
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
}

//edit field name modal
.modal {
    &.edit-field-name {
        .button-container {
            justify-content: flex-end;

            a {
                width: 125px;
            }
        }
    }
}

@include bp(sm) {
    .modal {
        &.edit-field-name {
            .button-container {
                
            }
        }
    }
}

//IMAGE SIZER MODAL
.image-resizer-wrapper {
    position: relative;
    z-index: 999;
}

.modal-overlay {
    background-color: rgba($almost-black, 0.5) !important;

    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0;
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    transition: all $base-duration $base-timing;
    z-index: 402;

    &.show {
        opacity: 1;
        transition: all $base-duration $base-timing;
        z-index: 402;
    }
}

#imgtoResize {
    margin: 0 auto bsu(1) auto;
}

.image-sizer {
    background: $white;
    border-radius: 5px;
    padding: bsu(2);
    position: relative;
    margin: 0 auto;
    max-width: 600px;
    width: 50%;

    .k-editor.k-header {
        background-color: transparent !important;
    }

    a.close-modal {
        position: absolute;
        top: -12.5px;
        right: -12.5px;
        display: block;
        width: 30px;
        height: 30px;
        text-indent: -9999px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .image-resizer-button-container,
    .image-resizer-clear-close-container {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
    }

    .image-resizer-button-container {
        margin: 0 0 bsu(2) 0;
        justify-content: flex-end;

        select {
            background-position: 95% 18px;
            padding: 15px;
            width: 100%;
        }

        input {
            font-size: 30px;
            line-height: 1;
            margin: 0 10px 0 0;
            padding: 7px 10px 13px 10px;
            width: 50px;

            &:last-of-type {
                margin: 0;
            }
        }
    }

    .image-resizer-clear-close-container {
        input {
            margin: 0 4% 0 0;
            width: 48%;

            &:last-of-type {
                margin: 0;
            }
        }
    }
}

@include bp(sm) {
    .image-sizer {
        .image-resizer-button-container {
            justify-content: flex-start;

            select {
                margin: 0 10px 0 0;
                width: 68%;
            }
    
            input {
                margin: 0 10px 0 0;
                width: 50px;
    
                &:last-of-type {
                    margin: 0;
                }
            }
        }
    
        .image-resizer-clear-close-container {
            input {
                margin: 0 4% 0 0;
                width: 48%;
    
                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }
}

@include bp(md) {
    .image-sizer {
        .image-resizer-button-container {    
            select {
                background-position: 98% 12px;
                margin: 0 2% 0 0;
                width: 68%;
            }
    
            input {
                margin: 0 2% 0 0;
                width: 14%;
    
                &:last-of-type {
                    margin: 0;
                }
            }
        }
    
        .image-resizer-clear-close-container {
            justify-content: flex-start;

            input {
                margin: 0 2% 0 0;
                width: 24%;
    
                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }
}

//thatsit
.thatsit,
.new-modal,
.video-modal {
    h1 {
        font-size: 40px;
    }

    ol.big-numbers {
        margin: 0 auto;
        width: 50%;
    }

    .bottom-button-container.flex-center .btn {
        margin: 0 4% 0 0;
        width: 48%;

        &:nth-of-type(2n+2),
        &:last-of-type {
            margin: 0;
        }
    }
}

//welcome modal
.welcome-message-modal {
    font-family: 'Quattrocento Sans', sans-serif;

    .text-container {
        text-align: left;
    }

    h1 {
        color: #5d8076;
        font-family: 'Quattrocento Sans', sans-serif;
        font-size: 30px;
        text-align: center;
    }

    p {
        margin: 0 0 5px 0;
    }

    img {
        height: auto;
        margin: 0 auto 10px auto;
        width: 100px;
    }
}

@include bp(sm) {
    .welcome-message-modal {
        padding: bsu(2) bsu(3);
        text-align: left;

        .welcome-message-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            flex-flow: row wrap;

            img {
                margin: 0 5% 0 0;
                width: 25%;
            }

            .text-container {
                width: 70%;
            }

            h1 {
                text-align: left;
            }
        }
    }
}

//video modals
.modal.new-modal,
.modal.video-modal {
    max-width: 750px;
    width: 90%;

    .btn {
        min-width: 0px;
        padding: 9px 20px 11px 20px;

        &.btn-ghost {
            padding: 7px 19px 9px 19px;
        }
    }
}

@include bp(sm) {
    .modal.new-modal,
    .modal.video-modal {
        .bottom-button-container.flex-center {
            .btn {
                min-width: 150px;
                width: auto;
            }
        }
    }
}


///////////////////////////////////////////////
//new design
///////////////////////////////////////////////
body.new-design {
    .modal {
        border-radius: 10px 10px 6px 6px;
        max-width: 600px;
        text-align: left;
        padding: 30px;

        &.narrow-modal {
            max-width: 420px;
        }

        &.modal-745 {
            max-width: 745px;
        }

        &.big-honkin-modal {
            max-width: 70em;
        }
    
        &.bigger-honkin-modal {
            max-width: 1600px;
        }

        &.log-builder-modal {
            padding: 0;
            max-width: 500px;

            h2 {
                background: $plantation;
                border-radius: 6px 6px 0 0;
                color: $white;
                font-size: 18px;
                font-weight: 700;
                padding: 10px 30px;

                .styled-checkbox~label {
                    color: $white;
                }
            }

            &.record {
                h2 {
                    background: $sea-nymph-hover;
                    color: $mine-shaft;

                    .styled-checkbox~label {
                        color: $mine-shaft;
                    }
                }
            }

            &.modal-795 {
                max-width: 795px;
                //width: 795px;
            }

            &.modal-870 {
                max-width: 870px;
                //width: 795px;
            }

            section {
                padding: 30px;
            }

            label {
                font-weight: 600;
            }
        }

        &.marketwatch-modal {
            padding: 0;
            text-align: center;
            max-width: 500px;
            width: 100%;

            .header-bar {
                background: $chambray;
                border-radius: 6px 6px 0 0;
                height: 40px;
            }

            svg {
                height: 75px;
                margin: 0 auto 20px auto;
                width: 75px;
            }

            section {
                padding: 20px;
            }

            h1,
            h2 {
                font-size: 22px;
                font-weight: normal;
                margin: 0;
            }

            h1 {
                margin: 0 0 10px 0;
            }

            h2 {
                font-size: 16px;
            }

            .btn {
                padding: 13px 20px;
                width: 140px;

                &:nth-of-type(2n+2) {
                    margin-right: 0;
                }
            }

            .close-modal {
                display: none;
            }
        }

        h1 {
            font-size: 30px;
            font-weight: 600;
        }

        p {
            font-size: 16px;
        }

        .modal-log-content,
        .log-info {
            p {
                font-size: 14px;
            }
        }

        a.close-modal {
            background-image: none;
            height: 30px;
            right: 0;
            top: 5px;
            width: 30px;

            &:after {
                background: transparent;
                border-radius: 0;
                color: $grey;
                content: '\e5cd';
                font-size: 35px;
                font-weight: 200;
                height: 30px;
                line-height: 1;
                padding: 0;
                right: 5px;
                top: -5px;
                width: 30px;
            }
        }
    }
}

@include bp(sm) {
    body.new-design {
        .modal {
            //padding: 30px 50px 50px 50px;

            a.close-modal {
                height: 40px;
                right: 15px;
                top: 15px;
                width: 40px;
    
                &:after {
                    font-size: 50px;
                    height: 40px;
                    width: 40px;
                }
            }
        }
    }
}

@include bp(md) {
    body.new-design {
        .modal {
            //padding: 50px 50px 50px 50px;
        }
    }
}

//New modals - 03/22
.modal.generic-modal {
    border-radius: 0;
    bottom: 0;
    left: 0;
    max-width: 790px;
    overflow-y: auto;
    position: absolute;
    right: 0;
    text-align: left;
    top: 0;
    width: 100%;
    z-index: 1002 !important;

    section {
        padding: 0;
    }

    h1 {
        font-weight: 600;
        margin: 0 0 bsu(1) 0;  
        padding: 0 0 bsu(1) 0;    
        position: relative;
    
        &:after {
            background: $hub;
            bottom: 0;
            content: '';
            display: block;
            height: 2px;
            position: absolute;
            width: 100%;
        }
    }
}

@include bp(sm) {
    .modal.generic-modal {
        border-radius: 6px;
        bottom: inherit;
        left: inherit;
        position: relative;
        right: inherit;
        top: inherit;
    }
}

//New Modals - 06/22
.modal.basic-modal {
    border-radius: 0;
    bottom: 0;
    left: 0;
    max-width: 550px;
    overflow-y: auto;
    position: absolute;
    right: 0;
    text-align: left;
    top: 0;
    width: 100%;
    z-index: 1002 !important;

    section {
        padding: 0;
    }

    h1 {
        margin: 0 0 40px 0;
        padding: 0;
    }

    h2 {
        margin: 0 0 10px 0;
    }

    h1 {
        margin: 0 0 40px 0;
        padding: 0;
    }

    h2 {
        margin: 0 0 10px 0;
    }

    p {
        margin: 0 0 30px 0;

        &:last-of-type {
            margin: 0;
        }
    }

    .btn-container {
        margin: 55px 0 0 0;
    }


    &.basic-modal-medium {
        max-width: 650px;

        h1 {
            margin: 0 0 3px 0;  
            padding: 0 0 3px 0;    

            &:after {
                display: block;
            }
        }

        p {
            margin: 0 0 30px 0;
        }
    }
}

@include bp(sm) {
    .modal.basic-modal {
        border-radius: 6px;
        bottom: inherit;
        left: inherit;
        position: relative;
        right: inherit;
        top: inherit;
    }

    .btn-container {
        #{$all-buttons},
		.button,
		.btn,
		a.btn,
		.ui-widget input.btn,
		.k-button,
		.k-filter-menu .k-button,
		.k-edit-form-container .k-button, 
		.k-edit-form-container .k-primary, 
		.k-popup .k-button, 
		.k-popup .k-primary,
		.ui-widget-content a.btn,
		section {
            margin: 0 2% 0 0;

            &:nth-last-of-type(2) {
                margin: 0 2% 0 0;
            }

            &:last-of-type {
                margin: 0;
            }
        }
    }
}


@include bp(md) {
    .modal.basic-modal {
        border-radius: 6px;
        bottom: inherit;
        left: inherit;
        position: relative;
        right: inherit;
        top: inherit;
    }
}
