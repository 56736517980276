.u-hidden,
.sr-only {
    visibility: hidden !important;
    transform: scale(0) !important;
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.u-invisible {
    opacity: 0;
    visibility: hidden;
    transition: all ease-in-out 0.25s;
}

@each $key, $breakpoint in $breakpoints {
    .#{key}-u-hidden {
        @include bp($breakpoint) {
            visibility: hidden !important;
            transform: scale(0) !important;
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;

        }
    }

    .#{key}-u-invisible {
        @include bp($breakpoint) {
            opacity: 0;
            visibility: hidden;
        }
    }

}