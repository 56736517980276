/* Measure is limited to ~66 characters */
.u-text-measure {
    max-width: 30em !important;
}

/* Measure is limited to ~80 characters */
.u-text-measure--wide {
    max-width: 34em !important;
}

/* Measure is limited to ~45 characters */
.u-text-measure--narrow {
    max-width: 20em !important;
}

/* Book paragraph style - paragraphs are indented with no vertical spacing.u-text- */
.u-text-indent {
    text-indent: 1em !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.u-text-small-caps {
    font-variant: small-caps !important;
}

/* Combine this class with a width to truncate text (or just leave as is to truncate at width of containing element.u-text- */

.u-text-truncate {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

@each $key, $breakpoint in $breakpoints {

    .#{key}-u-text-measure {
        @include bp($breakpoint) {
            max-width: 30em !important;
        }
    }

    .#{key}-u-text-measure--wide {
        @include bp($breakpoint) {
            max-width: 34em !important;
        }
    }

    .#{key}-u-text-measure--narrow {
        @include bp($breakpoint) {
            max-width: 20em !important;
        }
    }

    .#{key}-u-text-indent {
        @include bp($breakpoint) {
            text-indent: 1em !important;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    .#{key}-u-text-small-caps {
        @include bp($breakpoint) {
            font-variant: small-caps !important;
        }
    }

    .#{key}-u-text-truncate {
        @include bp($breakpoint) {
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
        }
    }

}