@mixin percentwidths-universal-x {
    @for $i from 1 through 100 {
        .percent-#{$i} { 
            width: #{$i}#{'%'} !important; 
        }
    }
}

@mixin fixedwidths-universal-x {
    @for $i from 1 through 5000 {
        .fixedwidth-#{$i} { 
            width: #{$i}#{'px'} !important;
            //min-width: 100%;
            max-width: 100%;
        }
    }
}

@mixin fixedwidths-med-up-x {
    @for $i from 1 through 5000 {
        .md-fixedwidth-#{$i} { 
            width: #{$i}#{'px'} !important;
            //min-width: 100%;
            max-width: 100%;
        }
    }
}

@include bp(md) {
    @include percentwidths-universal-x;
}

@include fixedwidths-universal-x;

@include bp(md) {
    @include fixedwidths-med-up-x;
}

html {
    box-sizing: border-box;
    color: $almost-black;
    font-family: $base-font-family;
    font-size: 16px;
    font-weight: 400;
    line-height: $base-line-height;
    -webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

html,
body {
    height: 100%;
    min-height: 100%;
}

body {
    background: url('../../Content/img/login-bg.jpg') no-repeat center center fixed;
    background-size: cover;
    display: block;
    height: 100%;
    margin: 0;
    overflow-x: hidden;
    width: 100%;

    footer.site-footer {
        display: none;
    }

    &.logged-in {
        background: $white;
        display: flex;
        flex-direction: column;

        footer.site-footer {
            display: block;
        }
    }
}

.site-outer-wrapper {
    flex: 1 0 auto;
    position: relative;
}

// body.new-design,
// body.reskin-container {
//     .site-outer-wrapper {
//         position: relative;
//     }
// }

@include bp(sm) {
    body { 
        &.logged-in {
            padding-top: $top-bar-height;
        }
    }
}

//overlay
body.logged-in {
    margin-top: 57px;

    &:before {
        background: rgba(0,0,0,0.2);
        bottom: 0;
        content: "";
        height: 100%;
        left: 0;
        opacity: 0;
        position: fixed;
        right: 0;
        top: 0;
        transition: none;
        width: 100%;
    }

    &.nooverlay {
        &:before {
            animation: zindex 7s forward;
        }
    }

    &.overlay {
        &:before {
            opacity: 1;
            z-index: 6;
        }

        &:after {
            background-image: linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0.2),  rgba(255,255,255,0));
            bottom: 0;
            content: "";
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 20.75rem;
            z-index: 2;
        }  
    }

    &.new-design {
        &.overlay {
            &:before {
                background-image: none;
                background: rgba(#6B7274,0.7);
            }
        }
    }

    &.self-service {
        margin-top: 0;
    }


}

@keyframes zindex {
    0% {
        z-index: 6;
    }
    100% {
        z-index: -10;
    }
}

//new for mobile
body.new {
    &.logged-in {
        margin-top: 57px;
    }
}

//mobile app adjust status bar
body,
body.logged-in {
    padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
    padding-top: env(safe-area-inset-top); /* iOS 11.2 */
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

//google translate
.skiptranslate {
    display: none !important;
}

.goog-text-highlight {
    background-color: inherit !important;
    box-shadow: inherit;
}


