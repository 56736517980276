table {
  //border-bottom: 2px solid $pistachio;
  border-collapse: collapse;
  font-size: 14px;
  margin: $base-spacing 0;
  padding: 0;
  position: relative;
  table-layout: fixed;
  text-align: left;
  width: 100%;

  &.no-margin {
    margin: 1px 0 !important;
  }
}

thead {
  line-height: $heading-line-height;
  vertical-align: bottom;

  border: none;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

tbody {
  vertical-align: top;
  width: 100%;
}

tr {
  display: block;
}

th,
td {
  display: block;
  font-size: 16px;
  padding: $small-spacing 10px $small-spacing 0;
  vertical-align: top;
  word-break: break-word;
  //word-wrap: break-word;
  width: auto;

  &.bg-pistachio {
    background: $pistachio;
    color: $white;

    &:nth-of-type(2n+2) {
      background: $pistachio;
    }
  }
}

th {
  font-weight: 600;
}

td {
  min-height: 40px;
  overflow-wrap: break-word;
  padding-left: 44%;
  position: relative;

  &:before {
    content: attr(data-label);
    font-weight: bold;
    left: 0;
    padding-left: 10px;
    position: absolute;
    text-align: left;
    width: 40%;
  }

  &.has-attachment {
    overflow-wrap: break-word;
  }

  &.notes {
    section {
      background: $mercury;
      padding: 5px;

      p {
        margin: 0 0 5px 0;
      }

      &:nth-of-type(2n+2) {
        background: transparent;
      }
    }
  }

  // &:nth-of-type(2n+2) {
  //   background: transparent;
  // }

  input,
  select {
    margin: 0;
    width: 52%;
  }

  // select {
  //   background: $white url('../../Content/img/icons/icon_arrow_down.svg') no-repeat 95% 8px;
  //   background-size: 12px;
  // }

  ul {
    margin-bottom: 0;
  }
}

@include bp(md) {
  table {
    td {
      &.notes {
        padding: 0;
      }
    }
  }
}

#attached-files {
  overflow-wrap: break-word;
}

table.k-scheduler-table,
table.k-month,
table.k-content,
table.k-scheduler-layout,
table.k-scheduler-table.k-scheduler-header-all-day,
table.k-scheduler-dayview,
table.k-scheduler-dayview table.k-scheduler-table {

  tr {
    display: table-row;
  }

  th, td {
    display: table-cell;
  }

  td {
    min-height: 0;
  }
}

table.k-scheduler-dayview {
  table-layout: auto;

  table.k-scheduler-table {
    table-layout: auto;

    tr {
      height: 41px;
    }
  }

  tr {
    height: 41px;
  }
}

.k-scheduler-layout>tbody>tr>td {
  min-height: 0;
}

table.original-site,
table.subsite {
  border: none;
  
  td {
    text-align: right;
    vertical-align: middle;
  }
}

table.green-header,
table.orange-header,
table.pistachio-header,
table.red-header {
  thead {
    tr {
        background: $hub;
        color: $white;
    }

    &.k-grid-header {
      th.k-header {
        background-color: $hub;
      }
    }
  }

  tbody {
    tr {
      background: $concrete;

      &:nth-of-type(2n+2) {
          background: transparent;
      }

      td {
        //background: $concrete;
        //padding: 10px;
      }

      &:nth-of-type(2n+2) {
        background: $white;
      }
    }
  }
  
}

table.orange-header {
  thead {
    tr {
      background: $marigold;
    }

    &.k-grid-header {
      th.k-header {
        background-color: $marigold;
      }
    }
  }
}

table.pistachio-header {
  thead {
    tr {
        background: $pistachio;
    }
  }
}

table.red-header {
  thead {
    tr {
        background: $red-orange;
    }

  }

  td {
    &:before {
      color: $red-orange;
    }
  }
}

@include bp(md) {
  thead {
    clip: 0;
    height: auto;
    margin: 0;
    position: relative;
    width: auto;
  }

  tr {
    display: table-row;

    td {
      background: $concrete;
      //padding: $small-spacing $small-spacing $small-spacing 0;

      &:nth-of-type(2n+2) {
        background: $concrete;
      }

      input,
      select {
        float: none;
        width: 100%;
      }
    }

    &:nth-of-type(2n+2) {
      td {
        background: $white;

        &:nth-of-type(2n+2) {
          background: $white;
        }
      }
    }
  }

  td, th {
    display: table-cell;
    text-align: left;
    //vertical-align: middle;
    padding: 10px;
  }

  td {
    background: transparent;
    border-radius: 0;
    
    &:before {
      display: none;
    }
  }

  table.original-site,
  table.subsite {
    th,
    td {
      text-align: left;
    }
  }

  table.no-striping {
    tbody {
      tr {
        background: transparent;

        td {
          background: transparent;
        }

        &:nth-of-type(2n+2) {
          background: transparent;
        
          tbody {
            td {
              background: transparent;
            }
          }
        }
      }
    }
  }
}

//non-responsive table
.modal {
  .rendered-html-container {
    overflow-x: auto;
  }
}

.rendered-html-container {
  table {
    display: table;
    table-layout: initial;
  }
}

@media print {
  .rendered-html-container {
    table {
      max-width: 100% !important;
      width: 100% !important;
  
      tbody,
      thead {
        max-width: 100% !important;
        width: 100% !important;
  
        tr {
          max-width: 100% !important;
          width: 100% !important;
        }
  
        th, 
        td {
          width: auto !important;
        }
      }
    }
  }
}

.rendered-html-container table,
//.report-preview table,
table.non-responsive,
.k-grid.k-widget.telerik-blazor table {
  display: table;

  thead {
    line-height: $heading-line-height;
    vertical-align: top;
    clip: none;
    height: auto;
    margin: 0;
    padding: 0;
    position: relative;
    width: auto;
  }

  tr {
    display: table-row;
  }

  tbody {
    tr {
      background: $concrete;
  
      &:nth-of-type(2n+2) {
        background: transparent;
      }

      td {
        background: transparent;
      }
    }
  }

  td,
  th {
    display: table-cell;
    padding: 10px;
    word-break: normal;

    &:before {
      display: none;
    }
  }

  &.no-striping {
    width: auto;

    tbody {
      tr {
        background: transparent;

        td {
          background: transparent;
          padding: 10px !important;
        }

        &:nth-of-type(2n+2) {
          background: transparent;
          
          td {
            background: transparent;
          }
        }
      }
    }
  }

  &.second-column-right-align {
    tr {
      td {
        &:nth-of-type(2) {
          text-align: right !important;
        }
      }
    }
  }

  &.scrollable-non-responsive {
    //overflow: scroll;
    min-width: 100%;
    width: auto;
  }
}

//non-responsive overflow-x table-grid
.horizontal-scroll-grid {
  border-bottom: 2px solid $hub;
  overflow-x: auto;

  .row {
    background: $concrete;

    div {
      background: $concrete;
    }

    &:nth-of-type(2n+2) {
      background: $white;

      div {
        background: $white;
      }
    }

    &.grid-header {
      background: $hub;
      color: $white;
      font-weight: bold;

      div {
        background: $hub;
      }
    }

    &.grid-footer {
      background: $concrete;
      font-size: 16px;
      font-weight: bold;

      div {
        background: $concrete;
        height: 50px;
        padding-bottom: 15px;
        padding-top: 15px;
      }
    }

    div {
      display: inline-block;
      height: 40px;
      padding: 10px;

      &:first-of-type {
        left: 0;
        position: sticky;
      }
    }
  }

  &.custom-maps-print {
    .row {
      width: 750px;

      div {
        text-align: right;
        width: 180px;

        &:first-of-type {
          text-align: left;
          width: 120px;
        }
  
        &:nth-of-type(2),
        &:nth-of-type(3) {
          width: 125px;
        }
      }
    }
  }

  &.table-only-content {
    //border: 1px solid $hub;
    //border-radius: 6px;
    border: none;
  }
}

.horizontal-scroll-grid-container-rounded-corners {
  border: 1px solid $hub;
  border-radius: 6px;
  overflow: hidden;
}

@include bp(md) {
  .horizontal-scroll-grid {
    &.custom-maps-print {
      overflow-x: visible;
    }
  }
}

//telerik blazor filter tables non-responsive
.k-grid.k-widget.telerik-blazor {
  border: none;
  
  table {
    min-width: 1200px;
    color: $emperor;

    tr {
      &:hover {
        background: transparent;
      }
    }

  }

  .k-grid-header th.k-header,
  .k-grid-header {
    background: transparent;
    color: $almost-black;

    .k-link {
      color: $almost-black !important;
    }
  } 

  .k-grid-header,
  .k-grid-header-wrap {
    background: transparent;
    border: none;

    &:hover {
      border: none;
    }
  }

  .k-grid-content {
    overflow-y: hidden;
  }

  div.k-grid-header, div.k-grid-footer {
    padding: 0;
  }
}

body.new-design {
  .k-i-filter::before {
    color: $sea-nymph-hover;
  }

  .k-grid.k-widget.telerik-blazor {
    .k-grid-header-wrap {
      border-bottom: 1px solid #f0f0f0;
    }
  
    .k-grid-container {
      border-bottom: 1px solid #f0f0f0;
    }

    tbody {
      tr {
        background: transparent;

        td {
          background: transparent;
          transition: all $base-duration $base-timing;
          vertical-align: middle;

          &:nth-of-type(2n+2) {
            background: transparent;
          }

          &:first-of-type {
            border-radius: 6px 0 0 6px;
          }

          &:last-of-type {
            border-radius: 0 6px 6px 0;
          }
        }

        &:hover {
          td,
          td:nth-of-type(2n+2) {
            background: $new-grey;
          }
        }
      }
    }
  }
}

@include bp(md) {
  .k-grid.k-widget.telerik-blazor table {
    min-width: 0;
  }
}

//checkbox table
table.checkbox-table {
  th,
  td {
    vertical-align: top;
  }

  th {
    padding: 15px 10px;

    &:first-of-type {
        padding: 15px 10px 15px 55px;
    }
  }

  td {
    padding: 10px;

    &:first-of-type {
        padding: 10px 10px 10px 20px;
    }
  }

  input[type="checkbox"] {
    float: left;
    margin: 2px 10px 0 0;
  }
}

//telerik tables
.k-widget {
  border: none;
}

.k-filter-menu {
  padding: 20px;
  .k-label{
    display: flex;
    align-items: center;
  }
  .k-filter-and{
    display: none !important;
  }
}

.k-filter-menu>div>div:last-child {
  margin: 0;
}

.k-filter-menu>div>div:last-child {
  border: none;
}

.k-filter-menu .k-button {
  margin: 0 4% 0 0;
  width: 48%;

  &.k-primary {
    margin: 0;
  }
}

//new tables
.cutty-sark-header,
.sea-nymph-header,
.swans-down-header {
  a {
    color: $emperor;
    text-decoration: underline;
  }

  &:after {
    background: $cutty-sark;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  th {
    background: $cutty-sark;
    color: $white;
    font-size: 12px;
  }

  tbody {
    font-size: 11px;
  }

  tr {
    &:nth-of-type(2n+2) {
      background: $gallery;
    }
  }

  td {
    min-height: 0;
  }

  .action-buttons a, 
  .additional-resource-line div.toggle-content li section a, 
  [data-label="Edit Attachments"] a, 
  [data-label="Edit Crop Rates"] a, 
  [data-label="Edit Product"] a, 
  [data-label="Supplier Profile"] a, 
  [data-label=Actions] a, 
  [data-label=Archive] a, 
  [data-label=Delete] a, 
  [data-label=Edit] a, 
  [data-label=Label] a, 
  [data-label=PDF] a, 
  [data-label=SDS] a, 
  [data-label=View] a,
  [data-label=Attachments] li a,
  [data-label=Notes] li a {
    background: transparent;
    border-radius: 0;
    color: $emperor;
    text-decoration: none;

    span {
      &:before {
        color: $emperor;
      }
    }
  }
}

.sea-nymph-header {
  th {
    background: $sea-nymph;
  }
}

.swans-down-header {
  th {
    background: $swans-down;
    color: $emperor;
  }
}

.two-columns table.transparent-header,
table.transparent-header {
  tr {
    background: transparent;
    border-bottom: 1px solid $gallery;
    border-top: none;
    margin: 0;
    padding-bottom: 10px;

    &:first-of-type {
      border-bottom: 1px solid $gallery;
    }

    th,
    td {
      background: transparent;
      color: $mine-shaft;
      font-size: 16px;
      //padding: 10px;
      padding: 10px 10px 10px 54%;
    }
  }
}

@include bp(md) {
  .cutty-sark-header {
    &:after {
      display: none;
    }
  }

  .two-columns table.transparent-header,
  table.transparent-header {
    border-bottom: 1px solid $gallery;

    tr,
    tr:first-of-type {
      border-bottom: none;
      padding-bottom: 0;

      th,
      td {
        padding: 10px;
      }

      td {
        padding: 10px 10px 4px 10px;
      }
    }

    thead {
      tr,
      tr:first-of-type {
        border-top: 1px solid $gallery;

        th {
          //border-bottom: 1px solid $gallery;
          padding-bottom: 20px;
          position: relative;

          &:after {
            background: $gallery;
            bottom: 10px;
            content: '';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            width: 100%;
          }
        }
      }
    }
  }
}

///////////////////////////////////////////////
//striped table
///////////////////////////////////////////////
table.striped-table,
table.striped-table.non-responsive {
  tr {
    td {
      background: $gallery;
    }

    &:nth-of-type(2n+2) {
      td {
        background: $white;
      }
    }
  }
}

@include bp(md) {
  table.striped-table,
  table.striped-table.non-responsive {
    thead {
      border-bottom: none;

      th {
        font-size: 17px;
        padding: 12px 30px 11px 30px;
      }
    }

    tr {
      td {
        padding: 12px 30px 11px 30px;
        transition: none;

        &:first-of-type {
          border-radius: 0;
        }

        &:last-of-type {
          border-radius: 0;
        }
      }

      &:hover {
        td,
        td:nth-of-type(2n+2) {
          background: $gallery;
        }
      }

      &:nth-of-type(2n+2) {
        &:hover {
          td,
          td:nth-of-type(2n+2) {
            background: $white;
          }
        }
      }
    }
  }
}

///////////////////////////////////////////////
//new design
///////////////////////////////////////////////
@include bp(md) {
  body.new-design {
    thead {
      border-bottom: 1px solid $ada-grey-4;
    }

    tr {
      td {
        background: transparent;
        transition: all $base-duration $base-timing;
        vertical-align: middle;

        &:nth-of-type(2n+2) {
          background: transparent;
        }
      }

      &:hover {
        td,
        td:nth-of-type(2n+2) {
          background: $ada-grey-5;
        }
      }
    }
  }
}

//table no background color
table.no-bg-color {
  background: transparent !important;

  thead,
  tbody {
    border: none !important;
    
    tr {
      color: $mine-shaft !important;
      background: transparent !important;

      th,
      td {
        background: transparent !important;
      }

      &:nth-of-type(2n+2) {
        background: transparent !important;

        th,
        td {
          background: transparent !important;
        }
      }
    }

    th,
    td {
      background: transparent !important;
    }
  }
}



///////////////////////////////////////////////
//basic table - 08/22
///////////////////////////////////////////////
//contained table - 0722
table.table-contained {
  tr {
    background: $concrete;
    padding: 30px 0;

    td {
      display: flex;
      flex-direction: row;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: flex-start;
      line-height: 1.2;
      margin: 0 5%;
      min-height: 30px;
      padding: 0 0 20px 0;

      &:last-of-type {
        min-height: 0;
        padding: 0;
      }

      &::before {
        display: inline-block;
        font-weight: 600;
        margin: 0 0 5px 0;
        padding: 0;
        position: relative;
        width: 100%;
      }

      span {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
      }

      input[type="checkbox"] {
        margin-top: 3px;
      }
    }

    &:nth-of-type(2n+2) {
      background: $white;
    }

    &:last-of-type {
      border-radius: 0 0 6px 6px;
    }

    [data-label="Actions"],
    [data-label="Supplier Profile"],
    [data-label="View"],
    [data-label="Edit"],
    [data-label="Delete"],
    [data-label="PDF"],
    [data-label="SDS"],
    [data-label="Label"],
    [data-label="Edit Product"],
    [data-label="Edit Attachments"],
    [data-label="Edit Crop Rates"],
    [data-label="Select"],
    .additional-resource-line div.toggle-content li section,
    .action-buttons {
      span {
        width: 75%;
      }

      a {
        display: block;
      }

      &::before {
        margin: 3px 0 0 0;
        min-width: 20%;
        width: 20%;
      }
    }
  }
}

@include bp(sm) {
  table.table-contained {
    tr {
      [data-label="Actions"],
      [data-label="Supplier Profile"],
      [data-label="View"],
      [data-label="Edit"],
      [data-label="Delete"],
      [data-label="PDF"],
      [data-label="SDS"],
      [data-label="Label"],
      [data-label="Edit Product"],
      [data-label="Edit Attachments"],
      [data-label="Edit Crop Rates"],
      [data-label="Select"],
      .additional-resource-line div.toggle-content li section,
      .action-buttons {
  
        span {
          width: 83%;
        }
  
        &::before {
          min-width: 12%;
          width: 12%;
        }
      }
    }
  }
}

@include bp(md) {
  table.table-contained {
    thead {
      tr {
        background: transparent;
        border-bottom: 1px solid $ada-grey-4;

        th {
          &:first-of-type {
            padding-left: 30px;
          }
        }
      }
    }

    tbody {
      tr {
        border-radius: 0 6px 6px 0;

        &:last-of-type {
          td {
            &:first-of-type {
              border-radius: 0 0 0 6px;
            }

            &:last-of-type {
              border-radius: 0 0 6px 0;
            }
          }
        }
      }
    }

    tr {
      background: $concrete;
      padding: 0;

      [data-label="Actions"],
      [data-label="Supplier Profile"],
      [data-label="View"],
      [data-label="Edit"],
      [data-label="Delete"],
      [data-label="PDF"],
      [data-label="SDS"],
      [data-label="Label"],
      [data-label="Edit Product"],
      [data-label="Edit Attachments"],
      [data-label="Edit Crop Rates"],
      .additional-resource-line div.toggle-content li section,
      .action-buttons {
        vertical-align: middle;

        span {
          width: 100%;
        }
      }
  
      th,
      td {
        display: table-cell;
        min-height: 44px;
        padding: 10px;

        &::before {
          display: none;
        }

        &:first-of-type {
          padding-left: 30px;
        }

        &:last-of-type {
          padding: 10px;
        }

        span {
          width: 100%;
        }

        input[type="checkbox"] {
          margin: auto 0;
        }
      }

      td[data-label="Select"],
      td[data-label="Show"] {
        padding-left: 0 !important;
        vertical-align: middle;
      }
    }
  }
}

@include bp(lg) {
  table.table-contained {
    tr {
      background: $concrete;
      padding: 0;

      [data-label="Actions"],
      [data-label="Supplier Profile"],
      [data-label="View"],
      [data-label="Edit"],
      [data-label="Delete"],
      [data-label="PDF"],
      [data-label="SDS"],
      [data-label="Label"],
      [data-label="Edit Product"],
      [data-label="Edit Attachments"],
      [data-label="Edit Crop Rates"],
      .additional-resource-line div.toggle-content li section,
      .action-buttons {
        a {
          margin-bottom: 0;
        }
      }
    }
  }
}
.pistachio-header{
  .k-button{
    background: none;
  }
}

table {
  .pistachio-summary {
    background: rgba($pistachio-hover, 0.15) !important;

    td {
      background-color: inherit;
    }
  }
}

.k-widget.k-grid{
  background: none;
}
.k-link, .k-pager-info{
  color: $almost-black;
}
.k-pager-numbers .k-state-selected {
  border-color: $pistachio;
  color: $pistachio;
}
.k-pager-wrap {
  border-radius: 0 0 6px 6px;
  padding-top: 14px;
  .k-pager-numbers .k-link{
    text-align: center;
  }
  .k-link .k-pager-nav{
    font-weight: 300;
    text-align: center;
  }
  .k-link .k-icon{
    color: $almost-black;
  }
}
.k-grid-header .k-header .k-link, .k-grid-header .k-link, .k-grid-header .k-link:link{
  color: $almost-black;
  font-weight: 600;
}
.k-grid-header .k-header{
  background: $white;
}
.k-filter-menu{
  .k-autocomplete, 
  .k-textbox {
      background: none;
      padding: 0;
      margin: 0;
      input:not([type]){
        margin: 0;
      }
      .k-icon{
        right: 8px;
      }
  }
}
.k-grid-filter-menu{
  .k-i-cancel:before{
    content: "";
  }
  .k-header{
    text-align: center;
    .k-button{
      padding: 10px 6px;
    }
  }
  .k-mobile-list input:not([type=checkbox]):not([type=radio]){
    margin-top: 0px !important;
  }
  .k-mobile-list .k-item>.k-label{
    margin-top: 0px !important;
  }
  .k-label input[type=radio]:checked:before{
    background: none;
  }
}

//telerik k-grid tables
.k-grid {
  .k-header {
    &.k-grid-top{
      background: $white;
      border-bottom: 1px solid $concrete !important;
      padding-left: 30px;
      .k-button {
        background-color: $white;
        color: $almost-black;
        padding-top: 7px;
        padding-bottom: 7px;
        text-transform: unset;
        border: 1px solid $ship-gray;
        font-size: 14px;
        margin-right: 7px;
        &:hover{
          background: $ada-grey-4;
        }
        .k-icon{
          display: none;
        }
      }
    }
    .k-button {
      background-color: $pistachio;
      border: none;

      &:hover {
        background-color: $pistachio-hover;
      }
    }

    .k-link {
      .k-icon {
        &.k-i-sort-desc-sm,
        &.k-i-sort-asc-sm {
          color: $link-green !important;
          margin-top: 3px;
        }
      }
    }
  }

  .k-grid-content {
    max-height: 533px;
    min-height: 50px;
  }
  
  table {
    table-layout: fixed;
    width: 100%;
    max-width: none;

    tr {
      display: table-row;
      background: $concrete;
      padding: 30px 0;

      &:nth-of-type(2n+2) {
        background: $white;
      }

      &.k-master-row {
        background: $concrete;

        td {
          background: transparent;
        }
        
        &.k-alt {
          background: $white;
        }
      }

      &.k-detail-row {
        background: $white;

        &.k-alt {
          background: $white;
        }
      }
    }

    thead {
      line-height: inherit;  
      border: inherit;
      clip: unset;
      height: inherit;
      margin: inherit;
      overflow: inherit;
      padding: inherit;
      position: relative;
      width: auto;
    }
    th {
      font-size: 14px;
      display: table-cell;
      padding: .786em .6em !important;
    }
    td {
      font-size: 14px;
      display: table-cell;
      word-break: normal;
      overflow-wrap: normal;
      white-space: normal;
      padding: .786em .6em;
      &:before {
        content:'';
      }
      .action-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0;
        border-radius: 3px;
        background-color: $marigold;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        padding-top: 1px;
        margin-right: 5px;
        height: 25px;
        width: 25px;
        text-align: center;
        vertical-align: middle;
        [class^=u-icon-] {
          &:before {
            color: $white;
            font-size: 19px;
          }
        }
      }
    }
  }
}

@include bp(lg) {
  .k-grid {
    table{
      th, td{
        font-size: 16px !important;
      }
    }
    
  }
}

.grouped-table-wrapper .row:nth-child(even) table {
    tr {
      background: $white;
      td {
        background: $white;
      }
    }
}

//telerik k-grid table accordions
.telerik-inner-accordian {
  padding-right: 45px;

  .flexbox-grid {
    &.row {
      padding: bsu(0.5);

      &:nth-of-type(odd) {
        background: $concrete;
      }
    }
  }
}